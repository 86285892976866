import Ember from 'ember';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set, get } from '@ember/object';
import { debounce, cancel, later } from '@ember/runloop';
import { localCopy } from 'tracked-toolbox';
import { task } from 'ember-concurrency';
import { isPresent, isEmpty } from '@ember/utils';
import { underscore, camelize } from '@ember/string';
import { toCoords, toLatLngObject, toLatLngArray } from 'garaje/helpers/resource-coords';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { htmlSafe } from '@ember/template';
import { RESERVED_DESK_ICON, FEATURE_ICON_MAP, DISABLED_DESK_ICON } from 'garaje/utils/map-icons';
import { NON_POINTS_OF_INTEREST } from 'garaje/utils/enums';
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import urlBuilder from 'garaje/utils/url-builder';
import leaflet from 'leaflet';
import 'leaflet-draw';
import 'leaflet-path-drag';
import { guidFor } from '@ember/object/internals';
import { getAutoGenerationModalPosition } from 'garaje/utils/resource-map';
import { defer } from 'rsvp';
import { getNeighborhoodPolygons } from 'garaje/utils/neighborhood-polygons';
import { getFlattenedEmployees } from 'garaje/utils/resource-overview';

/**
 * @param {String}                              rasterImageUrl
 * @param {Number}                              mapWidth
 * @param {Number}                              mapHeight
 * @param {Model<AreaMap>}                      areaMap
 * @param {Model<MapFloor>}                     mapFloor
 * @param {Model<MapFeature>}                   selectedFeature
 * @param {Array<Model<MapFeature>>}            mapFeatures
 * @param {Function}                            onSelectedFeatureChange
 * @param {Boolean}                             isSnappingEnabled
 * @param {Object}                              resourceOptions
 * @param {Array<Object>}                       availableResources
 * @param {Object}                              additionalDetails // additional information to pass that might change on case to case basis
 * @param {Function}                            setMapFeatures
 * @param {Boolean}                             viewOnly
 * @param {Boolean}                             sidebarIsOpen
 * @param {Boolean}                             shouldResetMap
 * @param {Function}                            setDeletableFeatures
 */
@eventsLogger
export default class ResourceMap extends Component {
  @service flashMessages;
  @service state;
  @service store;
  @service featureFlags;
  @service featureConfig;
  @service metrics;
  @service ajax;

  crs = leaflet.CRS.Simple;

  @localCopy('args.mapWidth', 1200) mapWidth;
  @localCopy('args.mapHeight', 800) mapHeight;
  @localCopy('args.sidebarIsOpen', false) sidebarIsOpen;
  @localCopy('args.isSnappingEnabled', true) isSnappingEnabled;
  @localCopy('args.shouldResetMap', false) shouldResetMap;

  @tracked map;
  @tracked isReady = false;

  @tracked drawStarted = false;
  @tracked drawingArea;

  @tracked cursorX;
  @tracked cursorY;
  @tracked containerWidth;
  @tracked containerHeight = this.getContainerHeight();

  @tracked isInteraction = false;
  @tracked isOverArea = false;
  @tracked debouncedInteractionOff;

  @tracked polylineX = [];
  @tracked polylineY = [];
  @tracked zoomDelta = 0.5;
  @tracked originalZoomValue;

  @tracked bulkDeskSelectionBox = [];
  @tracked selectedFeatures = [];
  @tracked hasSeenBulkSelectionTooltip = false;

  // (ar) all of the following tracked properties are related to the new UI for desk auto-generation
  // they can potentially be refactored into a separate component
  @tracked canAutoPlaceDesks = false;
  @tracked shouldShowDeskArrangementModal = false;
  @tracked deskAutoGenerationModalPosition = {};
  @tracked deskAutoPlacementSelection = { boundingBox: {} };
  @tracked autoDeskPlacementBox = [];
  @tracked neighborhoodToView = null;
  @tracked pressedKeys = {};

  unplacedDesksCache = null;
  snapDistanceWithZoom = 0;
  deskAutoGenerationDropdownVerticalOffsetInPixels = 45;
  deskAutoGenerationDropdownHorizontalOffsetInPixels = 0;
  deskAutoGenerationModalVerticalOffsetInPixels = 50;
  deskAutoGenerationModalHorizontalOffsetInPixels = 60;

  get isMapSetupImprovementsEnabled() {
    return this.featureFlags.isEnabled('map-setup-improvements-web');
  }

  get deskArrangementModalPosition() {
    return {
      x: this.deskAutoGenerationModalPosition.x + this.deskAutoGenerationDropdownHorizontalOffsetInPixels,
      y: this.deskAutoGenerationModalPosition.y + this.deskAutoGenerationDropdownVerticalOffsetInPixels,
    };
  }

  get shouldRenderResourceForm() {
    const { shouldShowEmployeeForm, isDraftMap } = this.args;
    const showInDraftMap = isDraftMap && this.featureConfig.isEnabled('maps.drafts');
    const showInEditMap = this.isEditResourceOverviewEnabled;
    return (showInDraftMap || showInEditMap) && !shouldShowEmployeeForm;
  }

  get isDrawing() {
    return this.args.placingResource?.type === 'room';
  }

  get bounds() {
    const { mapWidth, mapHeight } = this;
    return new leaflet.LatLngBounds(
      leaflet.CRS.Simple.unproject({ x: 0, y: 0 }, 0),
      leaflet.CRS.Simple.unproject({ x: mapWidth, y: -mapHeight }, 0),
    );
  }

  get isLandscape() {
    return this.mapWidth > this.mapHeight;
  }

  get mapDimensions() {
    return [this.mapWidth, this.mapHeight];
  }

  get floorMapFeatures() {
    return this.args.mapFeatures.filter((feature) => {
      if (!this.featureConfig.isEnabled('desks') && feature.type === 'desk') {
        return false;
      }
      if (feature.mapFloor) {
        return get(feature.mapFloor, 'id') === this.args.mapFloor.id;
      }
    });
  }

  get deskFeaturesOnFloor() {
    return this.floorMapFeatures.filter((feature) => feature.type === 'desk' && !feature.isDeleted);
  }

  get maxZoom() {
    const delta = 2.15;
    if (this.isLandscape) {
      return Math.sqrt(this.containerHeight / this.mapHeight) * delta;
    }
    return Math.sqrt(this.containerWidth / this.mapWidth) * delta;
  }

  get minZoom() {
    const delta = 1.35; //this delta prevents images from getting cut off
    if (this.isLandscape) {
      return -Math.sqrt(this.mapWidth / this.containerWidth) * delta;
    }

    return -Math.sqrt(this.mapHeight / this.containerHeight) * delta;
  }

  get canPlaceResource() {
    return (
      this.args.placingResource &&
      !this.isOverArea &&
      !this.isInteraction &&
      !this.autoGenerateDesksTask.isRunning &&
      isPresent(this.cursorX) &&
      isPresent(this.cursorY)
    );
  }

  get enableAnimation() {
    return !Ember.testing;
  }

  get zoomPanOptions() {
    return {
      animate: this.enableAnimation,
    };
  }

  get isPointOfInterest() {
    const { selectedFeature } = this.args;
    if (!selectedFeature) return false;
    return !NON_POINTS_OF_INTEREST.includes(selectedFeature.type);
  }

  get selectedFeatureType() {
    const { selectedFeature } = this.args;
    return this.isPointOfInterest ? 'point-of-interest' : selectedFeature?.type;
  }

  get placingResourceType() {
    const { placingResource } = this.args;
    return this.isPointOfInterest ? 'point-of-interest' : placingResource?.type;
  }

  get isEditResourceOverviewEnabled() {
    const { isDraftMap, viewOnly } = this.args;
    return !isDraftMap && !viewOnly;
  }

  get resourceOverviewEnabled() {
    return this.args.isDraftMap || this.isEditResourceOverviewEnabled;
  }

  getContainerHeight() {
    if (this.args.viewOnly) {
      return 800;
    }

    const { clientHeight } = document.querySelector('#resource-map-wrapper');
    return clientHeight;
  }

  @action
  setNeighborhoodToView(neighborhoodId) {
    const { viewOnly } = this.args;

    this.metrics.trackEvent('Set neighborhood focus mode', { neighborhoodId });

    if (!viewOnly) {
      this.neighborhoodToView = neighborhoodId;
    }

    if (neighborhoodId) {
      const alreadySelected = this.args.additionalDetails.selectedNeighborhoods.find(
        (neighborhood) => neighborhood.id === neighborhoodId,
      );
      if (!alreadySelected) {
        this.args.additionalDetails.selectedNeighborhoods.pushObject(
          this.store.peekRecord('neighborhood', neighborhoodId),
        );
      }
    }
  }

  @action
  isPOIFeature(feature) {
    return feature.type && !NON_POINTS_OF_INTEREST.includes(feature.type);
  }

  @action
  shouldDisableFeature(feature) {
    let resource;

    if (feature.hasError) {
      return false;
    }

    if (feature.isNew && feature.type === 'desk') {
      resource = feature[feature.type];
    } else if (feature.externalId) {
      resource = this.store.peekRecord(feature.type, feature.externalId);
    }

    if (isPresent(resource?.enabled)) {
      return !resource?.enabled;
    }

    if (this.featureConfig.isEnabled('mapsPointOfInterest')) {
      return !feature.enabled;
    }

    return false;
  }

  @action
  onInsert() {
    this.loadImageTask.perform();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);

    this.handleResize();
  }

  @action
  onDestroy() {
    this.args.setLeafletMap(null);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  }

  @action
  handleResize() {
    const { clientWidth, clientHeight } = document.querySelector('#resource-map-wrapper');
    this.containerHeight = clientHeight;
    this.containerWidth = clientWidth;
  }

  get inputIsFocused() {
    return document.activeElement.tagName === 'INPUT';
  }

  @action
  keyDownHandler(event) {
    const { placingResource, selectedFeature, leafletMap } = this.args;
    if (!this.pressedKeys[event.key]) {
      this.pressedKeys = { ...this.pressedKeys, [event.key]: true };
    }

    if ((selectedFeature || placingResource) && event.key === 'Escape') {
      this.closeResourceForm();

      if (this.isDrawing && this.args.drawingArea) {
        this.args.drawingArea.disable();
        this.args.leafletMap.dragging.enable();
      }
    }

    if (this.selectedFeatures.length && event.key === 'Escape') {
      this.selectedFeatures = [];
    }

    if (leafletMap && this.featureFlags.isEnabled('bulk-desk-editing-web')) {
      if ((this.pressedKeys['Meta'] || this.pressedKeys['Control']) && this.pressedKeys['a'] && !this.inputIsFocused) {
        this.selectedFeatures = this.deskFeaturesOnFloor;
        this.metrics.trackEvent('Selected all desks with hotkey', { numberOfDesks: this.selectedFeatures.length });
        event.preventDefault();
        return;
      }

      if (this.pressedKeys['Shift'] && this.pressedKeys['Backspace']) {
        this.metrics.trackEvent('Delete selected desks with hotkey', { numberOfDesks: this.selectedFeatures.length });
        return;
      }

      if (this.pressedKeys['Shift']) {
        leafletMap.dragging.disable();
        return;
      }
    }

    if (
      (this.pressedKeys['Meta'] || this.pressedKeys['Control']) &&
      placingResource?.type === 'desk' &&
      this.isMapSetupImprovementsEnabled
    ) {
      this.canAutoPlaceDesks = true;
      if (leafletMap) {
        leafletMap.dragging.disable();
      }
    }
  }

  get shouldDeleteSelectedDesks() {
    return this.pressedKeys['Shift'] && this.pressedKeys['Backspace'];
  }

  @action
  keyUpHandler(event) {
    const { leafletMap } = this.args;
    delete this.pressedKeys[event.key];

    if (event.key === 'Shift' && leafletMap && this.featureFlags.isEnabled('bulk-desk-editing-web')) {
      leafletMap.dragging.enable();
      return;
    }

    if ((event.key === 'Meta' || event.key === 'Control') && this.featureFlags.isEnabled('bulk-desk-editing-web')) {
      this.pressedKeys = {};
    }

    if ((event.key === 'Meta' || event.key === 'Control') && this.isMapSetupImprovementsEnabled) {
      if (!this.deskAutoPlacementSelection.boundingBox.point1) {
        this.canAutoPlaceDesks = false;
        if (leafletMap) {
          leafletMap.dragging.enable();
        }
      }
    }
  }

  @action
  onFloorUpdate() {
    // We need to remove existing polygons that is related to the prev floor
    this.isReady = false;
    this.args.leafletMap.eachLayer((layer) => {
      layer.options.isArea && this.args.leafletMap.removeLayer(layer);
    });
    this.loadImageTask.perform();

    if (!this.sidebarIsOpen) {
      this.sidebarIsOpen = true;
    }
  }

  @action
  onLoadImage() {
    // Without the timeout, when image is cached LeafletMap will render the markers before the image is fit to the bounds
    // When the image is fit the markers will be out of place. The timeout gives time for the image to be fit after loading
    later(
      this,
      () => {
        if (!Ember.testing) {
          this.args.leafletMap.fitBounds(this.bounds, { padding: [1, 1] });
          this.originalZoomValue = this.args.leafletMap.getZoom();
        }
        this.isReady = true;
      },
      500,
    );
  }

  get canDrawAutoPlacementBox() {
    return (
      this.featureFlags.isEnabled('map-setup-improvements-web') &&
      this.canAutoPlaceDesks &&
      this.deskAutoPlacementSelection.boundingBox?.point1
    );
  }

  get canDrawBulkSelectionBox() {
    const { viewOnly, placingResource } = this.args;
    return (
      this.pressedKeys['Shift'] && !viewOnly && !placingResource && this.featureFlags.isEnabled('bulk-desk-editing-web')
    );
  }

  drawAutoPlacementBox(ev) {
    const { lat: startLat, long: startLong } = this.deskAutoPlacementSelection.boundingBox.point1;

    const top = [startLat, startLong];
    const right = [startLat, ev.latlng.lng];
    const bottom = [ev.latlng.lat, ev.latlng.lng];
    const left = [ev.latlng.lat, startLong];
    this.autoDeskPlacementBox = [top, right, bottom, left, top];
  }

  drawBulkSelectionBox(ev) {
    const [startLat, startLong] = this.bulkDeskSelectionBox[0];
    const top = [startLat, startLong];
    const right = [startLat, ev.latlng.lng];
    const bottom = [ev.latlng.lat, ev.latlng.lng];
    const left = [ev.latlng.lat, startLong];
    this.bulkDeskSelectionBox = [top, right, bottom, left];
  }

  @action
  onMouseDown(ev) {
    if (this.canDrawBulkSelectionBox) {
      // prevent text selection while drawing the box
      ev.originalEvent.preventDefault();
      this.hasSeenBulkSelectionTooltip = true;
      this.bulkDeskSelectionBox = [[ev.latlng.lat, ev.latlng.lng]];
    }

    if (this.canAutoPlaceDesks && this.featureFlags.isEnabled('map-setup-improvements-web')) {
      this.metrics.trackEvent('Automatic desk generation launched');
      this.deskAutoPlacementSelection.boundingBox.point1 = { lat: ev.latlng.lat, long: ev.latlng.lng };
    }
  }

  @action
  handleMouseMove(ev) {
    const { offsetX, offsetY } = ev.originalEvent;
    if (this.bulkDeskSelectionBox.length) {
      // prevent text selection while drawing the box
      ev.originalEvent.preventDefault();
      this.drawBulkSelectionBox(ev);
    }

    if (this.canDrawAutoPlacementBox) {
      this.drawAutoPlacementBox(ev);
    }
    if (!this.isInteraction) {
      this.cursorX = offsetX;
      this.cursorY = offsetY;
    }
  }

  @action
  selectDesksInNeighborhood(neighorhoodId) {
    const desks = this.args.additionalDetails.desks;
    const neighborhoodDesks = desks.filter((desk) => desk.belongsTo('neighborhood').id() === neighorhoodId);
    const selectedFeatures = this.deskFeaturesOnFloor.filter((feature) =>
      neighborhoodDesks.some((desk) => desk.id === feature.externalId || desk.id === feature.desk?.id),
    );
    this.metrics.trackEvent('Selected desks in neighborhood', { numberOfDesks: selectedFeatures.length });
    this.selectedFeatures = selectedFeatures;
  }

  autoGenerateDesks = (ev) => {
    // we want to save these coordinates to know where to display the confirmation popup
    this.deskAutoPlacementSelection.boundingBox.point2 = { lat: ev.latlng.lat, long: ev.latlng.lng };

    const boundingBoxCoordinatesInPixels = this.args.leafletMap.latLngToContainerPoint(
      getAutoGenerationModalPosition(this.deskAutoPlacementSelection.boundingBox),
    );
    // apply the offsets so the auto-generation modal is above and center
    this.deskAutoGenerationModalPosition = {
      x: boundingBoxCoordinatesInPixels.x - this.deskAutoGenerationModalHorizontalOffsetInPixels,
      y: boundingBoxCoordinatesInPixels.y - this.deskAutoGenerationModalVerticalOffsetInPixels,
    };
    this.canAutoPlaceDesks = false;
    this.autoGenerateDesksTask.perform();
    this.args.leafletMap.dragging.enable();
  };

  getBoxCornerCoordinates(box) {
    const topRight = box.reduce(
      (acc, point) => {
        // where x is the largest and y is the largest
        if (point[0] >= acc[0] && point[1] >= acc[1]) {
          acc = point;
        }
        return acc;
      },
      [-Infinity, -Infinity],
    );

    const bottomLeft = box.reduce(
      (acc, point) => {
        // where x is the smallest and y is the smallest
        if (point[0] <= acc[0] && point[1] <= acc[1]) {
          acc = point;
        }
        return acc;
      },
      [Infinity, Infinity],
    );

    return [topRight, bottomLeft];
  }

  handleBulkDeskSelection() {
    const [topRight, bottomLeft] = this.getBoxCornerCoordinates(this.bulkDeskSelectionBox);
    const bottomLeftCoords = toCoords({ lng: bottomLeft[1], lat: bottomLeft[0] }, this.mapDimensions);
    const topRightCoords = toCoords({ lng: topRight[1], lat: topRight[0] }, this.mapDimensions);

    const selectedDesks = this.deskFeaturesOnFloor.filter((feature) => {
      const [xPos, yPos] = feature.geometry.coordinates;

      return (
        xPos >= bottomLeftCoords.xPos &&
        xPos <= topRightCoords.xPos &&
        yPos <= bottomLeftCoords.yPos &&
        yPos >= topRightCoords.yPos
      );
    });

    return selectedDesks;
  }

  @action
  onMouseUp(ev) {
    if (this.bulkDeskSelectionBox.length) {
      let deskFeaturesInBox = this.handleBulkDeskSelection();
      const featuresToUnselect = this.selectedFeatures.filter((feature) => deskFeaturesInBox.includes(feature));
      this.selectedFeatures = this.selectedFeatures.filter((feature) => !featuresToUnselect.includes(feature));
      deskFeaturesInBox = deskFeaturesInBox.filter((feature) => !featuresToUnselect.includes(feature));

      this.selectedFeatures = [...this.selectedFeatures, ...deskFeaturesInBox];
      this.metrics.trackEvent('Selected desks in bulk', {
        totalSelected: this.selectedFeatures.length,
        newSelected: deskFeaturesInBox.length,
        unselected: featuresToUnselect.length,
      });
      this.bulkDeskSelectionBox = [];
      this.args.onSelectedFeatureChange(null);
    }

    if (this.canAutoPlaceDesks && this.featureFlags.isEnabled('map-setup-improvements-web')) {
      this.autoGenerateDesks(ev);
    }
  }

  @action
  onSidebarToggle(isOpen) {
    this.sidebarIsOpen = isOpen;
  }

  @action
  resetMapView() {
    if (this.args.viewOnly) return;

    if (this.isDrawing) {
      this.args.drawingArea.disable();
      this.args.leafletMap.dragging.enable();
    }

    if (this.resourceOverviewEnabled) {
      this.sidebarIsOpen = false;
    }

    this.args.leafletMap.setMaxBounds(this.bounds);
    this.args.setPlacingResource(null);
  }

  @action
  registerMap({ target: map }) {
    // since there is no option to disable pan animation
    // this overrides the fn to skip removing class from map pane
    if (Ember.testing) {
      map._onPanTransitionEnd = () => {
        map.fire('moveend');
      };
    }

    map.on('moveend', () => {
      map.setMaxBounds(this.args.viewOnly ? this.bounds : null);
    });

    this._registerDrawingHandlers(map);
    this.args.setLeafletMap(map);
  }

  @action
  toggleInteractionOn() {
    cancel(this.debouncedInteractionOff);
    this.isInteraction = true;
  }

  @action
  toggleInteractionOff() {
    this.resetCursor();
    this.debouncedInteractionOff = debounce(this, this.turnOffInteraction, 250);
  }

  @action
  turnOffInteraction() {
    this.isInteraction = false;
    this.resetCursor();
  }

  @action
  resetCursor() {
    this.cursorX = null;
    this.cursorY = null;
  }

  generateDeskName() {
    const { desks } = this.args.additionalDetails;
    let deskNumber = desks.length + 1;
    while (desks.any((desk) => desk.name === `Desk ${deskNumber}`)) {
      deskNumber += 1;
    }
    return `Desk ${deskNumber}`;
  }

  @action
  getNeighborhoodColor(neighborhoodId) {
    const neighborhood = this.store.peekRecord('neighborhood', neighborhoodId);
    return neighborhood?.displayColor;
  }

  @action
  getNeighborhoodNameForDesk(feature) {
    let desk = feature.desk;
    if (feature.externalId) {
      desk = this.store.peekRecord('desk', feature.externalId);
    }
    const neighborhoodId = desk.belongsTo('neighborhood').id();
    if (neighborhoodId) {
      const neighborhood = this.store.peekRecord('neighborhood', neighborhoodId);
      return neighborhood?.name;
    }
    return null;
  }

  get neighborhoodPolygons() {
    return getNeighborhoodPolygons(this.deskFeaturesOnFloor, this.mapDimensions, this.store);
  }

  @action
  // finds the desks assigned to the given employeeId from the GQL response, and appends the assignedTo field for each of them
  getAssignedDesksForEmployee(employeeId) {
    return getFlattenedEmployees(this.args.gqlEmployees?.assigned)
      .filter((it) => parseInt(it.id) === parseInt(employeeId))
      .map((it) => {
        return it.assignedDesks.map((assignedDesk) => {
          return {
            ...assignedDesk,
            assignedTo: it.email,
          };
        });
      })
      .flat();
  }

  @action
  convertToLatLngArray(coordinates) {
    const coords = coordinates.map((coordsArray) => toLatLngObject(coordsArray, this.mapDimensions));
    return coords;
  }

  @action
  onClick({ latlng }) {
    const {
      onSelectedFeatureChange,
      setMapFeatures,
      mapFeatures,
      viewOnly,
      selectedFeature,
      placingResource,
      isDraftMap,
    } = this.args;
    const { mapDimensions } = this;
    if (viewOnly && selectedFeature) {
      onSelectedFeatureChange(null);
    }

    if (!this.canPlaceResource || this.isDrawing || this.canAutoPlaceDesks) return;
    if (selectedFeature) this._validateFeature(selectedFeature);

    const { xPos, yPos } = toCoords(latlng, mapDimensions);
    const newFeature = this.createFeature(placingResource, [xPos, yPos]);

    onSelectedFeatureChange(newFeature);
    setMapFeatures([...mapFeatures, newFeature]);

    this.logPlaced({
      resource_type: underscore(this.placingResourceType),
      isDraft: isDraftMap,
    });
  }

  @action
  createFeature(resource, coordinates) {
    const { areaMap, mapFloor, updateResourceOverview, setMapFeatures } = this.args;
    const feature = this.store.createRecord('map-feature', {
      type: resource.type,
      geometry: {
        type: resource.geometryType ?? 'Point',
        coordinates,
      },
      mapFloor,
      areaMap,
    });

    setMapFeatures([...this.args.mapFeatures, feature]);
    if (resource.type === 'desk') {
      this.createDeskFeature(feature, coordinates);
    }

    if (resource.type === 'room') {
      this.createRoomFeature(feature);
    }

    if (this.isPOIFeature(feature)) {
      this.createPOIFeature(feature);
    }

    feature.tempId = guidFor(feature);
    if (updateResourceOverview) {
      updateResourceOverview('create', feature);
    }
    return feature;
  }

  createRoomFeature(feature) {
    const { availableResources } = this.args;
    if (isPresent(availableResources.rooms) && isEmpty(feature.externalId)) {
      const room = availableResources.rooms.firstObject;
      set(feature, 'name', room.displayName);
      set(feature, 'externalId', room.id);
    }
  }

  createPOIFeature(feature) {
    if (feature.type === 'custom') {
      return;
    }

    if (feature.type === 'first-aid-kit') {
      set(feature, 'name', 'First aid');
    } else if (feature.type === 'aed') {
      set(feature, 'name', 'AED');
    } else {
      set(feature, 'name', humanize([feature.type]));
    }
  }

  createDeskFeature(feature, coordinates) {
    const { mapFloor } = this.args;
    const { unplacedDesks } = this.args.availableResources;
    const { desks, hasMaxActiveDesks, updateActiveDesksCount, nextDeskToPlace, setNextDeskToPlace, draft } =
      this.args.additionalDetails;
    const [xPos, yPos] = coordinates;
    if (unplacedDesks.length) {
      const desk = nextDeskToPlace;
      set(feature, 'externalId', desk.id);
      set(feature, 'desk', desk);
      set(feature, 'name', desk.name);
      set(desk, 'xPos', xPos);
      set(desk, 'yPos', yPos);
      setNextDeskToPlace();
    } else {
      const desk = this.store.createRecord('desk', {
        reservations: [],
        enabled: !this.featureConfig.isEnabled('desks.allowEnabling') ? false : !hasMaxActiveDesks,
        name: this.generateDeskName(),
        assignedTo: null,
        floorId: mapFloor.id,
        xPos,
        yPos,
      });
      if (draft) {
        set(desk, 'draft', draft);
      }
      desks.pushObject(desk);
      set(feature, 'desk', desk);
      set(feature, 'name', desk.name);

      if (!hasMaxActiveDesks) {
        updateActiveDesksCount(1);
      }
    }
  }

  @action
  getIconForFeature(feature) {
    if (feature.type === 'desk') {
      return this.getIconForDeskFeature(feature);
    } else {
      return this.getFeatureHtml(feature);
    }
  }

  @action
  isFeatureScheduled(feature) {
    if (feature.type !== 'desk' || !feature.externalId || !this.store.peekRecord('desk', feature.externalId)) {
      return false;
    } else {
      const desk = this.store.peekRecord('desk', feature.externalId);
      return desk.assignmentDetails?.length > 0 || desk.assignments?.length > 0;
    }
  }

  getFeatureHtml(feature) {
    const icon = FEATURE_ICON_MAP[feature.type];
    const labelColor = feature.enabled ? icon?.labelColor : 'carbon-30';
    return htmlSafe(`
      <div aria-hidden="true" data-test-feature-marker=${feature.type}>
        <div class="w-[30px] h-[30px]"></div>
        <!-- The feature label is being temporarily removed
        <div
          class="feature-label absolute text-center text-${labelColor}" data-test-feature-label>
          ${feature.name || ''}
        </div>
        -->
      </div>
    `);
  }

  getIconForDeskFeature(feature) {
    const { reservations, assignedDesks } = this.args.additionalDetails;
    const { viewOnly } = this.args;

    const desk = feature.externalId ? this.store.peekRecord('desk', feature.externalId) : feature.desk;
    const users = this.store.peekAll('user');
    const employees = this.store.peekAll('employee');

    let user;
    let employee;
    let reservation;

    if (desk) {
      if (desk.assignedTo) {
        user = users.find((user) => user.email === desk.assignedTo);
        employee = employees.find((employee) => employee.email === desk.assignedTo);
      } else {
        reservation = reservations?.find((res) => res.belongsTo('desk').id() == desk.id);
        user = users.find((user) => user.email === reservation?.userEmail);
        employee = employees.find((employee) => employee.email === reservation?.userEmail);
      }

      if (user && !employee) {
        employee = employees.find((employee) => employee.belongsTo('user').id() === user?.id);
      }

      if (user?.id === this.state.currentUser.id && viewOnly) {
        let deskLabel = 'Your desk';
        if (user?.email === desk.assignedTo && assignedDesks.some((assignedDesk) => assignedDesk.id === desk.id)) {
          deskLabel = 'Assigned desk';
        }
        return this.currentUserHtml(deskLabel);
      }

      if (desk.enabled && !desk.assignedTo && !reservation) {
        return '';
      }

      if (
        viewOnly &&
        (user?.hideFromEmployeeSchedule ||
          employee?.hideFromEmployeeSchedule ||
          !this.state.currentLocation.employeeScheduleEnabled ||
          ((desk.assignedTo || reservation) && !user && !employee))
      ) {
        return this.disabledDeskHtml;
      }

      if (viewOnly || desk.assignedTo) {
        if (user?.userPhotoUrl) {
          return this.getUserPhotoHtml(user.userPhotoUrl);
        }

        if (user || employee) {
          return this.getUserInitialsHtml(employee?.name || user?.fullName);
        }
      }
    }

    return '';
  }

  getUserInitialsHtml(userFullName) {
    return htmlSafe(`<div aria-hidden="true" class="table-cell align-middle w-[30px] min-w-[30px]" data-test-monogram>
      <div
        data-test-entry-monogram
        class="monogram small ${this.lengthClass(userFullName)}"
        style="background-color: hsl(224, 8%, 48%)"
      >
        ${this.monogramInitials(userFullName)}
      </div>
    </div>`);
  }

  monogramInitials(fullName) {
    let nameAttr = fullName ?? '';

    if (/\+\d+/.test(nameAttr)) {
      nameAttr = nameAttr.split('+')[0];
    }

    const nameArray = nameAttr.split(' ');
    let name = '';
    for (let i = 0; i < nameArray.length; i++) {
      name += nameArray[i].slice(0, 1);
    }
    return name.slice(0, 4); // max 4 chars
  }

  lengthClass(name) {
    const monogramInitials = this.monogramInitials(name);

    switch (monogramInitials.length) {
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
    }
    return '';
  }

  getUserPhotoHtml(src = '') {
    if (!src) {
      return;
    }
    return htmlSafe(`<div aria-hidden="true" class="table-cell align-middle w-[30px] min-w-[30px]" data-test-profile-picture>
      <span
        class="block mx-auto overflow-hidden border-4 border-white box-content rounded-full w-6 h-6"
        style="background-color: hsl(224, 8%, 48%); border: 4px solid white;"
      >
        <img
          alt=""
          class="object-cover object-center w-6 h-6"
          src="${src}"
          role="presentation"
          data-test-entry-feed-photo
          data-test-entry-thumbnail
        >
      </span>
    </div>`);
  }

  getExistingDeskCoords() {
    const { lat: lat1, long: long1 } = this.deskAutoPlacementSelection.boundingBox.point1;
    const { xPos: x1, yPos: y1 } = toCoords({ lat: lat1, lng: long1 }, this.mapDimensions);
    const { lat: lat2, long: long2 } = this.deskAutoPlacementSelection.boundingBox.point2;
    const { xPos: x2, yPos: y2 } = toCoords({ lat: lat2, lng: long2 }, this.mapDimensions);

    const maxX = Math.max(x1, x2);
    const maxY = Math.max(y1, y2);
    const minX = Math.min(x1, x2);
    const minY = Math.min(y1, y2);

    const desksInBox = this.deskFeaturesOnFloor
      .filter((desk) => {
        const x = desk.geometry.coordinates[0];
        const y = desk.geometry.coordinates[1];
        if (x > minX && x < maxX && y > minY && y < maxY) {
          return true;
        }
        return false;
      })
      .map((desk) => ({ x: desk.geometry.coordinates[0], y: desk.geometry.coordinates[1] }));
    return desksInBox;
  }
  toggleDeskArrangementModal = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.shouldShowDeskArrangementModal = !this.shouldShowDeskArrangementModal;
  };
  autoGenerateDesksAndFeatures = async (placingResource) => {
    const { rasterImageUrl, setMapFeatures, mapFeatures } = this.args;
    const url = urlBuilder.spacesAIApiUrl();
    const existingDeskCoords = this.getExistingDeskCoords();
    try {
      const response = await this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
        data: JSON.stringify({
          imageURL: rasterImageUrl,
          boundingBox: this.deskAutoPlacementSelection.boundingBox,
          existing: existingDeskCoords,
        }),
      });

      const autoGeneratedVerticalCoordinates = response.vertical_ordering;
      const autoGeneratedHorizontalCoordinates = response.horizontal_ordering;
      // the default order is vertical, so that is what gets initialized when a user creates a selection
      const generatedFeatures = autoGeneratedVerticalCoordinates.map((deskCoordinate) => {
        return this.createFeature(placingResource, [deskCoordinate.x, deskCoordinate.y]);
      });
      const autoGeneratedFeatures = generatedFeatures;
      const autoGeneratedDesks = generatedFeatures.map((it) => it.desk);
      setMapFeatures([...mapFeatures, ...generatedFeatures]);

      this.deskAutoPlacementSelection.boundingBox = {};
      this.canAutoPlaceDesks = false;

      if (autoGeneratedFeatures.length) {
        this.flashMessages.showAndHideFlash('success', 'Desks generated successfully');
        this.metrics.trackEvent('Automatic desks placed', { numberOfDesks: autoGeneratedFeatures.length });
      } else {
        this.flashMessages.showAndHideFlash('warning', 'No desks detected', 'Try again or place desks manually');
        this.metrics.trackEvent('No desks detected [auto desk placement]');
      }

      return {
        autoGeneratedVerticalCoordinates,
        autoGeneratedHorizontalCoordinates,
        autoGeneratedFeatures,
        autoGeneratedDesks,
      };
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error generating desks');
      this.autoDeskPlacementBox = [];
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  @task({ drop: true }) autoGenerateDesksTask = {
    unplacedDesks: null,
    autoGeneratedVerticalCoordinates: null,
    autoGeneratedHorizontalCoordinates: null,
    numberAutoGeneratedDesksVertically: true,
    autoGeneratedDesks: [],
    autoGeneratedFeatures: [],
    taskCompletionResolver: null,
    placingResource: null,
    *perform() {
      const deferred = defer();
      const arrangeDesks = (existingDesks, coordinates, setMapFeatures, mapFeatures) => {
        this.autoGeneratedDesks.map((it) => it.deleteRecord());
        this.autoGeneratedFeatures.map((it) => {
          this.context.removeFeature(it);
        });
        setMapFeatures(mapFeatures.filter((it) => !this.autoGeneratedFeatures.includes(it)));
        existingDesks.removeObjects(this.autoGeneratedDesks);
        const generatedFeatures = coordinates.map((deskCoordinate) => {
          return this.context.createFeature(this.placingResource, [deskCoordinate.x, deskCoordinate.y]);
        });
        this.autoGeneratedFeatures = generatedFeatures;
        this.autoGeneratedDesks = generatedFeatures.map((it) => it.desk);
        this.context.canAutoPlaceDesks = false;
      };
      this.arrangeHorizontally = (event) => {
        const { setMapFeatures, additionalDetails, mapFeatures } = this.context.args;
        if (event) {
          event.stopPropagation();
        }
        this.context.metrics.trackEvent('Sort order changed', { direction: 'horizontal' });
        if (this.numberAutoGeneratedDesksVertically) {
          arrangeDesks(additionalDetails.desks, this.autoGeneratedHorizontalCoordinates, setMapFeatures, mapFeatures);
        }
        set(this, 'numberAutoGeneratedDesksVertically', false);
        this.context.shouldShowDeskArrangementModal = false;
      };
      this.arrangeVertically = (event) => {
        const { setMapFeatures, additionalDetails, mapFeatures } = this.context.args;
        if (event) {
          event.stopPropagation();
        }
        this.context.metrics.trackEvent('Sort order changed', { direction: 'vertical' });
        if (!this.numberAutoGeneratedDesksVertically) {
          arrangeDesks(additionalDetails.desks, this.autoGeneratedVerticalCoordinates, setMapFeatures, mapFeatures);
        }
        set(this, 'numberAutoGeneratedDesksVertically', true);
        this.context.shouldShowDeskArrangementModal = false;
      };
      this.abort = (event) => {
        if (event) {
          event.stopPropagation();
        }
        this.context.metrics.trackEvent('Automatic desk placement denied');
        const { setMapFeatures, mapFeatures } = this.context.args;
        const { desks, setNextDeskToPlace } = this.context.args.additionalDetails;

        const desksToRemove = this.autoGeneratedDesks.filter((it) => !this.unplacedDesks.includes(it));
        desksToRemove.map((it) => it.deleteRecord());
        this.autoGeneratedFeatures.map((it) => {
          this.context.removeFeature(it);
        });

        setMapFeatures(mapFeatures.filter((it) => !this.autoGeneratedFeatures.includes(it)));
        desks.removeObjects(desksToRemove);
        setNextDeskToPlace();

        this.context.autoDeskPlacementBox = [];
        deferred.resolve(false);
      };
      this.continue = (event) => {
        if (event) {
          event.stopPropagation();
        }

        this.context.metrics.trackEvent('Automatic desk placement confirmed');
        this.context.autoDeskPlacementBox = [];
        deferred.resolve(true);
      };
      const { placingResource, availableResources } = this.context.args;

      // store snapshots of these variables as the initial values of their state could be required in other functions
      this.unplacedDesks = availableResources.unplacedDesks;
      this.placingResource = placingResource;

      try {
        const {
          autoGeneratedVerticalCoordinates,
          autoGeneratedHorizontalCoordinates,
          autoGeneratedFeatures,
          autoGeneratedDesks,
        } = yield this.context.autoGenerateDesksAndFeatures(placingResource);

        // the desks, features, and coordinates are all generated by this point, we are storing them for use in other functions in this task
        this.autoGeneratedVerticalCoordinates = autoGeneratedVerticalCoordinates;
        this.autoGeneratedHorizontalCoordinates = autoGeneratedHorizontalCoordinates;
        this.autoGeneratedFeatures = autoGeneratedFeatures;
        this.autoGeneratedDesks = autoGeneratedDesks;

        if (autoGeneratedDesks.length === 0) {
          this.context.autoDeskPlacementBox = [];
          deferred.resolve(true);
        }
        yield deferred.promise;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error', e);
        this.context.flashMessages.showAndHideFlash('error', 'Error generating desks');
      }
    },
  };

  currentUserHtml(label) {
    return htmlSafe(`
      <div aria-hidden="true" class="flex justify-start items-center w-36" data-test-current-user>
        <div class="${RESERVED_DESK_ICON.iconClassname} w-[30px] h-[30px]"></div>
        <span class="text-red-40 ml-2">${label}</span>
      </div>
    `);
  }

  get disabledDeskHtml() {
    return htmlSafe(`
      <div
        aria-hidden="true"
        class="${DISABLED_DESK_ICON.iconClassname} flex justify-start items-center bg-contain bg-no-repeat"
        data-test-disabled-desk
      >
        <div class="w-[30px] h-[30px]"></div>
      </div>
    `);
  }

  @action
  cancelDraw() {
    if (this.isDrawing && this.args.drawingArea) {
      this.args.drawingArea.disable();
      this.args.leafletMap.dragging.enable();
    }
  }

  @action
  onResourceClick(resource, { target }) {
    const {
      selectedFeature,
      onSelectedFeatureChange,
      additionalDetails,
      isDraftMap,
      setPlacingResource,
      shouldShowEmployeeForm,
      setEmployeeFormVisibility,
    } = this.args;

    if (selectedFeature) this._validateFeature(selectedFeature);

    onSelectedFeatureChange(resource);
    this.selectedFeatures = [];
    this.neighborhoodToView = null;

    // if the employee form is displayed right now, we want to hide it so the desk form can be displayed
    if (shouldShowEmployeeForm) {
      setEmployeeFormVisibility(false);
    }

    if (this.args.viewOnly) {
      target.off('mouseout');
      target.on('mouseout', () => {
        if (this.args.selectedFeature === resource) {
          target.openTooltip();
        } else {
          target.closeTooltip();
        }
      });
      this.metrics.trackEvent('Element tapped', {
        element_identifier: 'point_of_interest',
        element_label: resource.type,
        filter_mode: additionalDetails.selectedResourceFilter,
      });
      return;
    }

    if (this.resourceOverviewEnabled) {
      setPlacingResource(null);
    }

    if (!this.sidebarIsOpen) {
      this.sidebarIsOpen = true;
    }

    if (!resource.isNew) {
      this.logEdited({
        resource_type: underscore(resource.type),
        isDraft: isDraftMap,
      });
    }
  }

  @action
  closeBulkEditForm() {
    this.selectedFeatures = [];
  }

  @action
  closeResourceForm() {
    const { onSelectedFeatureChange, selectedFeature, setPlacingResource, placingResource } = this.args;

    if (selectedFeature) {
      this._validateFeature(selectedFeature);
      onSelectedFeatureChange(null);
    }

    if (this.isDrawing && this.args.drawingArea) {
      this.args.drawingArea.disable();
      this.args.leafletMap.dragging.enable();
    }

    if (placingResource) {
      setPlacingResource(null);
    }

    if (this.featureConfig.isEnabled('maps.drafts') && this.resourceOverviewEnabled) {
      this.sidebarIsOpen = false;
    }
  }

  @action
  onDragStart() {
    const { mapWidth, mapHeight, originalZoomValue, zoomDelta } = this;
    const zoomLevel = (this.args.leafletMap.getZoom() - originalZoomValue) / zoomDelta;
    const mapContainerHeight = 800;
    const mapContainerWidth = 958;
    const snapDistance = 10;
    const normalizedSnapValue = mapWidth > mapHeight ? mapWidth / mapContainerWidth : mapHeight / mapContainerHeight;
    const normalizedSnapDistance = normalizedSnapValue * snapDistance;
    this.snapDistanceWithZoom = normalizedSnapDistance * Math.pow(0.8, zoomLevel);
  }

  generateSnapping(target, feature) {
    const markerLatLng = target.getLatLng();

    const [closestXResource, closestYResource] = [
      this._findClosestResource(markerLatLng, 'lng', feature),
      this._findClosestResource(markerLatLng, 'lat', feature),
    ];

    const closestResources = {};
    const allAlignedResources = {};

    if (closestXResource) {
      closestResources.x = toLatLngObject(closestXResource.geometry.coordinates, this.mapDimensions);
      allAlignedResources.x = this._findAllAlignedResources(feature, closestXResource, 0);
    }

    if (closestYResource) {
      closestResources.y = toLatLngObject(closestYResource.geometry.coordinates, this.mapDimensions);
      allAlignedResources.y = this._findAllAlignedResources(feature, closestYResource, 1);
    }

    if (closestXResource) {
      if (closestYResource) {
        target.setLatLng({
          lat: closestResources.y.lat,
          lng: closestResources.x.lng,
        });
        this.polylineX = [[closestResources.y.lat, closestResources.x.lng]];
      } else {
        target.setLatLng({
          lat: markerLatLng.lat,
          lng: closestResources.x.lng,
        });
        this.polylineX = [[markerLatLng.lat, closestResources.x.lng]];
      }
      allAlignedResources.x.forEach((alignedResource) => {
        const alignedLatLng = toLatLngObject(alignedResource.geometry.coordinates, this.mapDimensions);
        this.polylineX.push([alignedLatLng.lat, alignedLatLng.lng]);
      });
    } else {
      this.polylineX = [];
    }

    if (closestYResource) {
      if (closestXResource) {
        target.setLatLng({
          lat: closestResources.y.lat,
          lng: closestResources.x.lng,
        });
        this.polylineY = [[closestResources.y.lat, closestResources.x.lng]];
      } else {
        target.setLatLng({ lat: closestResources.y.lat, lng: markerLatLng.lng });
        this.polylineY = [[closestResources.y.lat, markerLatLng.lng]];
      }
      allAlignedResources.y.forEach((alignedResource) => {
        const alignedLatLng = toLatLngObject(alignedResource.geometry.coordinates, this.mapDimensions);
        this.polylineY.push([alignedLatLng.lat, alignedLatLng.lng]);
      });
    } else {
      this.polylineY = [];
    }
  }

  bulkMoveSelectedFeatures(target, feature) {
    const { xPos: newXPos, yPos: newYPos } = toCoords(target.getLatLng(), this.mapDimensions);

    const xPosDiff = newXPos - (this.prevCoords ? this.prevCoords[0] : feature.geometry.coordinates[0]);
    const yPosDiff = newYPos - (this.prevCoords ? this.prevCoords[1] : feature.geometry.coordinates[1]);

    this.selectedFeatures.forEach((selectedFeature) => {
      if (selectedFeature === feature) return;

      const [xPos, yPos] = selectedFeature.geometry.coordinates;
      const geometry = { ...selectedFeature.geometry };
      geometry.coordinates = [xPos + xPosDiff, yPos + yPosDiff];
      set(selectedFeature, 'geometry', geometry);

      const desk = selectedFeature.desk
        ? selectedFeature.desk
        : this.store.peekRecord('desk', selectedFeature.externalId);
      set(desk, 'xPos', xPos + xPosDiff);
      set(desk, 'yPos', yPos + yPosDiff);
    });

    this.prevCoords = [newXPos, newYPos];
  }

  prevCoords = null;

  @action
  onDrag(feature, { target }) {
    if (this.selectedFeatures.length && this.selectedFeatures.includes(feature)) {
      this.bulkMoveSelectedFeatures(target, feature);
    }

    if (!this.isSnappingEnabled || this.selectedFeatures.length) return;
    this.generateSnapping(target, feature);
  }

  @action
  onDragEnd(feature, { target }) {
    this.resetCursor();
    this.polylineX = [];
    this.polylineY = [];

    const newLatLng = target.getLatLng();
    if (this.bounds.contains(newLatLng)) {
      const { xPos, yPos } = toCoords(newLatLng, this.mapDimensions);
      // this stinks but setting geometry properties directly does not make the model dirty
      const geometry = { ...feature.geometry };
      geometry.coordinates = [xPos, yPos];
      set(feature, 'geometry', geometry);
      if (feature.type === 'desk') {
        const desk = feature.desk ? feature.desk : this.store.peekRecord('desk', feature.externalId);
        set(desk, 'xPos', xPos);
        set(desk, 'yPos', yPos);
      }
    } else {
      target.setLatLng(toLatLngArray([feature.geometry.coordinates, this.mapDimensions]));
    }

    if (this.prevCoords) {
      this.metrics.trackEvent('Bulk moved desks', { numberOfDesks: this.selectedFeatures.length });
    } else {
      this.onResourceClick(feature, { target });
    }

    this.prevCoords = null;
  }

  /**
   * @param {MapFeature} feature Map Feature
   * @param {Element} el  A leaflet popup
   */
  @action
  handlePopupAdded(feature, el) {
    const popup = el.popup._contentNode.getBoundingClientRect();
    const leafletContainer = document.querySelector('.leaflet-container').getBoundingClientRect();
    const icon = document.querySelector(`.icon-for-feature-${feature.id}`).getBoundingClientRect();

    const CSS_MARGIN = 20; // match this exactly to what we use in the .leaflet-popup.left / .leaflet-popup.right css

    if (icon.left + icon.width + popup.width + CSS_MARGIN > leafletContainer.left + leafletContainer.width) {
      // NOTE: There seems to be an issue with ember leaflet, where you can set the `className` on the initial
      // popup render but when we determine direction and reset it, the changes are not applied to the element
      // This does not re-render in the template: `className={{if dm.popupToRight "right" "left"}}`
      // We can get around this by modifying the element directly.
      el.popup._container.classList.remove('right');
      el.popup._container.classList.add('left');
    } else {
      el.popup._container.classList.add('right');
      el.popup._container.classList.remove('left');
    }

    if (popup.top < leafletContainer.top) {
      el.popup._container.classList.add('bottom');
      el.popup._container.classList.remove('top');
    }

    if (popup.bottom > leafletContainer.bottom) {
      el.popup._container.classList.remove('bottom');
      el.popup._container.classList.add('top');
    }
  }

  @action
  removeFeature(feature) {
    const {
      onSelectedFeatureChange,
      setMapFeatures,
      mapFeatures,
      deletableFeatures,
      setDeletableFeatures,
      isDraftMap,
      updateResourceOverview,
    } = this.args;

    if (feature.geometry.type === 'Polygon') {
      this.args.leafletMap.eachLayer((layer) => {
        layer.options.isArea && layer.options.feature === feature && this.args.leafletMap.removeLayer(layer);
      });
    }

    if (!feature.isNew) {
      setDeletableFeatures([...deletableFeatures, feature]);
    }

    onSelectedFeatureChange(null);
    setMapFeatures(mapFeatures.filter((f) => f !== feature));

    if (updateResourceOverview && this.resourceOverviewEnabled) {
      updateResourceOverview('delete', feature);
      this.sidebarIsOpen = false;
    } else {
      this.sidebarIsOpen = true;
    }
    this.logDeleted({
      resource_type: underscore(feature.type),
      isDraft: isDraftMap,
    });
  }

  @action
  removeAreas() {
    if (this.args.deletableFeatures.any((feature) => feature.geometry.type === 'Polygon')) {
      this.args.deletableFeatures
        .filter((feature) => feature.geometry.type === 'Polygon')
        .forEach((feature) => {
          this.args.leafletMap.eachLayer((layer) => {
            layer.options.isArea && layer.options.feature === feature && this.args.leafletMap.removeLayer(layer);
          });
        });
    }
  }

  @action
  checkAvailableResources() {
    const { resourceOptions, placingResource, setPlacingResource, drawingArea, leafletMap } = this.args;

    if (!placingResource) return;
    if (resourceOptions[camelize(this.placingResourceType)]?.disabled) {
      if (this.isDrawing && drawingArea) {
        drawingArea.disable();
        leafletMap.dragging.enable();
      }
      return setPlacingResource(null);
    }
  }

  _findClosestResource(marker, axis, draggingResource) {
    return this.floorMapFeatures
      .filter(({ geometry }) => geometry.type === 'Point')
      .find((feature) => {
        const featureLatLng = toLatLngObject(feature.geometry.coordinates, this.mapDimensions);
        return (
          featureLatLng[axis] - this.snapDistanceWithZoom < marker[axis] &&
          marker[axis] < featureLatLng[axis] + this.snapDistanceWithZoom &&
          feature !== draggingResource
        );
      });
  }

  _findAllAlignedResources(resource, closestResource, coordsIndex) {
    return this.floorMapFeatures.filter(
      (feature) =>
        feature.geometry.type === 'Point' &&
        feature.geometry.coordinates[coordsIndex] === closestResource.geometry.coordinates[coordsIndex] &&
        resource !== feature,
    );
  }

  _findClosestEditingPoint(currentLatLng, points, axis) {
    return points.find((point) => {
      return (
        point[axis] - this.snapDistanceWithZoom < currentLatLng[axis] &&
        currentLatLng[axis] < point[axis] + this.snapDistanceWithZoom &&
        currentLatLng.lat !== point.lat &&
        currentLatLng.lng !== point.lng
      );
    });
  }

  @action
  onEditMarkerDrag({ target, latlng: currentCoords }, area) {
    if (!currentCoords || !this.isSnappingEnabled) return;

    const [existingPoints] = area.getLatLngs();
    const [closestXPoint, closestYPoint] = [
      this._findClosestEditingPoint(currentCoords, existingPoints, 'lng'),
      this._findClosestEditingPoint(currentCoords, existingPoints, 'lat'),
    ];

    if (closestXPoint) {
      if (closestYPoint) {
        target.setLatLng({ lng: closestXPoint.lng, lat: closestYPoint.lat }).update();
      } else {
        target.setLatLng({ lng: closestXPoint.lng, lat: currentCoords.lat }).update();
      }
    }

    if (closestYPoint) {
      if (closestXPoint) {
        target.setLatLng({ lng: closestXPoint.lng, lat: closestYPoint.lat }).update();
      } else {
        target.setLatLng({ lng: currentCoords.lng, lat: closestYPoint.lat }).update();
      }
    }

    if (closestXPoint || closestYPoint) {
      target.fire('drag');
      target.fire('dragend');
    }
  }

  _registerDrawingHandlers(map) {
    leaflet.drawLocal.draw.handlers.polygon.tooltip = {
      start: null,
      cont: null,
      end: 'Click first point to close this shape',
    };

    const reset = () => {
      this.args.setPlacingResource(null);
      this.args.leafletMap.dragging.enable();
      leaflet.drawLocal.draw.handlers.polygon.tooltip.end = null;
    };

    map
      .on('draw:created', (e) => {
        const { layer } = e;
        const { onSelectedFeatureChange, setMapFeatures, mapFeatures, placingResource, isDraftMap } = this.args;
        const [layerCoords] = layer.getLatLngs();
        const coordinates = layerCoords.map((latLng) => {
          const { xPos, yPos } = toCoords(latLng, this.mapDimensions);
          return [xPos, yPos];
        });

        const newFeature = this.createFeature(placingResource, coordinates);

        onSelectedFeatureChange(newFeature);
        setMapFeatures([...mapFeatures, newFeature]);

        this.logPlaced({
          resource_type: underscore(this.placingResourceType),
          isDraft: isDraftMap,
        });

        reset();
        this.args.drawArea(placingResource);
      })
      .on('draw:drawvertex', () => {
        this.drawStarted = true;
      })
      .on('draw:canceled', () => {
        reset();
        this.args.setPlacingResource(null);
      });
  }

  loadImageTask = task({ drop: true }, async () => {
    this.isReady = false;
    await new Promise((resolve, reject) => {
      const image = new Image();
      image.src = this.args.rasterImageUrl;
      image.onerror = (err) => {
        // eslint-disable-next-line no-console
        console.error('error loading map', err);
        reject();
      };
      image.onload = (ev) => {
        const { height: mapHeight, width: mapWidth } = ev.target;
        this.mapHeight = mapHeight;
        this.mapWidth = mapWidth;
        resolve();
      };
    });
  });

  @action
  _registerMarkerTestSelectors(feature, { target }) {
    // stank, cannot assign html attributes to <layer.marker /> directly
    target._icon.dataset.testMarker = true;
    target._icon.dataset.testFeatureType = feature.type;
    target._icon.dataset.testFeatureId = feature.id;
  }

  _validateFeature(feature) {
    if (feature.type !== 'desk') {
      const isValid = feature.requiredFields.every((field) => isPresent(feature[field]) && !feature.hasError);
      feature.hasError = !isValid;
    }
  }
}
