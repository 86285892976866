import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type FlowModel from 'garaje/models/flow';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { SettingsEmailsRouteModel } from '../route';

interface SettingsEmailsTemplatesRouteParams {
  mailer_template_id: string;
}

export interface SettingsEmailsTemplatesRouteModel {
  mailerTemplate: MailerTemplateModel;
  mailerTemplateVersionsByCompany: RecordArray<MailerTemplateVersionModel>;
  mailerTemplateVersionsByLocation: RecordArray<MailerTemplateVersionModel>;
  flows: RecordArray<FlowModel>;
}

export default class SettingsEmailsTemplatesRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    const { mailer_template_id } = <SettingsEmailsTemplatesRouteParams>this.paramsFor(this.routeName);
    this.redirectIfNotValid(mailer_template_id);
  }

  model({ mailer_template_id }: SettingsEmailsTemplatesRouteParams): Promise<SettingsEmailsTemplatesRouteModel> {
    const { store, state } = this;
    const { currentLocation, currentCompany } = state;

    // Always reload mailer-template and associated mailer-block models.
    // The mailer-template's mailer-block model's settings are generated based on location and cannot be cached
    const mailerTemplate = store.findRecord('mailer-template', mailer_template_id, {
      reload: true,
      include: 'mailer-template-sections.mailer-section.mailer-section-blocks.mailer-block',
    });

    const flows = store.query('flow', {
      include: 'global-flow',
      filter: { location: currentLocation.id }, // filter includes only regular flows
    });

    const mailerTemplateVersionsByCompany = store.query('mailer-template-version', {
      filter: {
        'mailer-template-id': mailer_template_id,
        'company-id': currentCompany.id,
      },
      include: 'flows',
    });

    const mailerTemplateVersionsByLocation = store.query('mailer-template-version', {
      filter: {
        'mailer-template-id': mailer_template_id,
        'location-id': currentLocation.id,
      },
      include: 'flows',
    });

    return hash({
      mailerTemplate,
      mailerTemplateVersionsByCompany,
      mailerTemplateVersionsByLocation,
      flows,
    });
  }

  redirectIfNotValid(id: string): void {
    const mailerTemplates = (<SettingsEmailsRouteModel>(
      this.modelFor('visitors.settings.emails')
    )).mailerTemplates.toArray();
    const isValid = mailerTemplates.map((mailerTemplate) => mailerTemplate.id).includes(id);

    if (!isValid && mailerTemplates[0]) {
      void this.router.replaceWith('visitors.settings.emails.templates', mailerTemplates[0].id);
    }
  }
}
