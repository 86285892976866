import { A } from '@ember/array';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { bool, not, and, or } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, subMinutes, fromUnixTime } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { task, timeout } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type ReservationModel from 'garaje/models/reservation';
import type SubscriptionModel from 'garaje/models/subscription';
import type { InvitesDashboardField } from 'garaje/pods/visitors/invites/index/controller';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LoggerService from 'garaje/services/logger';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import zft from 'garaje/utils/zero-for-tests';
import moment from 'moment-timezone';

type Context = 'location' | 'property';

interface InvitesTableInviteFeedItemComponentArgs {
  canPrintBadges: boolean;
  checkbox: string;
  /**
   * The context in which the component is being used
   */
  context: Context;
  deleteInvites: (invite: InviteModel) => void;
  fields: InvitesDashboardField[];
  invite: InviteModel;
  isSelected: boolean;
  location: LocationModel;
  preprintBadge: (invite: InviteModel, printer: PrinterModel) => void;
  printers: PrinterModel[];
  showFullDate: boolean;
  vrSubscription: SubscriptionModel;
}

export default class InvitesTableInviteFeedItemComponent extends Component<InvitesTableInviteFeedItemComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare logger: LoggerService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  @tracked displayPrinterSelectorModal = false;
  @tracked markVisitorAsLate = false;
  @tracked showSignInModal = false;

  @tracked itemFullNameWasTruncated = false;
  @tracked itemHostWasTruncated = false;

  id = guidFor(this);
  tooltipDelay = zft(400);

  @bool('args.invite.entry.signInTime') visitorIsSignedIn!: boolean;
  @not('visitorIsSignedIn') visitorIsNotSignedIn!: boolean;
  @bool('args.invite.entry.signOutTime') visitorIsSignedOut!: boolean;
  @not('visitorIsSignedOut') visitorIsNotSignedOut!: boolean;
  @or('args.invite.needsApprovalReview', 'args.invite.approvalWasDenied') visitorIsDisallowedAccess!: boolean;
  @not('visitorIsDisallowedAccess') visitorIsAllowedAccess!: boolean;
  @and('visitorIsSignedIn', 'visitorIsNotSignedOut') markVisitorAsSignedIn!: boolean;
  @and('args.invite.needsApprovalReview', 'isBeforeToday') isExpired!: boolean;

  @or('visitorIsSignedOut', 'markVisitorAsLate', 'markVisitorAsSignedIn') showTooltip!: boolean;

  get isBeforeToday(): boolean {
    return moment(this.args.invite.expectedArrivalTime).isBefore(moment(), 'day');
  }

  get deniedColspan(): number {
    // NOTE colspan for the denied message is equal the the number of shown fields minus 1
    // for the name field, which is already in the row and is not included in the colspan
    return this.args.fields.filter((field) => field.show).length - 1;
  }

  get context(): Context {
    return this.args.context ?? 'location';
  }

  get cannotReviewInvite(): boolean {
    return this.abilities.cannot('review entry-approval', {
      context: this.context,
      report: this.args.invite.approvalStatus?.failedReport,
    });
  }

  get fieldCount(): number {
    return A(this.args.fields ?? []).filterBy('show').length;
  }

  get canPrint(): boolean {
    return (
      !this.args.invite.isFromEmployeeScreening &&
      this.visitorIsAllowedAccess &&
      this.args.canPrintBadges &&
      this.args.printers.length > 0
    );
  }

  get canInviteAgain(): boolean {
    return !this.args.invite.isFromEmployeeScreening && !this.args.invite.isFromWalkUp && this.visitorIsAllowedAccess;
  }

  get canSignInFromInvite(): boolean {
    return this.abilities.can('create entries') && this.args.vrSubscription?.canSignInFromInvite;
  }

  get canSignIn(): boolean {
    return this.visitorIsNotSignedIn && this.visitorIsAllowedAccess && this.canSignInFromInvite;
  }

  get canDelete(): boolean {
    return this.visitorIsNotSignedIn && this.abilities.can('delete invite', this.args.invite);
  }

  get hasNoOptions(): boolean {
    return !this.canPrint && !this.canInviteAgain && !this.canSignIn && !this.canDelete;
  }

  markVisitorAsLateTask = task({ on: 'init' }, async () => {
    const expectedAt = moment(this.args.invite.expectedArrivalTime);

    while (true) {
      if (config.environment === 'test' || this.visitorIsSignedIn || this.markVisitorAsLate) {
        return;
      }

      const now = moment();
      const diff = expectedAt.diff(now, 'minutes');
      if (diff < -20) {
        this.markVisitorAsLate = true;
      }
      await timeout(5000);
    }
  });

  @action
  displayReservationTime(reservation: ReservationModel): string {
    if (reservation.isPartialDay) {
      const start = subMinutes(fromUnixTime(reservation.startTime), this.state.minutesBetweenTimezones);
      const end = subMinutes(fromUnixTime(reservation.endTime), this.state.minutesBetweenTimezones);
      return `${format(start, 'h:mmaa')} - ${format(end, 'h:mmaa')}`;
    }
    return 'All day';
  }

  @action
  signIn(): void {
    this.showSignInModal = true;
  }

  @action
  closeSignIn(): void {
    this.showSignInModal = false;
  }

  @action
  trackOpenActionMenu(inviteId: string): void {
    this.metrics.trackEvent('Invite Overflow Opened', { invite_id: inviteId });
  }

  @action
  trackPreprintBadgeRequested(invite: InviteModel): void {
    const properties = { invite_id: parseInt(invite.id, 10) };
    this.metrics.trackEvent('Preprint Invite Badge Requested', properties);
  }

  @action
  inviteAgain(invite: InviteModel): void {
    const { visitorEmail: email, fullName: name, id: previousInvite } = invite;
    const qpEmail = email || invite.email; // Fallback to email param

    void this.router.transitionTo('visitors.invites.new', {
      queryParams: { email: qpEmail, name, previousInvite },
    });

    this.metrics.trackEvent('Invites Invited Again', { invite_id: previousInvite });
  }

  @action
  preprintBadge(invite: InviteModel, printer: PrinterModel): void {
    this.args.preprintBadge(invite, printer);
    this.trackPreprintBadgeRequested(invite);
  }
}
