import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import _merge from 'lodash/merge';
import _isEqual from 'lodash/isEqual';
import { get } from '@ember/object';
import { next } from '@ember/runloop';

@adapter('v3')
class MailerSectionBlockOverride extends Model {
  constructor() {
    super(...arguments);
    next(() => {
      if (this.isNew) {
        this.settings = _merge({}, get(this, 'mailerSectionBlock.settings'), this.settings);
        this.position = get(this, 'mailerSectionBlock.position');
        this.active = get(this, 'mailerSectionBlock.active');
      }
    });
  }

  // Relationships
  @belongsTo('mailer-section-block') mailerSectionBlock;
  @belongsTo('mailer-template-version') mailerTemplateVersion;

  // Attributes
  @attr('immutable', { defaultValue: () => ({}) }) settings;
  @attr('number') position;
  @attr('boolean', { defaultValue: true }) active;

  get isDefault() {
    return (
      this.active === get(this, 'mailerSectionBlock.active') &&
      this.position === get(this, 'mailerSectionBlock.position') &&
      _isEqual(this.settings, get(this, 'mailerSectionBlock.settings'))
    );
  }

  get isDirty() {
    if (this.isNew) return !this.isDefault;
    return this.hasDirtyAttributes;
  }
}

export default MailerSectionBlockOverride;
