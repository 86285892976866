import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { and, alias } from 'macro-decorators';
import { set } from '@ember/object';
import { all, restartableTask } from 'ember-concurrency';
import { action } from '@ember/object';

export default class extends Component {
  @service trial;
  @service state;
  @service metrics;

  @alias('state.vrSubscription') vrSubscription;

  @and('args.agreementPage.enabled', 'vrSubscription.includesNda') legalDocumentsEnabled;

  @restartableTask
  *updateAgreementPositions(agreements) {
    yield all(
      agreements.map((agreement, index) => {
        set(agreement, 'position', index + 1);
        return agreement.save({ propagable: false });
      }),
    );
  }

  @action
  toggleAgreementEnabled(agreement, value) {
    this.args.updateAndSaveTask.perform(agreement, 'enabled', value, false);
  }

  @action
  toggleAgreementPageEnabled(agreementPage, value) {
    this.args.updateAndSaveTask.perform(agreementPage, 'enabled', value, false);
    this.args.trackFeatureToggled(value);
  }
}
