import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { buildChangeset, FakeChangeset } from 'garaje/utils/legal-document';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { alias } from 'macro-decorators';

const MINIMUM_IPAD_VERSION_NEEDED = '3.3.6';

export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsEditController extends Controller {
  @service currentAdmin;
  @service currentLocation;
  @service metrics;

  @tracked bccChangeset;
  @tracked ndaChangeset;
  @tracked changeset;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED) isAppUpdateRequired;
  @alias('currentLocation.languagesAvailable') translationLanguagesAvailable;

  get translationEnabledLocales() {
    return [get(this.currentLocation, 'enabledLocales'), get(this.currentLocation, 'locale')].flat().uniq();
  }

  setupChangeset() {
    this.bccChangeset = buildChangeset(this.model.agreement); // changeset to keep track of sendToBcc and bccEmail changes
    this.ndaChangeset = buildChangeset(this.model.agreement); // changeset to keep track of body, require-resign and duration changes
    this.changeset = new FakeChangeset([this.bccChangeset, this.ndaChangeset]); // fake changeset wrapping the two main sections changesets
  }

  get legalDocumentHeader() {
    return document.getElementById('legal-document-header');
  }

  trackDeleteClicked(id) {
    this.metrics.trackEvent('Legal Docs - Delete Legal Document Button Clicked', {
      legal_doc_id: id,
      location_id: this.model.tracking.currentLocationId,
    });
  }

  @action
  handleDelete() {
    const id = this.model.agreement.id;
    this.model.agreement.deleteRecord();
    this.trackDeleteClicked(id);
    this.transitionToRoute('visitors.settings.visitor-types.flow.legal-documents', this.model.flow.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}
