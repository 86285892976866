import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class CreateMailerTemplateVersionModalComponent extends Component {
  @service store;
  @service state;

  @tracked title = '';

  get isValidTitle() {
    return this.title.trim().length > 0;
  }

  @dropTask
  *submitTask(e) {
    e.preventDefault();
    const { title, store, state } = this;
    const { currentLocation } = state;
    const { mailerTemplate, onSave } = this.args;
    const mailerTemplateVersion = store.createRecord('mailer-template-version', {
      title,
      mailerTemplate,
      locations: [currentLocation],
    });
    yield onSave(mailerTemplateVersion);
  }

  @action
  setTitle(title) {
    this.title = title;
  }
}
