import { service } from '@ember/service';
import type { Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type FeatureFlagsService from 'garaje/services/feature-flags';

import ApplicationSerializer from './application';

export default class MailerTemplateSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  constructor(properties: object) {
    super(properties);

    const isV2 = this.featureFlags.isEnabled('visitors-mailer-templates-v2-m1');

    this.attrs = {
      templateFontFace: {
        serialize: isV2,
      },
      templateTextAlignment: {
        serialize: isV2,
      },
      envoyDefaultId: {
        serialize: isV2,
      },
      companies: {
        serialize: isV2,
      },
      locations: {
        serialize: isV2,
      },
      flows: {
        serialize: isV2,
      },
      sections: {
        serialize: isV2,
      },
      active: {
        serialize: false,
      },
      envoyDefault: {
        serialize: false,
      },
      description: {
        serialize: false,
      },
      identifier: {
        serialize: false,
      },
      subject: {
        serialize: false,
      },
      subtitle: {
        serialize: false,
      },
    };
  }

  keyForRelationship(key: string, relationship: string, method: string): string {
    if (key === 'sections') {
      return 'mailer-sections';
    } else {
      return super.keyForRelationship(key, relationship, method);
    }
  }

  // Avoid 400 error. Do not serialize envoyDefaultId for existing records
  serializeAttribute(snapshot: Snapshot<keyof ModelRegistry>, json: object, key: string, attribute: object): void {
    const record = <MailerTemplateModel>snapshot.record;

    if (!record?.isNew && key === 'envoyDefaultId') return;

    super.serializeAttribute(snapshot, json, key, attribute);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'mailer-template': MailerTemplateSerializer;
  }
}
