import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type CompanyConfigurationModel from 'garaje/models/company-configuration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type InactivityService from 'garaje/services/inactivity';
import type StateService from 'garaje/services/state';

enum INACTIVITY_TIME_OPTIONS {
  FIFTEEN_MINS = '15 minutes',
  THIRTY_MINS = '30 minutes',
  ONE_HR = '1 hour',
  TWO_HRS = '2 hours',
}

type MillisecondsToTimeType = {
  [key: number]: INACTIVITY_TIME_OPTIONS;
};

const MILLISECONDS_TO_TIME_MAP: MillisecondsToTimeType = {
  900000: INACTIVITY_TIME_OPTIONS.FIFTEEN_MINS,
  1800000: INACTIVITY_TIME_OPTIONS.THIRTY_MINS,
  3600000: INACTIVITY_TIME_OPTIONS.ONE_HR,
  7200000: INACTIVITY_TIME_OPTIONS.TWO_HRS,
};

class LocationOverviewManageAccountSettingsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare inactivity: InactivityService;
  @service declare state: StateService;

  @tracked isInactivityBoxOpen: boolean = false;
  @tracked inactivityTimeout: number | null = this.model?.sessionInactivityTimeout || null;

  declare model: CompanyConfigurationModel | undefined;

  get headerTitle(): string {
    const companyName = this.state.currentCompany?.name;
    return companyName ? `Account settings for ${companyName}` : 'Account settings';
  }

  get inactivityDescription(): INACTIVITY_TIME_OPTIONS | undefined {
    const sessionTimeout: number = this.model?.sessionInactivityTimeout || 7200000;
    return MILLISECONDS_TO_TIME_MAP[sessionTimeout];
  }

  get isInactivityEnabled(): boolean {
    const companyConfiguration = this.model;
    return Boolean(companyConfiguration?.sessionInactivityTimeout);
  }

  get inactivityOptions(): Array<number> {
    return [900000, 1800000, 3600000, 7200000];
  }

  get isSaveBtnDisabled(): boolean {
    return this.model?.sessionInactivityTimeout === this.inactivityTimeout;
  }

  displayTimeoutPeriod(timeout: number): string {
    return MILLISECONDS_TO_TIME_MAP[timeout] || '';
  }

  @action
  openInactivityBox(): void {
    this.isInactivityBoxOpen = true;
  }

  @action
  closeInactivityBox(): void {
    this.isInactivityBoxOpen = false;
    this.inactivityTimeout = this.model?.sessionInactivityTimeout || null;
  }

  enableInactivityTask = dropTask(async () => {
    const companyConfiguration = this.model;
    try {
      if (companyConfiguration) {
        this.inactivityTimeout = 7200000;
        companyConfiguration.sessionInactivityTimeout = this.inactivityTimeout;
        await companyConfiguration.save();
        // start the timer
        await this.inactivity.initialize();
        this.flashMessages.showAndHideFlash('success', 'Inactivity based timeout enabled');
        this.openInactivityBox();
      }
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again');
    }
  });

  disableInactivityTask = dropTask(async () => {
    const companyConfiguration = this.model;
    try {
      if (companyConfiguration) {
        this.inactivityTimeout = null;
        companyConfiguration.sessionInactivityTimeout = this.inactivityTimeout;
        await companyConfiguration.save();
        // stop the timer
        this.inactivity.cleanUp();
        this.flashMessages.showAndHideFlash('success', 'Inactivity based timeout disabled');
        this.isInactivityBoxOpen = false;
      }
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again');
    }
  });

  saveInactivityTask = dropTask(async () => {
    const companyConfiguration = this.model;
    try {
      if (companyConfiguration) {
        companyConfiguration.sessionInactivityTimeout = this.inactivityTimeout;
        await companyConfiguration.save();
        // change the timeout duration
        this.inactivity.timeoutDuration = this.inactivityTimeout;
        this.flashMessages.showAndHideFlash('success', 'Inactivity timeout period updated');
        this.isInactivityBoxOpen = false;
      }
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again');
    }
  });
}

export default LocationOverviewManageAccountSettingsController;
