import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class FullLegalName extends Component {
  @service abilities;
  @service authz;
  @service flashMessages;

  toggleTask = task({ drop: true }, async () => {
    this.args.config.legalNameValidationEnabled = !this.args.config.legalNameValidationEnabled;

    try {
      await this.args.config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.args.config.rollbackAttributes();
    }
  });

  get isDisabled() {
    return this.toggleTask.isRunning || !this.abilities.can('update full legal name setting in security');
  }
}
