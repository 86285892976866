import { action } from '@ember/object';
import Component from '@glimmer/component';

import type MailerSectionBlockAttributeObjectProxy from '../lib/mailer-section-block-attribute-object-proxy';

interface MailerTemplateEditorAttributeComponentSignature {
  Args: {
    attribute: MailerSectionBlockAttributeObjectProxy;
    onUpdate?: () => void;
  };
}

export default class MailerTemplateEditorAttributeComponent extends Component<MailerTemplateEditorAttributeComponentSignature> {
  @action
  resetAttribute(): void {
    this.args.attribute.reset();
    this.args.onUpdate?.();
  }

  @action
  setAttributeValue(value: string): void {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.args.attribute.set('value', value);
    this.args.onUpdate?.();
  }
}
