// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, action, get, set } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';

const RESIGN_DAYS = 30;
const RESIGN_OPTIONS = ['', 'days', 'always'];

/**
 * @param {Object}                        changeset
 * @param {Boolean}                       disabled
 * @param {Class<Agreement>}              model
 * @param {Boolean}                       propagable
 * @param {Function}                      trackLegalDocument
 * @param {Class<Subscription>}           vrSubscription
 * @param {Function}                      updateAndSaveTask
 */
export default class ReSign extends Component {
  isEditingResign = false;
  agreementDurationError = '';
  defaultDays = RESIGN_DAYS;

  @computed('agreementDurationError', 'resignOption', 'updateAndSaveTask.isRunning')
  get isSaveResignDisabled() {
    const isInvalid = get(this.changeset, 'isInvalid');
    const isClean = !get(this.changeset, 'isDirty');
    const isRunning = this.updateAndSaveTask.isRunning;
    const noOption = this.resignOption === '';
    const hasError = this.agreementDurationError !== '';
    return isInvalid || isClean || isRunning || noOption || hasError;
  }

  @computed('isEditingResign', 'disabled', 'changeset')
  get shouldOpenResignPanel() {
    return this.isEditingResign || (this.disabled && get(this.changeset, 'duration') > 0);
  }

  setupResignOption() {
    const days = get(this.changeset, 'duration');
    return days === null ? 'always' : days === 0 ? '' : 'days';
  }

  applyChangeset(changeset) {
    changeset.execute();
    changeset.rollback();
  }

  rollbackChangeset(changeset) {
    changeset.rollback();
  }

  isValidResignDays(value) {
    const days = parseInt(value, 10);
    return !isNaN(days) && days > 0;
  }

  @action
  rollbackResignSetting(changeset) {
    this.rollbackChangeset(changeset);
    set(this, 'resignOption', this.setupResignOption(changeset));
    this.defaultDays = RESIGN_DAYS;
    set(this, 'agreementDurationError', '');
  }

  @action
  saveResignSetting(changeset) {
    this.applyChangeset(changeset);
    this.updateAndSaveTask.perform({ model: this.model }).then(() => {
      set(this, 'isEditingResign', false);
      this.trackLegalDocument();
    });
  }

  @action
  resignDaysOptionChanged(changeset) {
    set(this, 'resignOption', 'days');
    set(changeset, 'duration', this.defaultDays);
    /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
    scheduleOnce('afterRender', () => document.querySelector('#days-input').focus());
    /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
  }

  @action
  resignAlwaysOptionChanged(changeset) {
    set(this, 'resignOption', 'always');
    set(changeset, 'duration', null);
    if (!this.isValidResignDays(this.resignDays)) {
      set(this, 'defaultDays', RESIGN_DAYS);
    }
    set(this, 'agreementDurationError', '');
  }

  @action
  disableResignSetting(changeset) {
    set(this, 'resignOption', '');
    set(this, 'agreementDurationError', '');
    set(this, 'defaultDays', RESIGN_DAYS);
    set(changeset, 'duration', 0);
    if (get(this.changeset, 'isDirty')) {
      this.applyChangeset(changeset);
      this.updateAndSaveTask.perform({ model: this.model, propagable: this.propagable }).then(() => {
        set(this, 'isEditingResign', false);
        this.trackLegalDocument();
      });
    } else {
      set(this, 'isEditingResign', false);
    }
  }

  @action
  validateResignDays(days) {
    set(this, 'agreementDurationError', this.isValidResignDays(days) ? '' : 'Minimum is 1');
  }

  @computed('defaultDays')
  get resignDays() {
    const defaultDays = this.defaultDays;
    const days = get(this.changeset, 'duration');
    const neverResign = days === 0;
    const alwaysResign = days === null;
    return neverResign || alwaysResign ? defaultDays : days;
  }

  set resignDays(value) {
    let _val = parseInt(value, 10);
    _val = isNaN(_val) ? null : _val;
    set(this.changeset, 'duration', _val);
    set(this, 'defaultDays', _val ? _val : RESIGN_DAYS);
  }

  get resignOption() {
    const value = this._resignOption;
    if (RESIGN_OPTIONS.includes(value)) {
      return value;
    } else {
      return this.setupResignOption();
    }
  }

  set resignOption(value) {
    if (RESIGN_OPTIONS.includes(value)) {
      this._resignOption = value;
    }
  }
}
