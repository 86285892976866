import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class EmailSidebarSettingsAttributeHtmlComponent extends Component {
  get extensions() {
    const { uiHooks } = this.args;
    if (isPresent(uiHooks)) {
      return uiHooks.map(({ clientName, label, token, icon }) => ({
        label: `${clientName} - ${label}`,
        token,
        icon,
      }));
    }
    return undefined;
  }
}
