import { run, later, cancel } from '@ember/runloop';
import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { set } from '@ember/object';
import OAuth2PasswordGrantAuthenticator from 'ember-simple-auth/authenticators/oauth2-password-grant';
import config from 'garaje/config/environment';
import $ from 'jquery';
import RSVP from 'rsvp';
import fetch from 'fetch';

const SCOPE = ['first_party', 'identity.basic', 'public', 'token.refresh'].join(',');

export default OAuth2PasswordGrantAuthenticator.extend({
  cookies: service(),
  externalAuthentication: service(),
  windowLocation: service(),
  localStorage: service(),

  serverTokenEndpoint: `${config.apiHost}/a/auth/v0/token`,

  clientId: config.garajeClientId,

  authenticate({ companyId, email, password, redirectFrom, scope = SCOPE, headers = {} }) {
    const isFromLegacyMobile = redirectFrom === 'legacy-mobile';

    return new RSVP.Promise((resolve, reject) => {
      const data = {
        grant_type: 'password',
        company_id: companyId,
        password,
        scope,
        username: email,
      };

      if (isFromLegacyMobile) {
        data.legacy_mobile = true;
      }

      const serverTokenEndpoint = this.serverTokenEndpoint;

      this.makeRequest(serverTokenEndpoint, data, headers).then(
        (response) => {
          run(() => {
            if (!this._validate(response)) {
              reject('access_token is missing in server response');
            }

            const expiresIn = response['expires_in'] || this.cookies.read('expires_in');
            const expiresAt = this._absolutizeExpirationTime(expiresIn);
            this._scheduleAccessTokenRefresh(expiresIn, expiresAt, response['refresh_token']);
            if (!isEmpty(expiresAt)) {
              response = Object.assign(response, { expires_at: expiresAt });
            }

            resolve(response);
          });
        },
        (response) => {
          run(null, reject, response.responseJSON || response.responseText);
        },
      );
    }).then((value) => {
      if (isFromLegacyMobile) {
        const redirectUri = `${config.host}/oauth.html#${$.param(value)}`;
        this.windowLocation.replace(redirectUri);
      }
      return value;
    });
  },

  invalidate(_data) {
    const externalAuthentication = this.externalAuthentication;

    // cleanup all next.js apps loggedin state from localstorage
    this.localStorage.removeItem('wa_logged_in'); // workplace-analytics
    this.localStorage.removeItem('as_logged_in'); // app-store
    this.localStorage.removeItem('dd_logged_in'); // dev-dashboard

    return RSVP.all([this._super(...arguments), externalAuthentication.invalidateEnvoyAuth()]);
  },

  /*
    This is a copy-paste from ember-simple-auth 6.0.0 with some needed modifications (noted with "begin"/"end" comments)
    to work with envoy-web:
    * the `fetch()` call is changed to have `credentials: 'include'` so that cookies are included in the request.
    * If `refresh_token` would have an empty value, omit it from the request entirely.
    * Include `X-CSRF-Token` header with a value from the `csrf_token` cookie

    https://github.com/mainmatter/ember-simple-auth/blob/6.0.0/packages/ember-simple-auth/src/authenticators/oauth2-password-grant.js#L286-L322
  */
  makeRequest(url, data, headers = {}) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';

    const clientId = this.clientId;
    if (!isEmpty(clientId)) {
      data['client_id'] = clientId;
    }

    const body = Object.keys(data)
      /* begin customization */
      .filter((key) => {
        if (key === 'refresh_token') {
          const refreshToken = data[key];
          // `refreshToken` is undefined if the backend returns an empty hash.
          // in this scenario, we want envoy-web to pick up the refresh token in
          // the cookie. it does this when it doesn't not find
          // `params[:refresh_token]`.
          return isPresent(refreshToken);
        }
        return true;
      })
      /* end customization */
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
      })
      .join('&');

    /* begin customization */
    const csrfToken = this.cookies.read('csrf_token');
    if (csrfToken) {
      headers = {
        ...headers,
        'X-CSRF-Token': csrfToken,
      };
    }
    /* end customization */

    const options = {
      body,
      headers,
      method: 'POST',
      /* begin customization */
      credentials: 'include',
      /* end customization */
    };

    return new RSVP.Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          response.text().then((text) => {
            try {
              const json = JSON.parse(text);
              if (!response.ok) {
                response.responseJSON = json;
                reject(response);
              } else {
                resolve(json);
              }
            } catch (SyntaxError) {
              response.responseText = text;
              reject(response);
            }
          });
        })
        .catch(reject);
    });
  },

  _validate() {
    return this._super(...arguments) || !isEmpty(this.cookies.read('csrf_token'));
  },

  // This customization is primarily to handle the scenario when we log in with
  // email and password but get returned an empty hash instead of the JWT data.
  _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
    if (this.refreshAccessTokens) {
      const now = new Date().getTime();
      if (isEmpty(expiresAt) && isPresent(expiresIn)) {
        expiresAt = new Date(now + expiresIn * 1000).getTime();
      }
      const offset = this.tokenRefreshOffset;
      // NOTE: we removed the isEmpty(refreshToken) check from the next line
      if (isPresent(expiresAt) && expiresAt > now - offset) {
        cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        if (config.environment === 'test') {
          set(this, '_didScheduleRefreshTokenInTheFuture', true);
        } else {
          this._refreshTokenTimeout = later(
            this,
            this._refreshAccessToken,
            expiresIn,
            refreshToken,
            expiresAt - now - offset,
          );
        }
      }
    }
  },
});
