import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import { type PendingUpload } from 'garaje/pods/components/properties/property-form/cover-photo-config/component';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

/**
 * @param {Changeset<Property>} changeset
 * @param {Function}            onPhotoLoading
 * @param {Task}                saveTask
 */

interface PropertyFormLogoConfigArgs {
  changeset: DetailedChangeset<ZoneModel>;
  onPhotoLoading: (isLoading: boolean) => unknown;
  saveTask: Task<void, [DetailedChangeset<ZoneModel>]>;
}

export default class PropertyFormLogoConfig extends Component<PropertyFormLogoConfigArgs> {
  @tracked isOpen = false;
  @tracked pendingUpload: PendingUpload | null = null;

  isEmpty = !this.args.changeset.logo;

  @action
  onPendingUpload(pendingUpload: PendingUpload): void {
    this.pendingUpload = pendingUpload;
    // use file name to mark file dirty
    this.args.changeset.logo = pendingUpload.file.name;
  }

  @action
  cancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  @action
  reset(): void {
    this.pendingUpload?.reset();

    if (this.isEmpty) {
      this.args.changeset.rollbackProperty('logo');
    } else {
      this.args.changeset.logo = null;
    }

    this.pendingUpload = null;
  }

  saveTask = dropTask(async () => {
    this.args.onPhotoLoading(true);

    if (this.pendingUpload) {
      this.args.changeset.logo = <string>await this.pendingUpload.upload().catch(throwUnlessTaskDidCancel);
    }

    await this.args.saveTask.perform(this.args.changeset).catch(throwUnlessTaskDidCancel);

    this.isOpen = false;
    this.pendingUpload = null;
    this.isEmpty = !this.args.changeset.logo;
    this.args.onPhotoLoading(false);
  });
}
