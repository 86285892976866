import { service } from '@ember/service';
import type { Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { SinglePayload } from 'jsonapi/response';

import ApplicationSerializer from './application';

export default class GlobalAgreementSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  attrs = {
    documentUrl: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key: string): string {
    if (key === 'agreement-pages') {
      return 'global-agreement-page';
    } else if (key === 'agreements') {
      return 'global-agreement';
    } else {
      return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(modelName: keyof ModelRegistry): string {
    if (modelName === 'global-agreement-page') {
      return 'agreement-pages';
    } else if (modelName === 'global-agreement') {
      return 'agreements';
    } else {
      return super.payloadKeyFromModelName(modelName);
    }
  }

  serialize(snapshot: Snapshot<keyof ModelRegistry>, options: object): SinglePayload<GlobalAgreementModel> {
    const json = <SinglePayload<GlobalAgreementModel>>super.serialize(snapshot, options);

    if (!this.featureFlags.isEnabled('visitors-legal-doc-pdf-upload')) {
      delete json.data.attributes.footer;
      delete json.data.attributes['document'];
    }
    return json;
  }
}
