import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isNone } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type { Task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type MailerSectionBlockModel from 'garaje/models/mailer-section-block';
import type MailerSectionBlockOverrideModel from 'garaje/models/mailer-section-block-override';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type MailerTemplateSectionModel from 'garaje/models/mailer-template-section';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';
import type UiHookModel from 'garaje/models/ui-hook';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { SettingsEmailsTemplatesRouteModel } from '../route';

import type SettingsEmailsTemplatesVersionsController from './controller';

export interface SettingsEmailsTemplatesVersionsRouteModel {
  mailerTemplate: MailerTemplateModel;
  currentCompany: CompanyModel;
  mailerTemplateVersion: MailerTemplateVersionModel;
  mailerSectionBlockOverrides: ArrayProxy<MailerSectionBlockOverrideModel>;
  mailerTemplateVersionsByCompany: RecordArray<MailerTemplateVersionModel>;
  mailerTemplateVersionsByLocation: RecordArray<MailerTemplateVersionModel>;
  mailerTemplateSections: ArrayProxy<MailerTemplateSectionModel> | null;
  uiHooks?: RecordArray<UiHookModel>;
}

// Used for mapping UIHooks triggerName filter to a given mailer-template identifier.
// TODO - Add this to the mailer-template model.
const TRIGGER_NAME_MAPPING = {
  ['welcome']: 'welcome_email',
  ['registration']: 'registration_email',
};
export default class SettingsEmailsTemplatesVersionsRoute extends Route {
  declare controller: SettingsEmailsTemplatesVersionsController;

  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  async generateOverridesForSection(
    mailerSection: MailerSectionModel,
    mailerTemplateVersion: MailerTemplateVersionModel,
  ): Promise<void> {
    for (const mailerSectionBlock of mailerSection.mailerSectionBlocks.slice()) {
      await this.maybeCreateOverride(mailerSectionBlock, mailerTemplateVersion);
    }
  }

  async maybeCreateOverride(
    mailerSectionBlock: MailerSectionBlockModel,
    mailerTemplateVersion: MailerTemplateVersionModel,
  ): Promise<void> {
    const mailerSectionBlockOverrides = await mailerTemplateVersion.mailerSectionBlockOverrides;
    const hasOverride = mailerSectionBlockOverrides
      .slice()
      .some((override) => override.belongsTo('mailerSectionBlock').id() === mailerSectionBlock.id);
    if (!hasOverride) {
      this.createOverride(mailerSectionBlock, mailerTemplateVersion);
    }
  }

  createOverride(mailerSectionBlock: MailerSectionBlockModel, mailerTemplateVersion: MailerTemplateVersionModel): void {
    this.store.createRecord('mailer-section-block-override', {
      mailerTemplateVersion,
      mailerSectionBlock,
    });
  }

  async model({
    template_version_id,
  }: {
    template_version_id: string;
  }): Promise<SettingsEmailsTemplatesVersionsRouteModel> {
    const { currentCompany } = this.state;
    const { mailerTemplate, mailerTemplateVersionsByCompany, mailerTemplateVersionsByLocation } = <
      SettingsEmailsTemplatesRouteModel
    >this.modelFor('visitors.settings.emails.templates');

    let mailerTemplateVersion = await this.store.findRecord('mailer-template-version', template_version_id);

    // Ensure that we have a fully loaded MailerTemplateVersion model
    const templateRelationship = mailerTemplateVersion.belongsTo('mailerTemplate');
    const overridesRelationship = mailerTemplateVersion.hasMany('mailerSectionBlockOverrides');
    if (isNone(templateRelationship.value()) || isNone(overridesRelationship.value())) {
      mailerTemplateVersion = await this.store.findRecord('mailer-template-version', template_version_id, {
        reload: true,
      });
    }

    let uiHooks;
    if (this.featureFlags.isEnabled('hooks-custom-content-in-email-template')) {
      uiHooks = await this.store.query('ui-hook', {
        location: this.state.currentLocation.id,
        triggerName: TRIGGER_NAME_MAPPING[<keyof typeof TRIGGER_NAME_MAPPING>mailerTemplate.identifier],
      });
    }

    const mailerSectionBlockOverrides = await mailerTemplateVersion.mailerSectionBlockOverrides;
    const mailerTemplateSections = await mailerTemplate.mailerTemplateSections;

    return hash({
      mailerTemplate,
      currentCompany,
      mailerTemplateVersion,
      mailerSectionBlockOverrides,
      mailerTemplateVersionsByCompany,
      mailerTemplateVersionsByLocation,
      mailerTemplateSections,
      uiHooks,
    });
  }

  async afterModel(model: SettingsEmailsTemplatesVersionsRouteModel): Promise<void> {
    const { mailerTemplateVersion, mailerTemplateVersionsByLocation, mailerTemplateSections } = model;
    if (mailerTemplateVersionsByLocation.includes(mailerTemplateVersion)) {
      for (const mailerTemplateSection of mailerTemplateSections!.slice()) {
        const mailerSection = await mailerTemplateSection.mailerSection;
        await this.generateOverridesForSection(mailerSection, mailerTemplateVersion);
      }
    }
  }

  redirect(model: SettingsEmailsTemplatesVersionsRouteModel): void {
    const { mailerTemplate, mailerTemplateVersion, mailerTemplateVersionsByCompany, mailerTemplateVersionsByLocation } =
      model;

    const isVersionInVersions =
      mailerTemplateVersionsByCompany.includes(mailerTemplateVersion) ||
      mailerTemplateVersionsByLocation.includes(mailerTemplateVersion);

    if (!isVersionInVersions) {
      void this.router.transitionTo('visitors.settings.emails.templates', mailerTemplate.id);
    }
  }

  setupController(
    controller: SettingsEmailsTemplatesVersionsController,
    model: SettingsEmailsTemplatesVersionsRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.transitionConfirmed = false;
    controller.loadEmailPreviewHTML();
  }

  resetController(controller: SettingsEmailsTemplatesVersionsController): void {
    void (<Task<void, []>>(<unknown>controller.deleteConfirmationTask)).cancelAll();
    controller.rollbackAll();
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controller.confirmTransition(transition);
  }
}
