import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type WindowLocationService from 'garaje/services/window-location';
import type { SinglePayload } from 'jsonapi/response';

const STEPS = {
  emailSearch: 'login/login-wizard/email-search',
  emailFound: 'login/login-wizard/email-found',
};

interface LoginWizardComponentArgs {
  companyId: number;
  prefilledEmail: string;
  rd: string;
  redirectToEnvoy: (path: string) => void;
  state: string;
}

export default class LoginWizardComponent extends Component<LoginWizardComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare localStorage: LocalStorageService;
  @service declare windowLocation: WindowLocationService;

  @tracked currentStep = STEPS.emailSearch;
  @tracked emailFound = false;
  @tracked password = '';
  @tracked email = '';
  @tracked _samlUrl?: string | null;

  get samlUrl(): string | null | undefined {
    return this._samlUrl;
  }

  set samlUrl(url: string | undefined) {
    try {
      const samlUrl = new URL(url!);
      // pass through the state parameter if it's present
      if (this.args.state) {
        // eslint-disable-next-line ember/use-ember-get-and-set
        samlUrl.searchParams.set('state', this.args.state);
      }
      this._samlUrl = samlUrl.toString();
    } catch (_e) {
      // unset samlUrl if it's not a valid URL
      this._samlUrl = null;
    }
  }

  @action
  moveTo(component: keyof typeof STEPS, email: string): void {
    this.email = email;
    this.currentStep = STEPS[component];
  }

  @action
  handleSearchResult(
    email: string,
    found: boolean,
    payload: SinglePayload<{ usesCredentials: boolean; samlTo: string; confirmed: boolean }>,
  ): void {
    if (!found) {
      this.flashMessages.showAndHideFlash('error', 'Login failed');
      return;
    }

    const attributes = payload.data.attributes || {};
    const canUseCredentials = Boolean(attributes['uses-credentials']);
    const canUseSAML = Boolean(attributes['saml-to']);
    const isConfirmed = Boolean(attributes.confirmed);

    this.email = email;
    this.samlUrl = attributes['saml-to'];

    this._handleSearchResult({ canUseCredentials, canUseSAML, isConfirmed });
  }

  @action
  showSessionTimeoutFlashMsg(): void {
    const sessionTimeout = this.localStorage.getItem('sessionTimeout');
    this.localStorage.setItem('sessionTimeout', 'false');
    if (sessionTimeout === 'true') {
      this.flashMessages.showFlash(
        'warning',
        'Session timeout',
        'You have been logged out due to the inactivity limit set by your admin.',
      );
    }
  }

  _handleSearchResult({
    canUseCredentials,
    canUseSAML,
    isConfirmed,
  }: {
    canUseCredentials: boolean;
    canUseSAML: boolean;
    isConfirmed: boolean;
  }): void {
    switch (true) {
      case isConfirmed && canUseCredentials && canUseSAML:
        this._displayPasswordForm();
        break;
      case isConfirmed && !canUseCredentials && canUseSAML:
        this._redirectToSAML();
        break;
      case isConfirmed && canUseCredentials && !canUseSAML:
        this._displayPasswordForm();
        break;
      case isConfirmed && !canUseCredentials && !canUseSAML:
        this._displayConfigIncompleteError();
        break;
      case !isConfirmed && canUseCredentials && canUseSAML:
        this._displayPasswordForm();
        break;
      case !isConfirmed && !canUseCredentials && canUseSAML:
        this._redirectToSAML();
        break;
      case !isConfirmed && canUseCredentials && !canUseSAML:
        this._displayResendConfirmationEmail();
        break;
      case !isConfirmed && !canUseCredentials && !canUseSAML:
        this._displayConfigIncompleteError();
        break;
      default:
        this._displayConfigIncompleteError();
    }
  }

  @action
  changeEmail(): void {
    this.emailFound = false;
    this.password = '';
    this.currentStep = STEPS.emailSearch;
  }

  @action
  logInViaSAML(): void {
    this._redirectToSAML();
  }

  @action
  _displayConfigIncompleteError(): void {
    // User can't use credentials and the SSO url is not configured. Show error
    this.flashMessages.showFlash('error', 'SAML configuration incomplete. Contact your admin.');
  }

  _displayPasswordForm(): void {
    this.password = '';
    this.emailFound = true;
  }

  _displayResendConfirmationEmail(): void {
    this.currentStep = STEPS.emailFound;
  }

  _redirectToSAML(): void {
    this.localStorage.setItem('default_route', '');
    this.windowLocation.assign(this.samlUrl!);
  }
}
