import Controller from '@ember/controller';
import { service } from '@ember/service';
import type Link from 'ember-link/link';
import type LinkManagerService from 'ember-link/services/link-manager';

import type { SettingsEmailsRouteModel } from './route';

interface Tab {
  label: string;
  link: Link;
}

export default class SettingsEmailsController extends Controller {
  declare model: SettingsEmailsRouteModel;

  @service declare linkManager: LinkManagerService;

  get tabs(): Tab[] {
    return this.model.mailerTemplates.toArray().map(({ id, title }) => {
      return {
        label: `${title} email`,
        link: this.linkManager.createUILink({
          route: 'visitors.settings.emails.templates',
          models: [id],
          query: { action: null },
        }),
      };
    });
  }
}
