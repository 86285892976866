import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { SettingsEmailsRouteModel } from '../route';

export default class SettingsEmailsIndexRoute extends Route {
  @service declare router: RouterService;

  redirect({ mailerTemplates }: SettingsEmailsRouteModel): void {
    const mailerTemplate = mailerTemplates.toArray()[0];
    if (!mailerTemplate) return;

    void this.router.replaceWith('visitors.settings.emails.templates', mailerTemplate.id);
  }
}
