import { get, setProperties } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import type { MailerBlockSettingAttributeValues } from 'garaje/models/mailer-block';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

export default class MailerSectionBlockAttributeObjectProxy extends ObjectProxy<MailerBlockSettingAttributeValues> {
  defaultContent?: MailerBlockSettingAttributeValues;

  get isDefault(): boolean {
    const { defaultValue } = this;

    if (typeof defaultValue === 'undefined') return true;

    return _isEqual(get(this, 'value'), defaultValue);
  }

  get defaultValue(): string | undefined {
    return this.defaultContent?.value;
  }

  reset(): void {
    // @ts-ignore
    setProperties(this, _cloneDeep(this.defaultContent));
  }
}
