import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { SettingsEmailsTemplatesRouteModel } from '../route';

export default class SettingsEmailsTemplatesIndexRoute extends Route {
  @service declare router: RouterService;

  redirect(model: SettingsEmailsTemplatesRouteModel): void {
    const { mailerTemplate, mailerTemplateVersionsByCompany, mailerTemplateVersionsByLocation } = model;

    const mailerTemplateVersion =
      mailerTemplateVersionsByCompany.firstObject || mailerTemplateVersionsByLocation.firstObject;

    void this.router.replaceWith(
      'visitors.settings.emails.templates.versions',
      mailerTemplate.id,
      mailerTemplateVersion!.id,
    );
  }
}
