import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { timeout, restartableTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type PrinterModel from 'garaje/models/printer';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import zft from 'garaje/utils/zero-for-tests';
import { hash } from 'rsvp';

const POLL_TIMEOUT = 10000;

export interface VisitorsDevicesPrintersIndexRouteModel {
  printers: RecordArray<PrinterModel>;
  vrSubscription: SubscriptionModel | LocationSubscriptionModel | null;
}

export default class VisitorsDevicesPrintersIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (this.abilities.cannot('view-nav-item printers')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): Promise<VisitorsDevicesPrintersIndexRouteModel> {
    const locationId = this.state.currentLocation.id;
    const vrSubscription = this.state.vrSubscription;
    const printers = this.store.query('printer', { filter: { location: locationId }, include: 'devices,location' });
    return hash({ printers, vrSubscription });
  }

  afterModel(model: VisitorsDevicesPrintersIndexRouteModel): void {
    void this.pollPrinterTask.perform(model);

    if (this.abilities.cannot('create printers') || !model.vrSubscription?.canEnableBadgePrinting) {
      // if user cannot create printers, OR badge printing cannot be enabled on the location,
      // do not redirect the user to "new printer" page if there are 0 printers`
      return;
    } else if (model.printers.length === 0) {
      // redirect to new page if there are 0 printers and user can add new ones
      void this.router.transitionTo('visitors.devices.printers.new');
    }
  }

  pollPrinterTask = restartableTask(
    { cancelOn: 'deactivate' },
    async (model: VisitorsDevicesPrintersIndexRouteModel) => {
      // we stop polling printer status for testing after 2 requests
      let maxPollingCountForTesting = 2;
      do {
        await timeout(config.testLongPolling ? 10 : zft(POLL_TIMEOUT));

        const printers = await this.store.query('printer', {
          filter: {
            location: this.state.currentLocation.id,
          },
          include: 'devices,location',
        });

        model.printers = printers;
        maxPollingCountForTesting--;
      } while (config.environment !== 'test' || (config.testLongPolling && maxPollingCountForTesting));
    },
  );
}
