import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { all, task, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import normalizeResponse from 'garaje/utils/normalize-response';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import {
  getUnixTime,
  fromUnixTime,
  startOfDay,
  endOfDay,
  formatISO,
  format,
  subMinutes,
  isSameDay,
  isAfter,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getPartialTimes } from 'garaje/utils/hour-options';
import { isPresent } from '@ember/utils';

/**
 * @param {Array}       employees
 * @param {Task}        searchTask
 * @param {Task}        loadMoreTask
 * @param {Task}        reservationModalTask
 * @param {Boolean}     hasMorePages
 * @param {Boolean}     isEditing
 * @param {Reservation} reservation
 */

export default class ReservationModal extends Component {
  @service state;
  @service store;
  @service flashMessages;
  @service ajax;
  @service metrics;
  @service workplaceMetrics;
  @service featureFlags;

  @tracked currentPage = 'employee';
  @tracked selectedEmployee;
  @tracked selectedDates = this.initialSelectedDates;
  @tracked selectedDesks = [];
  @tracked successfulReservations = [];
  @tracked reservationForSelectedEmployee;
  @tracked multidayEnabled = false;
  @tracked allDayEnabled = this.args.reservation?.isPartialDay ? false : true;
  @tracked selectedTime = this.initialSelectedTime;
  @tracked autoAssignDesksEnabled = false;

  @task
  *loadInvitesTask(date) {
    try {
      const endTime = this.getLocationEndTime(date);
      const filter = {
        locationId: get(this.state.currentLocation, 'id'),
        dateTo: encodeURIComponent(formatISO(endTime)),
        dateFrom: encodeURIComponent(formatISO(date)),
        email: encodeURIComponent(this.selectedEmployee.email),
      };
      const url = urlBuilder.v3.invites.fetchEmployeeInviteOnDate(filter);
      const response = yield this.ajax.request(url, {
        type: 'GET',
        contentType: 'application/vnd.api+json',
        headers: { accept: 'application/vnd.api+json' },
      });

      const activeInvite = response.data.find((invite) => !invite.attributes['entry-signed-out-at']);
      if (activeInvite) {
        let invite = this.store.findRecord('invite', activeInvite.id);
        if (!invite) {
          const normalizedInvite = normalizeResponse(this.store, 'invite', { data: activeInvite });
          invite = this.store.createRecord('invite', normalizedInvite.data);
          setProperties(invite, {
            ...normalizedInvite.data.attributes,
          });
        }
        return invite;
      } else {
        return null;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error:', e);
    }
  }

  @task
  *createInviteTask(date) {
    const startTime = date;
    const endTime = this.getLocationEndTime(date);
    const invite = yield this.state.currentLocation.createEmployeeInvite(
      startTime,
      this.selectedEmployee.fullName,
      this.selectedEmployee.email,
    );
    set(invite, 'endTime', endTime);
    const inviteUserDatum = invite.userData[0];
    set(inviteUserDatum, 'value', 'Employee registration');
    setProperties(invite, {
      employeeScreeningFlow: true,
      flowName: 'Employee registration',
    });
    yield invite.save();
    return invite;
  }

  @task
  *loadOrCreateInviteTask(date) {
    let invite = yield this.loadInvitesTask.perform(date);
    if (!invite) {
      invite = yield this.createInviteTask.perform(date);
    }
    return invite;
  }

  getReservationObj(user, location, invite, desk, startTime, endTime) {
    const resObj = {
      data: {
        type: 'reservation',
        attributes: {
          'start-time': startTime,
          'end-time': endTime,
        },
        meta: {
          'auto-assign-desk': this.autoAssignDesksEnabled,
        },
        relationships: {
          user: {
            data: {
              type: 'users',
              id: user.id,
            },
          },
          location: {
            data: {
              type: 'locations',
              id: location.id,
            },
          },
          invite: {
            data: {
              type: 'invites',
              id: invite.id,
            },
          },
        },
      },
    };
    if (desk) {
      resObj.data.relationships.desk = {
        data: {
          type: 'desks',
          id: desk.id,
        },
      };
    }
    return resObj;
  }

  getLocationEndTime(date) {
    return this.state.getOfficeLocationTime(endOfDay(utcToZonedTime(date, this.state.currentLocation.timezone)));
  }

  createReservationTask = task({ drop: true }, async () => {
    const { isEditing, reservation, entry, isDbeam } = this.args;
    if (isEditing && !reservation && !entry) {
      this.workplaceMetrics.logMonitorError({
        event: 'RESERVATION_MODAL_EDITING_NONEXISTENT_RESERVATION',
      });
      this.flashMessages.showAndHideFlash('Error editing reservation', "The reservation doesn't exist!");
      return;
    }

    // For DBEAM, we first destroy the previous invite / entry prior to editing anything
    if (isEditing && entry) {
      await entry.destroyRecord();
    }

    try {
      if (isEditing && reservation) {
        await this._editReservation(reservation);
      } else {
        await this._createReservation();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error', e);
      this.workplaceMetrics.logMonitorError({
        event: 'RESERVATION_MODAL_FAILED_TO_CREATE_RESERVATION',
        debugExtras: {
          isDbeam,
          entryId: entry?.id,
          reservationId: reservation?.id,
          action: isEditing ? 'edit' : 'create',
        },
        error: e,
      });
      this.flashMessages.showAndHideFlash('Error creating reservation', parseErrorForDisplay(e));
      return;
    }

    this.setCurrentPage('approval');
  });

  _createReservation = async () => {
    const { isDbeam } = this.args;
    const invites = await all(this.selectedDates.map((date) => this.loadOrCreateInviteTask.perform(date)));

    const shouldSkipReservationCreation = isPresent(isDbeam) && isDbeam && !this.autoAssignDesksEnabled;
    if (shouldSkipReservationCreation) {
      return;
    }

    const reservationObjects = [];
    const user = await this.selectedEmployee.user;
    this.selectedDates.forEach((date, idx) => {
      const invite = invites[idx];
      const [partialStart, partialEnd] = getPartialTimes(date, this.selectedTime);
      const startTime = this.allDayEnabled ? date : partialStart;
      const endTime = this.allDayEnabled ? this.getLocationEndTime(date) : partialEnd;

      const createdReservation = this.getReservationObj(
        user,
        this.state.currentLocation,
        invite,
        this.selectedDesks[idx],
        getUnixTime(startTime),
        getUnixTime(endTime),
      );
      reservationObjects.push(createdReservation);
    });

    const url = urlBuilder.rms.bulkCreateReservations();
    const reservations = await this.ajax.request(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: reservationObjects,
    });

    reservations.data.forEach((res) => {
      const desk = this.store.peekRecord('desk', res.relationships.desk.data.id);
      this.successfulReservations.push({
        startTime: fromUnixTime(res.attributes['start-time']),
        endTime: fromUnixTime(res.attributes['end-time']),
        desk,
      });
      this.metrics.trackEvent('DelegatedBooking_Used', {
        action: 'create',
        reservationId: res.id,
        partialDay: !this.allDayEnabled,
        multiday: this.multidayEnabled,
      });
    });

    if (reservations.errors) {
      this.workplaceMetrics.logMonitorError({
        event: 'RESERVATION_MODAL_FAILED_TO_CREATE_RESERVATIONS_MULTIDAY',
        debugExtras: {
          isDbeam,
          action: 'create',
        },
        error: reservations.errors,
      });

      this.flashMessages.showAndHideFlash(
        'error',
        'Reservation not successful',
        'One or more of your reservations failed',
      );
    }
  };

  _editReservation = async (reservation) => {
    const locationTime = this.state.getOfficeLocationTime(
      startOfDay(subMinutes(this.selectedDates[0], this.state.minutesBetweenTimezones)),
    );
    const invite = await this.loadOrCreateInviteTask.perform(locationTime);

    const [partialStart, partialEnd] = getPartialTimes(locationTime, this.selectedTime);
    const startTime = this.allDayEnabled ? locationTime : partialStart;
    const endTime = this.allDayEnabled ? this.getLocationEndTime(locationTime) : partialEnd;
    const newDesk = this.selectedDesks.length ? this.selectedDesks[0] : get(reservation, 'desk');
    const response = await reservation.editReservation(invite, newDesk, getUnixTime(startTime), getUnixTime(endTime));
    const newReservation = response.data;

    this.successfulReservations = [
      {
        startTime: fromUnixTime(newReservation.attributes['start-time']),
        endTime: fromUnixTime(newReservation.attributes['end-time']),
        desk: newDesk,
      },
    ];

    this.metrics.trackEvent('DelegatedBooking_Used', {
      action: 'edit',
      prevReservationId: reservation.id,
      reservationId: newReservation.id,
      partialDay: !this.allDayEnabled,
      multiday: this.multidayEnabled,
    });
  };

  @dropTask
  *deleteReservationTask() {
    const { reservation, reservationModalTask, entry, isDbeam } = this.args;
    try {
      if (entry) {
        if (!entry.signedInAt) {
          yield entry.destroyRecord();
        } else {
          set(entry, 'signOutTime', new Date());
          yield entry.save();
        }
      }

      const url = urlBuilder.rms.releaseDesk();
      yield this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
        data: {
          data: {
            attributes: {
              'reservation-id': reservation.id,
            },
          },
        },
      });
      this.store.unloadRecord(reservation);

      this.metrics.trackEvent('DelegatedBooking_Used', {
        action: 'delete',
        reservationId: reservation.id,
        partialDay: !this.allDayEnabled,
      });
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'RESERVATION_MODAL_FAILED_TO_DELETE_RESERVATION',
        debugExtras: {
          isDbeam,
          action: 'delete',
          entryId: entry?.id,
          reservationId: reservation?.id,
        },
        error: e,
      });

      throw e;
    }

    reservationModalTask.last.continue();
  }

  @dropTask
  *getSelectedEmployeeTask() {
    const { isEditing, reservation, entry } = this.args;
    if (!isEditing) {
      this.selectedDates = [this.state.getOfficeLocationTime(startOfDay(new Date()))];
      return;
    }

    const startTime = reservation ? fromUnixTime(reservation?.startTime) : (entry?.expectedArrivalTime ?? new Date());
    this.selectedDates = [this.state.getOfficeLocationTime(subMinutes(startTime, this.state.minutesBetweenTimezones))];

    let params;
    if (reservation) {
      const user = yield reservation.user;
      params = { filter: { email: user.email } };
    } else {
      params = { filter: { email: entry.email } };
    }

    const response = yield this.store.query('employee', params);
    this.selectedEmployee = get(response, 'firstObject');
    yield this.selectedEmployee.employeeLocations;
  }

  get initialSelectedDates() {
    const { reservation, entry } = this.args;
    const startDate = reservation ? fromUnixTime(reservation?.startTime) : (entry?.expectedArrivalTime ?? new Date());
    return [this.state.getOfficeLocationTime(startOfDay(subMinutes(startDate, this.state.minutesBetweenTimezones)))];
  }

  get initialSelectedTime() {
    const { isEditing, reservation } = this.args;
    if (!isEditing || !reservation) {
      return { startTime: '00:00:00', endTime: '23:59:59' };
    }

    const start = fromUnixTime(reservation.startTime);
    const end = fromUnixTime(reservation.endTime);
    return {
      startTime: format(subMinutes(start, this.state.minutesBetweenTimezones), 'HH:mm:ss'),
      endTime: format(subMinutes(end, this.state.minutesBetweenTimezones), 'HH:mm:ss'),
    };
  }

  get modalTitle() {
    const { isEditing } = this.args;
    switch (this.currentPage) {
      case 'employee':
        return isEditing ? 'Edit reservation' : 'New reservation';
      case 'map':
        return 'Choose a desk';
      case 'delete':
        return 'Delete reservation?';
      case 'unsaved':
        return 'You have unsaved changes';
      default:
        return '';
    }
  }

  get hasUnsavedChanges() {
    // Checks if the date changed or a desk has been selected
    const { isEditing, reservation } = this.args;
    if (isEditing && reservation) {
      return (
        !isSameDay(startOfDay(fromUnixTime(reservation?.startTime)), startOfDay(this.selectedDates[0])) ||
        this.selectedDesks[0]
      );
    } else {
      return (
        this.selectedDates.length !== 1 ||
        !isSameDay(startOfDay(this.selectedDates[0]), this.state.getOfficeLocationTime(startOfDay(new Date()))) ||
        this.selectedDesks[0]
      );
    }
  }

  @action
  onDateSelect(locationDate) {
    if (this.multidayEnabled) {
      if (this.selectedDates.find((date) => isSameDay(date, new Date(locationDate)))) {
        this.selectedDates = this.selectedDates.filter((date) => !isSameDay(date, new Date(locationDate)));
      } else {
        this.selectedDates = [...this.selectedDates, new Date(locationDate)].sort((a, b) => {
          return isAfter(a, b) ? 1 : -1;
        });
      }
    } else {
      this.selectedDates = [new Date(locationDate)];
    }
  }

  @action
  onEmployeeSelect(employee) {
    this.selectedEmployee = employee;
  }

  @action
  setCurrentPage(page) {
    this.currentPage = page;
  }

  @action
  setSelectedDesk(desk, idx) {
    this.selectedDesks[idx] = desk;
    this.selectedDesks = [...this.selectedDesks];
  }

  @action
  onMultidayToggleClick() {
    this.multidayEnabled = !this.multidayEnabled;
    this.selectedDates = this.selectedDates.length ? [this.selectedDates[0]] : [];
  }

  @action
  onAllDayToggleClick() {
    this.allDayEnabled = !this.allDayEnabled;
  }

  @action
  onPartialTimeChange(attr, value) {
    this.selectedTime = {
      ...this.selectedTime,
      [attr]: value,
    };
  }

  resetComponent() {
    const { isEditing, reservation, entry } = this.args;
    const originalTime =
      isEditing && reservation ? fromUnixTime(reservation?.startTime) : (entry?.expectedArrivalTime ?? new Date());
    this.selectedEmployee = null;
    this.selectedDates = [this.state.getOfficeLocationTime(startOfDay(originalTime))];
    this.selectedDesks = [];
    this.successfulReservations = [];
    this.currentPage = 'employee';
    this.multidayEnabled = false;
    this.allDayEnabled = reservation?.isPartialDay ? false : true;
    this.autoAssignDesksEnabled = false;
    this.selectedTime = this.initialSelectedTime;
  }

  @action
  exitModal() {
    const { reservationModalTask } = this.args;
    switch (this.currentPage) {
      case 'employee':
      case 'map':
        if (this.hasUnsavedChanges) {
          this.currentPage = 'unsaved';
        } else {
          this.resetComponent();
          reservationModalTask.last.abort();
        }
        break;
      case 'delete':
        this.currentPage = 'employee';
        break;
      case 'unsaved':
        this.resetComponent();
        reservationModalTask.last.abort();
        break;
      case 'approval':
        this.resetComponent();
        reservationModalTask.last.continue();
    }
  }
}
