import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { task, lastValue, all, restartableTask, timeout } from 'ember-concurrency';
import { TEST_IDENTIFIER, VACCINE_IDENTIFIER } from 'garaje/models/user-document-template';
import type UserDocumentTemplateConfigurationModel from 'garaje/models/user-document-template-configuration';
import type MetricsService from 'garaje/services/metrics';
import type TrialService from 'garaje/services/trial';
import type UserDocumentService from 'garaje/services/user-document';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import zft from 'garaje/utils/zero-for-tests';
import { and, or } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';

import type { LocationOverviewSignInFlowsShowLegalDocumentsIndexRouteModel } from './route';

@updateAndSaveTask
class LocationOverviewSignInFlowsShowLegalDocumentsIndexController extends Controller {
  declare model: LocationOverviewSignInFlowsShowLegalDocumentsIndexRouteModel;

  @service declare trial: TrialService;
  @service declare metrics: MetricsService;
  @service('user-document') declare userDocumentService: UserDocumentService;
  @service declare store: StoreService;

  @and('model.agreementPage.enabled', 'model.vrSubscription.includesNda') legalDocumentsEnabled!: boolean;

  @lastValue('setupTrackingConfigurations') trackingConfigurations?: {
    vaccineConfiguration?: UserDocumentTemplateConfigurationModel;
    testConfiguration?: UserDocumentTemplateConfigurationModel;
  };
  @localCopy('trackingConfigurations.vaccineConfiguration')
  vaccineConfiguration?: UserDocumentTemplateConfigurationModel;
  @localCopy('trackingConfigurations.testConfiguration') testConfiguration?: UserDocumentTemplateConfigurationModel;
  @or('testConfiguration.active', 'vaccineConfiguration.active') trackingEnabled!: boolean;

  @action
  trackFeatureToggled(isEnabled: boolean): void {
    let eventName = 'Legal Docs - Legal Documents ';
    eventName += isEnabled ? 'Enabled' : 'Disabled';
    this.metrics.trackEvent(eventName, {
      company_id: this.model.tracking.currentCompanyId,
    });
  }

  setupTrackingConfigurations = task(async () => {
    if (!this.userDocumentService.loadTemplates.lastSuccessful?.value) {
      await this.userDocumentService.loadTemplates.perform();
    }

    const [vaccineConfiguration, testConfiguration] = await all([
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(this.model.flow, VACCINE_IDENTIFIER),
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(this.model.flow, TEST_IDENTIFIER),
    ]);

    return { vaccineConfiguration, testConfiguration };
  });

  searchUsersTask = restartableTask(async (term: string) => {
    await timeout(zft(300));
    const locations = this.store.peekAll('location') ?? [];
    const re = new RegExp(`.*${term}.*`, 'i');
    const currentContactsIds = this.model.flow.visitorDocumentContacts.mapBy('id');
    const userRoles = await this.store.query('user-role', {
      filter: {
        query: term,
        location: locations.mapBy('id').join(','),
        roles: ['Global Admin', 'Location Admin', 'Receptionist'].join(','),
      },
    });
    const users = await all(userRoles.map((role) => role.user));

    return A(users)
      .uniqBy('id')
      .reject(({ id }) => currentContactsIds.includes(id))
      .filter(({ fullName }) => fullName.match(re));
  });

  @action
  _trackAddLegalDocStarted(): void {
    this.send('trackAddLegalDocStarted');
  }

  @action
  _trackEditLegalDocStarted(): void {
    this.send('trackEditLegalDocStarted');
  }
}

export default LocationOverviewSignInFlowsShowLegalDocumentsIndexController;
