import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { task, timeout, dropTask, restartableTask, all } from 'ember-concurrency';
import { isEmpty, isBlank, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import _intersection from 'lodash/intersection';
import normalizeResponse from 'garaje/utils/normalize-response';
import addOrUpdateUserData from 'garaje/utils/user-data';
import { dependentKeyCompat } from '@ember/object/compat';
import { NON_ASSIGNABLE_FLOWS, PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import { reads, notEmpty } from 'macro-decorators';
import { defer } from 'rsvp';
import { cached } from 'tracked-toolbox';

import ObjectProxy from '@ember/object/proxy';
import Changeset from 'ember-changeset';
import moment from 'moment-timezone';

import MappedField from 'garaje/utils/mapped-field';
import urlBuilder from 'garaje/utils/url-builder';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { Permission } from 'garaje/utils/ui-permissions';

import config from 'garaje/config/environment';
import { buildWaiter } from '@ember/test-waiters';
import zft from 'garaje/utils/zero-for-tests';

const EVENT_REPORT_POLLING_TIMEOUT = zft(10000);
const NUMBER_OF_POLLING_TRIES = zft(3);
const testWaiter = buildWaiter('new-visitor-form-component-waiter');
const isTesting = config.environment === 'test';

export default class VisitorsEntryController extends Controller {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorageExtension;
  @service ajax;
  @service authz;
  @service abilities;
  @service currentAdmin;
  @service state;
  @service flashMessages;
  @service metrics;
  @service store;

  checkStatuses = ['Checked', 'Not checked'];
  @tracked previousEntries = [];
  @tracked displayPrinterSelectorModal = false;
  @tracked morePreviousEntriesUrl = null;
  @tracked eventReports;
  @tracked userDataChangeset;
  @tracked staticFields;
  @tracked confirmRemovalOfDocument = null;
  @tracked hasAttachments = false;

  @action
  updateUserDataField() {
    /*
     * NOOP -- the way we interact with userData is slightly different
     * here to how we do it invites. Since the code was copy pasted we
     * need to keep this until we refactor the component entry-fields.
     *
     * Intentional TODO: Refactor this.
     */
  }

  @reads('model.approvalStatus') approvalStatus;
  @reads('state.currentLocation') currentLocation;
  @reads('hasChanges') canSave;
  @reads('flowField.value') flowName;
  @notEmpty('model.approvalStatus.preRegistrationRequiredReport') hasPreRegistrationRequiredReports;
  @notEmpty('model.invite.id') hasInvite;
  @reads('flow.activeUserDocumentTemplateConfigurations') activeUserDocumentTemplateConfigurations;

  @(employeesSearcherTask({
    filter: {
      deleted: false,
    },
    prefix: true,
  }).restartable())
  searchEmployeesTask;

  @dependentKeyCompat
  get hasDirtyUserData() {
    return this.model.userData.isAny('isDirty') || this.isStaticDirty;
  }

  get cannotChangeFlows() {
    return this.abilities.cannot('edit entry') || this.isANonAssignableFlow || this.isMultiLocation;
  }

  get isMultiLocation() {
    return this.locationNames.length > 1;
  }

  get locationNames() {
    return this.model.locationNames || [];
  }

  get primaryLocation() {
    return this.locationNames[0];
  }

  get secondaryLocations() {
    const [_, ...childLocationNames] = this.locationNames;
    return childLocationNames;
  }

  get isStaticDirty() {
    return this.staticFields.any((field) => get(field.changeset, 'isDirty'));
  }

  get endOfSignedOutMonth() {
    return moment(get(this.model, 'signedOutAt')).add(1, 'M').endOf('month');
  }

  get canPrintBadges() {
    const hasPrintPermission = this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_READ);

    return hasPrintPermission && get(this.vrSubscription, 'canEnableBadgePrinting');
  }

  get canSetPropertyNotes() {
    return this.locationIsConnectedToProperty;
  }

  get isANonAssignableFlow() {
    return this.model.isFromEmployeeScreening || this.model.isFromWalkUp;
  }

  get legalDocumentDescriptions() {
    return this.agreements.reduce((obj, agreement) => {
      const agreeableNda = this.model.agreeableNdas.findBy('agreement.id', agreement.id);
      /**
       * There may be agreeableNdas that have signed agreements (i.e. an "NDA" PDF) but that don't have a
       * relationship to an agreement (i.e., a legal document template) and therefore have no agreement name.
       * These signed agreements are from before visitor type flows existed. We assign them a generic
       * "Non-disclosure Agreement" name because NDAs used to be the only kind of agreement we supported.
       */
      const agreementName = typeof agreement.name === 'undefined' ? 'Non-disclosure Agreement' : agreement.name;

      if (agreeableNda.signedAt) {
        const entrySignedInAt = moment(this.model.signedInAt);
        const agreementSignedAt = moment(agreeableNda.signedAt);
        const wasPreviouslySigned = entrySignedInAt.diff(agreementSignedAt, 'days') !== 0;
        const description = wasPreviouslySigned ? `signed ${agreementSignedAt.format('MMM D, YYYY')}` : 'signed';

        obj[agreementName] = description;
      } else if (agreeableNda.agreementOptional) {
        obj[agreementName] = 'optional';
      } else {
        obj[agreementName] = 'not signed';
      }

      return obj;
    }, {});
  }

  get locationIsConnectedToProperty() {
    return this.connectedTenants.length > 0;
  }

  get isAdmin() {
    const roleNames = get(this.currentAdmin, 'roleNames');
    return isPresent(_intersection(['Global Admin', 'Location Admin', 'Front Desk Admin'], roleNames));
  }

  get thumbnailPath() {
    const largeThumbnail = get(this.model, 'thumbnails.large');
    return largeThumbnail ? largeThumbnail : '/assets/images/features/avatar-placeholder.png';
  }

  get showSignOutSection() {
    const entry = this.model;

    // don't show "Signed Out" section if approval was denied and visitor never entered
    if (entry.approvalWasDenied) return false;

    // show "Signed Out" section is the entry is signed out, or the current user can edit the entry
    return !!entry.signedOutAt || this.abilities.can('edit entry', entry);
  }

  _buildStaticFields() {
    const staticFields = [];

    const fullNameObject = new MappedField({
      fieldData: this.model.userData.findBy('field', 'Your Full Name'),
      isRequired: true,
    });

    if (!isEmpty(get(this.currentLocation, 'config.localizedFields')) && fullNameObject.fieldData) {
      staticFields.pushObject(fullNameObject);
    }

    const emailAddress = this.model.userData.findBy('field', 'Your Email Address');
    const phoneNumber = this.model.userData.findBy('field', 'Your Phone Number');

    if (emailAddress) {
      const decoratedEmail = new MappedField({ fieldData: emailAddress, isRequired: false });
      staticFields.pushObject(decoratedEmail);
    }

    if (phoneNumber) {
      const decoratedPhone = new MappedField({ fieldData: phoneNumber, isRequired: false });

      staticFields.pushObject(decoratedPhone);
    }

    get(this.model, 'userData').forEach((datum) => {
      if (!staticFields.findBy('field', get(datum, 'field')) && 'Purpose of visit' !== get(datum, 'field')) {
        staticFields.pushObject(new MappedField({ fieldData: datum, isRequired: false }));
      }
    });

    this.staticFields = staticFields;
  }

  get sourcesForApprovalReviewMetrics() {
    return get(this.approvalStatus, 'failedReport').reduce(
      (sources, report) => ({ ...sources, [get(report, 'source')]: true }),
      {},
    );
  }

  /* MVT related
   * Keeping all the code related with MVT below
   */
  /*
   * flowField attempts to lookup an Entry's flow, first
   * by POV, then by the key given in config.localizedFields,
   * and finally by provided a POJO with the value of flowName
   *
   * TODO: What is the logic behind this? There's totally an
   * undefined case that's not covered here.
   */
  get flowField() {
    const { model, currentLocation } = this;
    const { flowName, userData } = model;
    let { povKey: povField } = model;

    // This is only relevant on legacy entries.
    // After MVT all userData will be stored in English
    if (!povField) {
      povField = get(currentLocation.config, 'localizedFields').findBy('field', 'Purpose of visit')?.localized;
    }

    let flowField = userData.findBy('field', povField);

    if (!flowField && flowName) {
      // Notice POJO instead of ED object
      flowField = {
        value: flowName,
      };
    }

    return flowField;
  }

  /*
   * This is the current flow found on the entry, even if it does
   * not exist in `currentLocation.flows`
   */
  get currentFlowField() {
    // Since flow and Purpose of Visit are completely different fields,
    // but the form element to change Purpose of visit relies on
    // currentLocation.flows, we have to make this composite structure
    // so that the entry's flow, as it is stored, is compatible with
    // the `currentLocation.flows`'s type

    let flowFieldJSON;
    if (this.flowField && !this.flowField.toJSON) {
      // This is when flowField returns as POJO
      flowFieldJSON = this.flowField;
    } else {
      // This is when flowField is an ED obj or UserDatum
      flowFieldJSON = this.flowField.toJSON();
    }
    let o;
    o = Object.assign({}, flowFieldJSON);
    o = Object.assign(o, { name: get(this.flowField, 'value') });
    return o;
  }

  /*
   * All possible flows include all flows in `currentLocation.flows`
   * as well as any flow found on the entry.
   */
  get possibleFlowsForEntries() {
    const possibleFlowsForEntries = A();
    if (this.model.isFromEmployeeScreening || this.purposeOfVisit?.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      possibleFlowsForEntries.push(this.model.flow);
      return possibleFlowsForEntries;
    }
    possibleFlowsForEntries.push(this.currentFlowField);
    get(this.currentLocation, 'flows')
      .filter(({ employeeCentric, type, name }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric && name)
      .forEach((f) => possibleFlowsForEntries.push(f));
    return possibleFlowsForEntries.uniqBy('name');
  }

  get hasFlowThatNoLongerExists() {
    const locationFlows = get(this.currentLocation, 'flows').filter(
      ({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric,
    );
    return get(this.possibleFlowsForEntries, 'length') > get(locationFlows, 'length');
  }

  get purposeOfVisit() {
    return this.model.userData.findBy('field', 'Purpose of visit');
  }

  /*
   * Flow is the full flow object, looked up from currentLocation,
   * that exists if it's found by name from what's listed on the entry
   */
  get flow() {
    // eslint-disable-next-line ember/use-ember-get-and-set
    const flowId = this.model.get('flow.id');
    const pov = this.purposeOfVisit?.value;

    if (this.model.isFromEmployeeScreening || pov === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.currentLocation.employeeScreeningFlow;
    }

    // fallback on name instead of id if using non model based flow
    return this.currentLocation.flows.findBy('id', flowId) ?? this.currentLocation.flows.findBy('name', pov);
  }

  get hasChanges() {
    const userDataChangesetModified = this.userDataChangesetModified;
    const hasDirtyAttributes = this.model.hasDirtyAttributes;
    const hasDirtyUserData = this.hasDirtyUserData;
    const hasDirtyVisitorDocuments = this.hasDirtyVisitorDocuments;

    return userDataChangesetModified || hasDirtyAttributes || hasDirtyUserData || hasDirtyVisitorDocuments;
  }

  @dependentKeyCompat
  get userDataChangesetModified() {
    return this.userDataChangeset.isAny('isDirty');
  }

  get signInFields() {
    if (!this.flow) {
      return [];
    }

    return get(this.flow, 'signInFieldPage.signInFields') || [];
  }

  _buildUserDataChangeset() {
    const { model, currentLocation } = this;
    let signInFields = [];
    const fields = [];

    if (this.flow) {
      signInFields = get(this.flow, 'signInFieldPage.signInFields');
    }

    // bootstrap editable sign-in-fields based in entry.userData
    model.userData.forEach((userDatum, index) => {
      const { field: fieldName, value } = userDatum;
      /*
       * We ignore flow field, it is not editable via the
       * {{entry-fields}} component. If we allow flow edit, then
       * it will happen through a different interface in the template.
       */
      // eslint-disable-next-line ember/use-ember-get-and-set
      if (value !== this.flow?.get('name') && isPresent(signInFields)) {
        let field = signInFields.findBy('name', fieldName);

        if (!field) {
          /* The following conditions brute force a match for host, email or phone.
           *
           * In an ideal world we should have a found a match already, but:
           *
           *  - field for host changes (since it's customizable)
           *  - email can be saved on a different locale
           *  - phone number was store with the bdField (API regression)
           */
          if (value === model.host) {
            field = signInFields.findBy('isHost');
          } else if (value === model.email) {
            field = signInFields.findBy('isEmail');
          } else if (fieldName === 'phoneNumber') {
            field = signInFields.findBy('isPhone');
          }
        }

        if (!field) {
          /*
           * If we get here, it means there used to be a
           * sign-in-field model for this field, but it was
           * removed.
           *
           * We create a "pseudo-field", so it displays as a
           * text-field in the template.
           *
           * Position 100 is to show the field at the bottom of
           * the list.
           */
          field = {
            id: `${index}-${currentLocation.id}`, // pseudo-id
            name: fieldName,
            isCustom: true,
            localized: fieldName,
            isLoaded: true,
            position: 100,
          };
        }

        field = new Changeset(ObjectProxy.create({ content: field, value }));
        fields.push(field);
      }
    });

    if (isPresent(signInFields)) {
      signInFields.forEach((field) => {
        // We want to prevent duplicate fields in the case where userData
        // also contains the same field
        const fieldIsAlreadyBootstrapped = !!fields.findBy('id', get(field, 'id'));
        const fieldIsGuestName = get(field, 'isGuestName');

        if (fieldIsGuestName || fieldIsAlreadyBootstrapped) {
          return;
        }

        fields.push(new Changeset(ObjectProxy.create({ content: field, value: '' })));
      });
    }

    this.userDataChangeset = fields;
  }

  @action
  doSearch(term) {
    return this.searchEmployeesTask.perform(term);
  }

  @action
  setHost(employee) {
    set(this.model, 'host', employee && get(employee, 'name'));
    this.model.employee = employee;
  }

  @task
  *approveEntryTask(entry) {
    try {
      yield entry.approveEntry();
      yield get(entry, 'flow.badge'); // load async relationship
      this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));
      yield entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'approve',
        entry_id: get(entry, 'id'),
        source: 'Entry Details',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving entry', parseErrorForDisplay(error));
    }
  }

  @task
  *denyEntryTask(entry) {
    try {
      yield entry.denyEntry();
      this.flashMessages.showAndHideFlash('warning', 'Entry denied');
      yield entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'deny',
        entry_id: get(entry, 'id'),
        source: 'Entry Details',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error denying entry');
    }
  }

  @task
  *reprintBadgeTask(entry, printer = null) {
    try {
      let response;
      // FF. multiplePrinters
      if (printer) {
        const data = [{ type: 'entries', id: entry.id }];
        response = yield printer.reprintBadge({ data });
      } else {
        // TODO Is this dead code?
        yield entry.reprintBadge();
      }
      this._trackReprintBadgeRequested(entry.id);
      if (response) {
        const errored = response.data.filter((r) => r.attributes.result === 'error');
        if (errored.length) {
          const msg = 'Cannot print badge';
          const componentName = 'flash-message/printer-error';
          this.flashMessages.showFlashComponent('error', msg, componentName, response);
          const statuses = errored[0].attributes.statuses;
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'error',
            message_title: msg,
            message_codes: statuses,
          });
        } else {
          const msg = 'Printing badge!';
          this.flashMessages.showAndHideFlash('success', msg);
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'success',
            message_title: msg,
            message_codes: [],
          });
        }
      } else {
        this.flashMessages.showAndHideFlash('success', 'Printing badge!');
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Cannot print badge');
    }
  }

  _trackReprintBadgeRequested(entryId) {
    const entry_id = parseInt(entryId, 10);
    const properties = {
      action_origin: 'entry_page',
      entry_id,
    };
    this.metrics.trackEvent('Reprint Entry Badge Requested', properties);
  }

  @dropTask
  showDeleteConfirmationTask = {
    entries: [],

    *perform(entries) {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = (args) => deferred.resolve(args);
      return yield deferred.promise;
    },
  };

  @task
  *saveTask() {
    const { model } = this;
    const { visitorDocuments } = model;
    const token = isTesting ? testWaiter.beginAsync() : false;

    this.updateAllUserData(model);

    yield model.save();

    try {
      yield all(
        (visitorDocuments ?? A()).map((visitorDocument) =>
          this.saveVisitorDocumentTask.perform(model, visitorDocument),
        ),
      );
    } catch (error) {
      this.flashMessages.showFlash('error', 'Visitor saved! But one or more document uploads failed');

      return;
    } finally {
      if (token) testWaiter.endAsync(token);
    }

    this.flashMessages.showAndHideFlash('success', 'Saved!');
  }

  @dropTask
  *loadEventReports() {
    const location = get(this.currentLocation, 'id');
    const identifier = `vr:entry:${get(this.model, 'id')}`;
    try {
      /**
       * We're querying for both event-reports and host-notification platform-jobs,
       * because eventually we will deprecate event-reports.
       * For now, we can de-dupe them by replacing any event-reports that have a corresponding
       * platform-job with the same name.
       */
      const platformJobsQueryResult = yield this.store.query('platform-job', { filter: { identifier } });
      const newPlatformJobs = platformJobsQueryResult.toArray().filterBy('pluginCategory', 'host-notifications');
      const platformJobNames = newPlatformJobs.mapBy('name');
      const newEventReportsQueryResult = yield this.store.query('event-report', { filter: { identifier, location } });
      const newEventReports = newEventReportsQueryResult.filter((report) => !platformJobNames.includes(report.name));
      newEventReports.pushObjects(newPlatformJobs);

      if (get(newEventReports, 'length')) {
        const loadedReports = this.eventReports;
        const newEventIds = newEventReports.mapBy('sourceId');

        // Since it's possible that we could have an old event report in "pending" status that has since
        // changed to a "terminal" status, we always take the latest version of any duplicate event reports
        const updatedEventReportList = loadedReports
          .filter((report) => !newEventIds.includes(get(report, 'sourceId')))
          .concat(newEventReports);

        this.eventReports = updatedEventReportList;
      }
      return newEventReports;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error loading notification statuses', parseErrorForDisplay(e));
      throw e;
    }
  }

  @restartableTask
  *pollEventReports() {
    if (!get(this.currentLocation, 'hostNotificationsEnabled')) {
      return;
    }

    const location = get(this.currentLocation, 'id');
    let keepPolling = false;
    let maxPolls = NUMBER_OF_POLLING_TRIES;
    do {
      if (isBlank(location)) {
        break;
      }
      const newEventReports = yield this.loadEventReports.perform();
      // Only poll as long as there are event reports that are either `queued` or `inProgress`.
      const hasPendingEvents = newEventReports.some((event) => get(event, 'inProgress') || get(event, 'queued'));
      // The events creation for a new entry is async, so the intial request for a just created entry
      // could be empty, so we give `NUMBER_OF_POLLING_TRIES`
      maxPolls--;

      keepPolling = hasPendingEvents || maxPolls > 1;
      yield timeout(EVENT_REPORT_POLLING_TIMEOUT);
    } while ((config.environment !== 'test' || config.testLongPolling) && keepPolling);
  }

  rollbackChanges() {
    const { model } = this;
    const userDataChangeset = this.userDataChangeset;

    model.rollbackAttributes();
    userDataChangeset.forEach((changeset) => changeset.rollback());
    model.visitorDocuments.invoke('rollbackAttributes');
  }

  @action
  setAdditionalHosts(hosts) {
    this.model.additionalHosts = hosts;
  }

  @action
  setHostAndUserData(changeset, employee) {
    this.setHost(employee);
    set(changeset, 'value', get(employee, 'name'));
  }

  updateAllUserData(entry) {
    this.beginPropertyChanges();
    this.userDataChangeset.filterBy('isDirty').forEach((fieldChangeset) => {
      addOrUpdateUserData(entry, fieldChangeset.name, fieldChangeset.value);

      fieldChangeset.execute();
      fieldChangeset.rollback();
    });
    this.endPropertyChanges();
  }

  @task
  *loadPreviousEntries(url) {
    if (isBlank(url)) {
      url = urlBuilder.v3.entry.previousEntriesUrl(this.model ? get(this.model, 'id') : null);
    }
    const result = yield this.ajax.request(url, {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
      data: { page: { limit: 3 } },
    });

    const entries = normalizeResponse(this.store, 'entry', result);

    const previousEntries = this.store.push(entries);
    this.previousEntries.addObjects(previousEntries);

    this.morePreviousEntriesUrl = result.links.next;
  }

  @action
  setFlow(flow) {
    set(this.model, 'flowName', flow.name);
    set(this.flowField, 'value', flow.name);

    // Only assign a model to the rel.
    // Need to reset rel so fallback on virtual flow looks up proper flow.
    // Will get fixed on save when proper flow is returned from API
    set(this.model, 'flow', flow.id ? flow : undefined);
    this._buildUserDataChangeset();
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  delete() {
    const dateForDashboard = moment(get(this.model, 'signInTime')).format('YYYY-MM-DD');
    this.showDeleteConfirmationTask.perform([this.model]).then(
      () => {
        this.transitionToRoute('visitors.entries', { queryParams: { date: dateForDashboard } });
      },
      (reason) => {
        if (reason && reason.message !== 'TransitionAborted') {
          throw reason;
        }
      },
    );
  }

  @action
  reprintBadge(entry, printer = null) {
    this.reprintBadgeTask.perform(entry, printer);
  }

  @action
  signOut(time) {
    const signInDateFormatted = moment(get(this.model, 'signInTime')).format('YYYY-MM-DD');
    const signOutTime = time ? moment(`${signInDateFormatted} ${time}`, 'YYYY-MM-DD h:mm a').toDate() : new Date();
    // we need to do this because we set signOutTime here, and again in the sign-out-entry
    // and Ember will throw an error because we're hammering this value
    set(this.model, '_signOutTime', signOutTime);

    this.showSignOutConfirmationTask.perform([this.model]).then(
      () => {
        return this.transitionToRoute('visitors.entries', {
          queryParams: { date: signInDateFormatted },
        });
      },
      (reason) => {
        if (reason && reason.message !== 'TransitionAborted') {
          throw reason;
        } else {
          this.model.rollbackAttributes();
        }
      },
    );
  }

  @action
  checkForAttachments() {
    this.hasAttachments =
      this.visitorDocuments?.isAny('hasAttachedFile') || this.visitorDocuments?.isAny('hasInputFieldData');
  }

  // Manage Visitor Documents

  @cached
  get visitorDocuments() {
    const { model, activeUserDocumentTemplateConfigurations } = this;

    return (activeUserDocumentTemplateConfigurations ?? A()).sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        model.visitorDocumentForTemplate(userDocumentTemplate) ||
        this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get hasDirtyVisitorDocuments() {
    return this.visitorDocuments.any((visitorDocument) => this.isVisitorDocumentPersistable(visitorDocument));
  }

  get isValidVisitorDocuments() {
    const documentsToCheck = this.visitorDocuments?.filterBy('hasAttachedFile');
    if (isEmpty(documentsToCheck)) return true;

    return documentsToCheck.isEvery('isValidDocument');
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  isVisitorDocumentPersistable(visitorDocument) {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;
    const hasDirtyAttributes = visitorDocument.hasDirtyAttributes && !visitorDocument.isNew;

    return isAssociatedToActiveTemplate && (hasAttachmentsWithPendingUploads || hasDirtyAttributes);
  }

  @action
  attachFileToDocument(visitorDocument, userDocumentTemplateAttachment, update) {
    visitorDocument.entries.addObject(this.model);

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument) {
    const { model } = this;
    const { userDocumentTemplate } = visitorDocument;

    if (visitorDocument.isNew) {
      model.visitorDocuments.removeObject(visitorDocument);
      visitorDocument.unloadRecord();
      model.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
    } else {
      this.confirmRemovalOfDocument = visitorDocument;
    }
  }

  @dropTask
  showSignOutConfirmationTask = {
    entries: [],

    *perform(entries) {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @task
  *saveVisitorDocumentTask(entry, visitorDocument) {
    if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

    // Associate the entry's location to the visitor document (fallback to current location)
    const location = this.store.peekRecord('location', get(entry, 'location.id')) || this.state.currentLocation;

    visitorDocument.locations = A([location]);

    yield this.uploadVisitorDocumentAttachmentsTask.perform(entry, visitorDocument);
    yield visitorDocument.save();

    // Cleanup attachments
    visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

    return visitorDocument;
  }

  @task
  *uploadVisitorDocumentAttachmentsTask(entry, visitorDocument) {
    if (!visitorDocument.userDocumentAttachmentsPendingUpload.length) return;

    const { activeStorageExtension } = this;
    const directUploadURL = '/a/visitors/api/direct-uploads';
    const userDocumentTemplateId = get(visitorDocument, 'userDocumentTemplate.id');

    if (!(entry?.id && userDocumentTemplateId)) return;

    const prefix = `user-documents/${userDocumentTemplateId}/entries/${entry.id}`;
    const endpoint = `${directUploadURL}?prefix=${prefix}`;

    return yield all(
      visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
        this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension),
      ),
    );
  }

  @task uploadDocumentAttachmentTask = {
    progress: 0,

    *perform(userDocumentAttachment, endpoint, activeStorageExtension) {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          this.progress = progress;
          if (!get(userDocumentAttachment, 'isDestroyed') && !get(userDocumentAttachment, 'isDestroying')) {
            set(userDocumentAttachment, 'uploadProgress', progress);
          }
        },
      });

      userDocumentAttachment.file = signedId;

      return signedId;
    },
  };

  @task
  *removeVisitorDocumentFromEntryTask(visitorDocument, entry) {
    const { userDocumentTemplate } = visitorDocument;

    try {
      yield visitorDocument.removeFromEntries([entry]);
      entry.visitorDocuments.removeObject(visitorDocument);
      entry.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
      this.flashMessages.showAndHideFlash('success', `${visitorDocument.title} removed from entry`);
    } catch (_) {
      entry.visitorDocuments.removeObject(entry.visitorDocumentForTemplate(userDocumentTemplate));
      entry.visitorDocuments.addObject(visitorDocument);
      this.flashMessages.showFlash('error', `Failed to remove ${visitorDocument.title} from entry`);
    }
  }
}
