/**
 * Formats SCIM provider for display
 *
 * @param {String}    provider    SCIM provider
 *
 * @return {String}  formatted SCIM provider
 */
export function formatScimProvider(provider) {
  let formattedProvider = '';

  switch (provider) {
    case 'onelogin':
      formattedProvider = 'OneLogin';
      break;
    case 'okta':
      formattedProvider = 'Okta';
      break;
    case 'azure':
      formattedProvider = 'Microsoft Entra';
      break;
  }

  return formattedProvider;
}
