import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type MailerSectionBlockOverrideModel from 'garaje/models/mailer-section-block-override';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type StateService from 'garaje/services/state';
import type { SinglePayload } from 'jsonapi/response';

export default class MailerTemplateVersionModel extends Model {
  @service declare state: StateService;

  @belongsTo('mailer-template') declare mailerTemplate: AsyncBelongsTo<MailerTemplateModel>;

  @hasMany('mailer-section-block-override')
  declare mailerSectionBlockOverrides: AsyncHasMany<MailerSectionBlockOverrideModel>;
  @hasMany('location') declare locations: AsyncHasMany<LocationModel>;
  @hasMany('company') declare companies: AsyncHasMany<CompanyModel>;
  @hasMany('flow') declare flows: AsyncHasMany<FlowModel>;

  @attr('string') declare title: string;
  @attr('boolean', { defaultValue: false }) declare isDefault: boolean;

  serializeWithBlockOverrides(): object {
    const { currentCompany, currentLocation } = this.state;
    const serializedOverrides = this.mailerSectionBlockOverrides.map(
      (override) => (<SinglePayload<MailerSectionBlockOverrideModel>>override.serialize()).data,
    );
    const payload = <SinglePayload<MailerTemplateVersionModel> & { meta: unknown }>this.serialize();
    payload.meta = {
      ...(payload.meta || {}),
      location_id: currentLocation.id,
      company_id: currentCompany.id,
    };
    payload.data.relationships = {
      ...(payload.data.relationships || {}),
      'mailer-section-block-overrides': {
        data: serializedOverrides,
      },
    };
    return payload;
  }

  async getEmailPreview(): Promise<unknown> {
    const payload = this.serializeWithBlockOverrides();
    return await apiAction(this, { method: 'PATCH', path: 'preview', data: payload });
  }

  async sendTestEmail(): Promise<unknown> {
    const payload = this.serializeWithBlockOverrides();
    return await apiAction(this, { method: 'PATCH', path: 'send-test', data: payload });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-template-version': MailerTemplateVersionModel;
  }
}
