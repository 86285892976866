import { A } from '@ember/array';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_CREATE_ROLES = [GLOBAL_ADMIN];

export default class LocationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canCreate(): boolean {
    if (!this.state.hasSubscriptions) {
      return false;
    }

    const roleNames = this.currentAdmin.roleNames;
    if (isPresent(_intersection(CAN_CREATE_ROLES, roleNames))) {
      return true;
    }

    const companyRoles = this.currentAdmin.companyRoles || [];

    return A(companyRoles).filterBy('roleName', GLOBAL_ADMIN).length > 0;
  }

  get canReadDisabledLocation(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.ACCOUNT_MANAGEMENT_DISABLED_LOCATION_READ);
  }
}
