import { Promise as EmberPromise, resolve } from 'rsvp';
import config from 'garaje/config/environment';

// based on https://karl-sjogren.com/2015/08/05/lazy-loading-extra-scripts-in-ember/

const LOADING = {};

function scriptLoader(id, src, parent = 'head') {
  let promise;

  if (LOADING[id]) {
    promise = LOADING[id];
  } else {
    promise = LOADING[id] = new EmberPromise(function (resolve, reject) {
      /* eslint-disable no-extra-boolean-cast */
      if (!!document.getElementById(id)) {
        delete LOADING[id];

        return resolve(true);
      }
      /* eslint-enable no-extra-boolean-cast */

      const script = document.createElement('script');
      script.async = true;
      script.id = id;
      script.src = src;
      script.type = 'text/javascript';
      script.addEventListener('load', function () {
        resolve(true);
      });
      script.addEventListener('error', function () {
        reject(`Failed to load ${id} (${src})`);
      });
      document.getElementsByTagName(parent)[0].appendChild(script);
    }, `Loading external script resource ${id} (${src})`);
  }
  return promise;
}

export default scriptLoader;

export function loadGoogleMaps() {
  if (config.environment === 'test') {
    return resolve();
  }

  const id = 'envoygmapsjs';
  const src = `https://maps.googleapis.com/maps/api/js?v=3&libraries=places&key=${config.googleMapsApiKey}&channel=garaje`;

  return scriptLoader(id, src);
}

/**
 *
 * @returns {Promise<boolean>}
 */
export function loadAdobeEmbedApi() {
  if (config.environment === 'test' || !config.adobe.apiKey) {
    return resolve();
  }

  const id = 'adobe-embed-pdf-sdk';
  const src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';

  return scriptLoader(id, src);
}

export function loadHeadway() {
  if (config.environment === 'test') {
    return resolve();
  }

  const id = 'envoyheadwayapp';
  const src = 'https://cdn.headwayapp.co/widget.js';

  return scriptLoader(id, src);
}

export function loadChameleon() {
  if (config.environment === 'test') {
    return resolve();
  }

  const id = 'envoychameleonjs';
  const src = '/assets/js/chameleon.js';
  // swallow errors with loading script
  return scriptLoader(id, src).catch((_e) => null);
}

/**
 *
 * @returns {Promise<boolean>}
 */
export function loadSrcDoc() {
  const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

  if (!isIE) {
    return resolve(false);
  }

  const id = 'envoysrcdocspolyfill';
  const src = '/assets/js/srcdoc-polyfill.js';

  return scriptLoader(id, src, 'body');
}
