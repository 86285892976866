import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'garaje/config/environment';
import { type PendingUpload } from 'garaje/pods/components/properties/property-form/cover-photo-config/component';

interface PropertyFormPhotoUploaderArgs {
  onPendingUpload?: (pendingUpload: PendingUpload) => unknown;
  reset?: () => unknown;
}

export default class PropertyFormPhotoUploader extends Component<PropertyFormPhotoUploaderArgs> {
  @tracked hasFile?: boolean;

  validPhotoTypes = /image\/(gif|jpe?g|png)/;

  get directUploadUrl(): string {
    return `${config.apiHost}/a/multi-tenancy/api/direct-uploads`;
  }

  photoUploaderElement!: HTMLElement;

  @action
  onPendingUpload(pendingUpload: PendingUpload): void {
    this.hasFile = true;
    this.args.onPendingUpload?.(pendingUpload);
  }

  @action
  reset(): void {
    this.hasFile = false;
    this.args.reset?.();
  }

  @action
  selectFile(): void {
    this.photoUploaderElement.querySelector('input')?.click();
  }
}
