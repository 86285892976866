import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class DashboardIndexRoute extends Route {
  title = 'Home · Envoy';

  @service state;
  @service store;
  @service featureFlags;
  @service router;

  async model() {
    const { billingCompany, vrSubscription, workplaceSubscription, deliveriesSubscription, roomsSubscription } =
      this.state;
    const currentLocation = this.modelFor('current-location');

    let pluginInstalls = undefined;
    let homepageGroups = [];

    if (this.featureFlags.isEnabled('ms-teams-v2-upgrade-banner')) {
      pluginInstalls = this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
        page: { limit: 100 },
      });
    }

    if (
      this.state.hasSubscriptions &&
      this.featureFlags.isEnabled('growth_show_personalized_homepage') &&
      this.featureFlags.isEnabled('growth_show_personalized_homepage_modules')
    ) {
      homepageGroups = await this.store.query('homepage-group', {
        include:
          'homepage-item-group-mappings.homepage-item.setup-guide-items,homepage-item-group-mappings.homepage-item.marketing-items,homepage-item-group-mappings.homepage-item.helpdesk-items.helpdesk-questions,homepage-item-group-mappings.homepage-item.help-resources-items',
      });
    }

    return hash({
      billingCompany,
      location: currentLocation,
      vrSubscription,
      workplaceSubscription,
      deliveriesSubscription,
      roomsSubscription,
      pluginInstalls,
      homepageGroups,
    });
  }

  redirect() {
    if (!this.state.hasSubscriptions && this.featureFlags.isEnabled('dashboard_multiproduct_welcome_page')) {
      this.router.transitionTo('dashboard.welcome');
    }
  }
}
