import ObjectProxy from '@ember/object/proxy';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import { get, setProperties } from '@ember/object';

export default class MailerSectionBlockAttributeObjectProxy extends ObjectProxy {
  defaultContent;

  get isDefault() {
    return _isEqual(get(this, 'value'), get(this, 'defaultContent.value'));
  }

  get defaultValue() {
    return get(this, 'defaultContent.value');
  }

  reset() {
    setProperties(this, _cloneDeep(get(this, 'defaultContent')));
  }
}
