import Component from '@glimmer/component';
import { action, get } from '@ember/object';

/**
 * @param {object}         attribute
 * @param {boolean}        active
 * @param {Function}       onChangeValue
 */
export default class Dropdown extends Component {
  @action
  onChangeValue(option) {
    this.args.onChangeValue(option.identifier);
  }

  get selected() {
    const { attribute } = this.args;
    const value = get(attribute, 'value');
    const options = get(attribute, 'options');
    return options.find((option) => option.identifier === value);
  }
}
