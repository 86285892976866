import Controller from '@ember/controller';
import { next } from '@ember/runloop';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, and, not, or } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import config from 'garaje/config/environment';

export default class DashboardIndexController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service localStorage;
  @service metrics;
  @service state;
  @service performanceLoadTime;

  @alias('state.vrSubscription') subscription;
  @not('state.hasSubscriptions') hasNoSubscriptions;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') canVisitBilling;

  @tracked showMultiProductModal = true;
  @tracked showInteractiveDemoModal = false;
  @tracked interactiveDemoUrl = null;
  /**
   * @type {boolean} checks for Visitors subscription and any enabled Protect features
   */
  @and('state.visitorsSubscription', 'state.currentLocation.isProtectSetup') isProtectSetup;

  /**
   * Set the `height`of all`.outter-product-box` divs to the highest one
   */
  _resizeBoxes() {
    const innerContainers = [...document.querySelectorAll('div.inner-product-box')];
    const outterContainers = [...document.querySelectorAll('div.outer-product-box')];

    const heights = innerContainers.map((e) => e.offsetHeight);
    const highest = Math.max(...heights);

    outterContainers.forEach((e) => (e.style.height = `${highest}px`));
  }

  get showCouponOfferModal() {
    const isConnectCustomer =
      (this.state.tenantConnectionRequests || this.state.tenantConnections) &&
      (this.state.tenantConnectionRequests?.length || this.state.tenantConnections?.length);

    return this.subscription && this.canVisitBilling && !isConnectCustomer;
  }

  @action
  openInteractiveDemoModal(demoUrl) {
    this.interactiveDemoUrl = demoUrl;
    this.showInteractiveDemoModal = true;
  }

  @action
  closeInteractiveDemoModal() {
    this.showInteractiveDemoModal = false;
    this.interactiveDemoUrl = null;
  }

  @action
  onResize() {
    next(this, this._resizeBoxes);
  }

  /**
   * Handles tracking
   *
   * @param {Event} evt - the DOM event
   */
  @action
  trackYourEnvoyClicked(evt) {
    if (config.environment === 'test' && evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const { target } = evt;
    const header = target.parentElement.querySelector('h4').innerText;
    const message_body = target.parentElement.querySelector('p').innerText;
    const button_text = target.innerText;
    this.metrics.trackEvent('Dashboard Message Clicked', {
      header,
      message_body,
      button_text,
      type: 'new_feature',
    });
  }

  @action
  logLoadTime() {
    this.performanceLoadTime.logPageUsableMetric();
  }
}
