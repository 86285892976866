import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';

type SupportedTemplates = MailerTemplateModel | MailerTemplateVersionModel;

interface ManageRecipientsModalComponentSignature {
  Args: {
    flows: FlowModel[];
    mailerTemplates: SupportedTemplates[];
    onSave: (templates: SupportedTemplates[]) => Promise<void>;
  };
}

/**
 * All of this complexity is because we cannot save a mailer-template relationships via a flow
 * This ObjectProxy computes the selected, saved and default templates for a given flow and provides
 * information about which templates need to be saved when the selected template is updated
 */
class FlowProxy extends ObjectProxy<FlowModel> {
  @tracked mailerTemplates!: SupportedTemplates[];
  @tracked _selectedTemplate!: SupportedTemplates;

  get defaultTemplate() {
    return this.mailerTemplates.find((mailerTemplate) => {
      if ('isDefault' in mailerTemplate) return mailerTemplate.isDefault;
      return mailerTemplate.envoyDefault;
    });
  }

  get savedTemplate() {
    const { defaultTemplate, mailerTemplates } = this;
    const matchedTemplate = mailerTemplates.find((mailerTemplate) =>
      isPresent(mailerTemplate.flows.findBy('id', this.content?.id)),
    );
    return matchedTemplate || defaultTemplate;
  }

  get selectedTemplate() {
    return this._selectedTemplate || this.savedTemplate || this.defaultTemplate;
  }

  set selectedTemplate(template: SupportedTemplates) {
    this._selectedTemplate = template;
  }

  get isDirty() {
    return this.selectedTemplate !== this.savedTemplate;
  }

  updateRelationships(): SupportedTemplates[] {
    const { selectedTemplate, savedTemplate, isDirty, content } = this;
    const updatedTemplates: SupportedTemplates[] = [];
    if (isDirty) {
      if (savedTemplate) {
        savedTemplate.flows.removeObject(content!);
        updatedTemplates.push(savedTemplate);
      }
      if (selectedTemplate) {
        selectedTemplate.flows.addObject(content!);
        updatedTemplates.push(selectedTemplate);
      }
    }
    return updatedTemplates;
  }
}

export default class ManageRecipientsModalComponent extends Component<ManageRecipientsModalComponentSignature> {
  @tracked flows;

  constructor(owner: unknown, args: ManageRecipientsModalComponentSignature['Args']) {
    super(owner, args);
    const { flows, mailerTemplates } = this.args;
    this.flows = flows.map((flow) => FlowProxy.create({ mailerTemplates, content: flow }));
  }

  get hasDirtyFlows(): boolean {
    return isPresent(this.flows.find((flow) => flow.isDirty));
  }

  @action
  onChange(flow: FlowProxy, template: MailerTemplateModel): void {
    flow.selectedTemplate = template;
  }

  saveRecipientsTask = dropTask(async () => {
    const { flows } = this;
    const { onSave } = this.args;
    const templatesToSave = flows.reduce<NativeArray<SupportedTemplates>>(
      (acc, flow) => acc.addObjects(A(flow.updateRelationships())),
      A(),
    );
    await onSave(templatesToSave);
  });
}
