import Component from '@glimmer/component';
import { action, set } from '@ember/object';

export default class EmailSidebarSettingsAttributeComponent extends Component {
  @action
  resetAttribute() {
    this.args.attribute.reset();
    this.args.onUpdate();
  }

  @action
  setAttributeValue(value) {
    set(this.args.attribute, 'value', value);
    this.args.onUpdate();
  }
}
