import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { isPresent, isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { addMinutes, formatISO, isFuture, endOfDay, startOfDay, subDays, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import type { TaskInstance } from 'ember-concurrency';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AccessEventModel from 'garaje/models/access-event';
import type { Field } from 'garaje/models/location';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type CurrentLocationService from 'garaje/services/current-location';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { modifyDateInTimeZone, parseYyyyMmDdInTimeZone, DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { and, notEmpty, reads } from 'macro-decorators';
import { resolve } from 'rsvp';

import type { WorkplaceAccessEventsRouteModel } from './route';

const MIN_SEARCH_LENGTH = 3;

const HEADER_ENUMS = {
  name: 'Name',
  autoSignInStatus: 'Auto sign-in status',
  entranceName: 'Entrance Name',
  integrationName: 'Integration Name',
  eventTimeHeader: 'Event Time',
  date: 'Date',
};

const PRIMARY_HEADERS = [
  { name: HEADER_ENUMS.name, componentName: 'custom-column', sort: 'person-name' },
  { name: HEADER_ENUMS.entranceName, componentName: 'custom-column' },
  { name: HEADER_ENUMS.integrationName, componentName: 'custom-column' },
  { name: HEADER_ENUMS.eventTimeHeader, componentName: 'custom-column', sort: 'message-timestamp' },
];

type AccessEventPage = PaginatedRecordArray<AccessEventModel> & { meta: { 'total-count': number } };

interface AccessEventQueryParams {
  'location-ids': string;
  'start-time'?: string;
  'end-time'?: string;
  query?: string;
  employee?: string;
}

interface SortableField extends Field {
  sort?: string;
}

interface PluginStatusResponse {
  jsonapi: {
    version: string;
  };
  data: {
    type: string;
    id: string;
    relationships: {
      'plugin-install': {
        data: {
          id: string;
          type: string;
        };
      };
      location: {
        data: {
          id: string;
          type: string;
        };
      };
    };
    attributes: {
      'last-successful-event': string | null;
      'current-status': 'OK' | 'FAILURE';
    };
  }[];
}

type PluginInstallIdentifier = string;

enum Status {
  OK = 'OK',
  FAILURE = 'FAILURE',
}
interface BasePluginState {
  status: Status;
  lastSuccessfulEvent: string | null; // ISO string for OffsetDateTime
}

export interface PluginInstallStatus {
  installId: PluginInstallIdentifier;
  locationId: string | null;
  zoneId: string | null;
  currentStatus: BasePluginState;
  isDismissed: boolean;
}

export default class WorkplaceAccessEventsController extends Controller {
  declare model: WorkplaceAccessEventsRouteModel;
  declare limit: number;

  @service declare currentLocation: CurrentLocationService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare ajax: AjaxFetchService;

  queryParams = ['startDate', 'endDate', 'date', 'query', 'filter', 'refresh', 'sort'];
  private calendarRef: HTMLElement | null = null;

  @tracked refresh = ''; // Flag to force a model refresh
  @tracked date: string | null = '';
  @tracked query = '';
  @tracked inputQuery: string | null = null;
  @tracked accessEventsCount = 0;
  @tracked filter = 'all';
  @tracked sort = '-message-timestamp';
  @tracked startDate: Date | null = startOfDay(new Date());
  @tracked endDate: Date | null = endOfDay(new Date());
  @tracked startTime: Date | null = startOfDay(new Date());
  @tracked endTime: Date | null = endOfDay(new Date());
  @tracked page = 1;
  @tracked totalLoadedAccessEvents!: number;
  @tracked showCalendar = false;
  @tracked selectedDateRange = 'Today';
  @tracked pluginStatuses: PluginInstallStatus[] = [];

  parsePluginStatuses(response: PluginStatusResponse): PluginInstallStatus[] {
    const dismissedPluginStatuses = this.pluginStatuses.filter((it) => {
      return it.isDismissed;
    });
    return response.data
      .map((resource) => {
        const pluginInstallId = resource.relationships['plugin-install'].data.id;
        let isDismissed = false;
        // early return if required relationships are missing
        if (dismissedPluginStatuses.some((it) => it.installId === pluginInstallId)) {
          isDismissed = true;
        }

        return {
          installId: resource.relationships['plugin-install'].data.id,
          locationId: resource.relationships['location'].data.id,
          zoneId: null,
          currentStatus: {
            status: resource.attributes['current-status'] === 'OK' ? Status.OK : Status.FAILURE,
            lastSuccessfulEvent: resource.attributes['last-successful-event'],
          },
          isDismissed: isDismissed,
        };
      })
      .filter((it) => it !== null) as PluginInstallStatus[];
  }

  @notEmpty('query') showClearButton!: boolean;
  @and('page', 'loadAccessEvents.isIdle', 'mutateQuery.isIdle') showCount!: boolean;
  @reads('currentLocation.timezone', 'America/Los_Angeles') timezone!: string;

  @action
  formatTimestamp(timestamp?: string | null): string {
    if (!timestamp) return 'No event recorded';

    const date = new Date(timestamp);
    return date.getTime() ? format(date, "MMMM do, 'at' h:mma") : 'Invalid timestamp';
  }

  @action
  fetchPluginName(pluginInstallId: string): string {
    const pluginInstall = this.store.peekRecord('plugin-install', pluginInstallId);
    if (!pluginInstall) return 'Unknown plugin';
    // eslint-disable-next-line ember/use-ember-get-and-set
    const plugin = this.store.peekRecord('plugin', pluginInstall.plugin.get('id'));
    return plugin ? plugin.name : 'Unknown plugin';
  }

  @action
  dismissPluginInstallStatusNotification(pluginInstallId: string): void {
    const pluginStatus = this.pluginStatuses.find((it) => it.installId === pluginInstallId);
    // set the plugin status to dismissed
    if (pluginStatus) {
      pluginStatus.isDismissed = true;
    }
    this.pluginStatuses = [...this.pluginStatuses];
  }

  get minSearchLength(): number {
    return MIN_SEARCH_LENGTH;
  }

  get todayAsString(): string {
    return formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get todayAsDate(): Date {
    return parseYyyyMmDdInTimeZone(this.todayAsString, new Date(), this.timezone);
  }

  get isToday(): boolean {
    const { dateWithDefault, startDateWithDefault, endDateWithDefault, todayAsString } = this;

    if (dateWithDefault !== todayAsString) return false;
    if (formatInTimeZone(startDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;
    if (formatInTimeZone(endDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;

    return true;
  }

  get isAfterToday(): boolean {
    const { startDateWithDefault, endDateWithDefault } = this;

    return isFuture(startDateWithDefault) && isFuture(endDateWithDefault);
  }

  get currentQuery(): string {
    // If a search query typed in, use it.
    // Fallback to query param.
    const { inputQuery, query } = this;

    return isNone(inputQuery) ? query : inputQuery;
  }

  get dateRangeFilterOptions(): string[] {
    const { selectedDateRangeWithDefault } = this;
    const options = ['Today', 'Yesterday', 'Past 7 days', 'Past 14 days', 'Past 30 days', 'Custom range'];

    if (!options.includes(selectedDateRangeWithDefault)) {
      options.push(selectedDateRangeWithDefault);
    }

    return options;
  }

  get dateWithDefault(): string {
    return this.date || formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get startDateWithDefault(): Date {
    if (this.startDate) return new Date(this.startDate);

    const { dateWithDefault } = this;

    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const start = modifyDateInTimeZone(date, this.timezone, startOfDay);

    return start;
  }

  get endDateWithDefault(): Date {
    if (this.endDate) return new Date(this.endDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const end = modifyDateInTimeZone(date, this.timezone, endOfDay);

    return end;
  }

  get startTimeWithDefault(): Date {
    if (this.startTime) return new Date(this.startTime);

    const { dateWithDefault } = this;

    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const start = modifyDateInTimeZone(date, this.timezone, startOfDay);

    return start;
  }

  get endTimeWithDefault(): Date {
    if (this.endTime) return new Date(this.endTime);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const end = modifyDateInTimeZone(date, this.timezone, endOfDay);

    return end;
  }

  get selectedDateRangeWithDefault(): string {
    const { selectedDateRange, isToday } = this;

    if (isToday) return 'Today';
    if (selectedDateRange) return selectedDateRange;

    return 'Custom range';
  }

  @action
  didSelectDateRange(startDate: Date, endDate: Date): void {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_DATE_RANGE_SELECT_CLICKED');

    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedDateRange = 'Custom range';
    this.date = '';
  }

  get startDateFormat(): string {
    const { timezone, startDateWithDefault } = this;

    return formatInTimeZone(startDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get endDateFormat(): string {
    const { timezone, endDateWithDefault } = this;

    return formatInTimeZone(endDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  @action
  setStartTime(date: Date): void {
    this.startTime = date;
  }

  @action
  setEndTime(date: Date): void {
    this.endTime = date;
  }

  @action
  setStartDate(date: Date): void {
    this.startDate = date;
  }

  @action
  setEndDate(date: Date): void {
    this.endDate = date;
  }

  @action
  didSelectTimeRange(startTime: Date, endTime: Date): void {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_TIME_RANGE_SELECT_CLICKED');

    this.startTime = startTime;
    this.endTime = endTime;
    this.selectedDateRange = 'Custom range';
    this.date = '';
  }

  @action
  setStartAndEndTime(): void {
    this.startTime = this.startTimeWithDefault;
    this.endTime = this.endTimeWithDefault;
    this.startDate = this.startDateWithDefault;
    this.endDate = this.endDateWithDefault;
  }

  get allAccessEvents(): AccessEventModel[] {
    return this.model.accessEvents;
  }

  get relevantAccessEvents(): AccessEventModel[] {
    // eslint-disable-next-line ember/no-get
    return get(this.model, 'accessEvents');
  }

  get hasMorePages(): boolean {
    const currentTotal = this.page * this.limit;
    return currentTotal <= this.accessEventsCount;
  }

  get primaryHeaders(): SortableField[] {
    return PRIMARY_HEADERS;
  }

  get accessEventDashboardFields(): Field[] {
    const dashboardFields = this.currentLocation.location.dashboardFields;
    return [...dashboardFields.toArray()];
  }

  get fieldOptions(): Field[] {
    const primaryHeaders = this.primaryHeaders;
    let headers: SortableField[] = [...primaryHeaders];
    headers = headers.map(({ name, componentName, sort }) => {
      return {
        name,
        componentName,
        // always show name
        show:
          name === 'Name' || name === 'Event Time'
            ? true
            : isPresent(A(this.accessEventDashboardFields).findBy('name', name)),
        disabled: name === 'Name' || name === 'Event Time' ? true : false,
        sort,
      };
    });
    return headers;
  }

  sortAccessEvents(field: string, direction: string): void {
    // set sort
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;
    this.metrics.trackEvent('Access Events Sorted', { sort_field_name: field });
  }

  calculateAccessEventsCount(): void {
    this.accessEventsCount = this.totalLoadedAccessEvents;
  }

  accessEventParams(_include: string[] = []): Record<string, unknown> {
    const offset = (this.page - 1) * this.limit;
    const filter: AccessEventQueryParams = {
      'location-ids': this.currentLocation.location.id,
    };

    if (isPresent(this.query)) {
      filter.query = this.query;
    }

    filter['start-time'] = formatISO(this.startDateWithDefault);
    filter['end-time'] = formatISO(this.endDateWithDefault);

    return {
      filter,
      page: { limit: this.limit, offset },
      sort: this.sort,
    };
  }

  @action
  updateInputQuery(employeeName: string): void {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_SEARCHED_EMPLOYEES');

    // Set input value immediately.
    // This reduces risk of rendered input reverting to a previous value.
    this.inputQuery = employeeName;
    if (employeeName) {
      void this.mutateQuery.perform(this.inputQuery);
    } else {
      this.clearSearch();
    }
  }

  mutateQuery = restartableTask(async (employeeName: string) => {
    if (this.query === employeeName) return;

    // Stop if the query is "too short"
    if (employeeName.length < this.minSearchLength) return;

    await timeout(zft(500));

    await this.loadAccessEvents.cancelAll();
    this.query = employeeName;
    this.page = 0;
    this.metrics.trackEvent('Access Events Searched', { search_value: this.query });
  });

  get undismissedPluginStatuses(): PluginInstallStatus[] {
    return this.pluginStatuses.filter((it) => {
      return !it.isDismissed && it.currentStatus.status === Status.FAILURE;
    });
  }

  loadPluginStatuses = dropTask(async () => {
    const url = urlBuilder.pluginStatusesUrl();
    const settings = {
      accept: 'application/vnd.api+json',
      data: { filter: { 'location-ids': this.currentLocation.location.id } },
    };
    const response = await this.ajax.request<PluginStatusResponse>(url, settings);
    this.pluginStatuses = this.parsePluginStatuses(response);

    // fetch plugin-installs if not in store
    const installIds = this.pluginStatuses.map((status) => status.installId);
    const pluginInstalls = this.store.peekAll('plugin-install');
    const shouldFetch = installIds.some((installId) => !pluginInstalls.findBy('id', installId));
    if (shouldFetch) {
      await this.store.query('plugin-install', { filter: { location: this.currentLocation.location.id } });
    }
  });

  loadAccessEvents = dropTask(async () => {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_LOADING_ACCESS_EVENTS');
    try {
      this.page++;

      const accessEventParams = this.accessEventParams();
      const accessEvents = <AccessEventPage>await this.store.query('access-event', accessEventParams);
      await timeout(zft(250));
      // @ts-ignore private function
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.beginPropertyChanges();
      A(this.model.accessEvents).pushObjects(A(accessEvents.toArray()));
      set(this.model, 'accessEvents', A(this.model.accessEvents).uniqBy('id'));
      this.totalLoadedAccessEvents = accessEvents.meta?.['total-count'];
      // @ts-ignore private function
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.endPropertyChanges();
      this.calculateAccessEventsCount();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      // TODO: handle error state here. try...catch is quite big.
      // throw e;
      this.page--;
    }
  });

  loadAccessEventsRestartableTask = restartableTask(async () => {
    await timeout(zft(250));
    this.page = 0;
    set(this.model, 'accessEvents', []);
    await this.loadAccessEvents.perform();
  });

  @action
  onDateRangeSelect(option: string): void {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_DATE_RANGE_SELECT_CLICKED');

    let start = startOfDay(new Date());
    let end = endOfDay(new Date());

    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 14 days':
        start = subDays(start, 14);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Custom range':
        return;
    }

    this.date = '';
    this.selectedDateRange = option;
    this.startDate = addMinutes(start, this.state.minutesBetweenTimezones);
    this.endDate = addMinutes(end, this.state.minutesBetweenTimezones);
  }

  _loadMore(): void | Promise<void> | TaskInstance<void> {
    // Because of the way loadMore works -- there are scenarios where
    // this can be called if destroyed
    if (this.isDestroyed) {
      return;
    }
    if (this.hasMorePages) {
      return this.loadAccessEvents.perform();
    } else {
      return resolve();
    }
  }

  loadMore(): void | Promise<void> | TaskInstance<void> {
    return this._loadMore();
  }

  @action
  toggleDashboardField({ name, componentName, show }: Field & { show: boolean }): void {
    let dashboardFields = this.currentLocation.location.dashboardFields;

    if (show) {
      dashboardFields.pushObject({ name, componentName });
    } else {
      dashboardFields = A(dashboardFields.filter((field) => field.name !== name));
    }

    this.currentLocation.location.dashboardFields = A(dashboardFields);
    void this.currentLocation.location.save();
  }

  @action
  clearSearch(): void {
    this.page = this.query ? 0 : this.page;
    this.inputQuery = null;
    this.query = '';
  }

  @action
  setCalendarRef(element: HTMLElement): void {
    this.calendarRef = element;
  }

  @action
  handleBlur(event: FocusEvent): void {
    if (this.calendarRef && !this.calendarRef.contains(event.relatedTarget as Node)) {
      this.showCalendar = false;
    }
  }

  @action
  toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'workplace.access-events': WorkplaceAccessEventsController;
  }
}
