import { Scalars, Exact } from './feature-config-types';
import {
  AssignedAndUnassignedEmployeesMeta,
  Desk,
  Employee,
  EmployeeAttendancePerformanceBasic,
} from './assigned-and-unassigned-employees-types';
import { Room } from '../../utils/graphql/graphql-types';

export type DepartmentName = string;

export type ResourceItem = {
  id?: string;
  featureId: string;
  name: string;
  display: string;
  isLastExpanded?: boolean;
};

export enum ResourceOverviewType {
  Employee = 'seating',
  Resource = 'resource',
}

export type EmployeeItem = {
  id: number;
  name: string;
  display: string;
  email: string;
  department: string;
  assignedDesks: Array<Desk>;
  attendancePerformance: EmployeeAttendancePerformanceBasic | null;
  assignments: ScheduledAssignment[];
  isLastExpanded?: boolean;
  meta: string[];
  iconClass: string | null;
  shouldShowIcon: boolean;
};

export type ScheduledAssignment = {
  id: string;
  name: string;
  start: Scalars['DateTime'];
  employeeId: string;
  deskId: string;
  display: string;
  isLastExpanded?: boolean;
};

// representation of the response from the getAssignedAndUnassignedEmployee GQL query
export type EmployeesOverview = {
  unassigned: Array<Employee>;
  assigned: Array<Employee>;
  meta: AssignedAndUnassignedEmployeesMeta;
};

export type ScheduledEmployeesPanel = {
  assignments: Array<ScheduledAssignment>;
};

export type EmployeesOverviewSidePanel = {
  // eslint-disable-next-line no-undef
  unassigned: Record<DepartmentName, Array<EmployeeItem>> | Array<EmployeeItem>;
  // eslint-disable-next-line no-undef
  assigned: Record<DepartmentName, Array<EmployeeItem>> | Array<EmployeeItem>;
  meta?: AssignedAndUnassignedEmployeesMeta;
};

export type DesksSection = {
  'assigned-desks': Array<ResourceItem>;
  'hotel-desks': Array<ResourceItem>;
  'disabled-desks': Array<ResourceItem>;
};

export type RoomsSection = {
  'enabled-rooms': Array<ResourceItem>;
  'disabled-rooms': Array<ResourceItem>;
};

export type PointsOfInterestSection = {
  AED?: Array<ResourceItem>;
  EMERGENCY_EXIT?: Array<ResourceItem>;
  FIRE_EXTINGUISHER?: Array<ResourceItem>;
  FIRST_AID_KIT?: Array<ResourceItem>;
  BREAK_ROOM?: Array<ResourceItem>;
  CAFE?: Array<ResourceItem>;
  DELIVERY_AREA?: Array<ResourceItem>;
  KITCHEN?: Array<ResourceItem>;
  MOTHERS_ROOM?: Array<ResourceItem>;
  PRINTER?: Array<ResourceItem>;
  VISITOR_AREA?: Array<ResourceItem>;
  ACCESSIBLE?: Array<ResourceItem>;
  ELEVATOR?: Array<ResourceItem>;
  RESTROOM?: Array<ResourceItem>;
  STAIRS?: Array<ResourceItem>;
  CUSTOM?: Array<ResourceItem>;
};

export type FeaturesOverview = {
  desks: DesksSection;
  rooms: RoomsSection;
  'points-of-interest': PointsOfInterestSection;
};

export type MapFloor = {
  id: Scalars['ID'];
  name: Scalars['String'];
  rasterImage: Scalars['String'];
  ordinality: Scalars['Int'];
  features: Array<MapFeature>;
  __typename: 'MapFloor';
};

export type MapFeatureAttrs = {
  id: Scalars['ID'];
  tempId?: Scalars['ID'];
  externalId?: Scalars['ID'];
  floorId: Scalars['ID'];
  name: Scalars['String'];
  notes: Scalars['String'];
  enabled: Scalars['Boolean'];
  type: Scalars['String'];
  floor: MapFloor;
};

export type MapFeature = MapFeatureAttrs & {
  __typename: 'MapFeature';
};

export type DeskMapFeature = MapFeatureAttrs & {
  desk: Desk;
  __typename: 'DeskMapFeature';
};

export type RoomMapFeature = MapFeatureAttrs & {
  room: Room;
  __typename: 'RoomMapFeature';
};

export type GetMapFeaturesQueryVariables = Exact<{
  areaMapID: Scalars['ID'];
  floorID: Scalars['ID'];
}>;

export type GetMapFeaturesQuery = {
  __typename?: 'Query';
  areaMap: {
    __typename: 'AreaMap';
    id: Scalars['ID'];
    floors: Array<MapFloor>;
  };
};
