import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask, restartableTask } from 'ember-concurrency';
import type PrinterModel from 'garaje/models/printer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { VisitorsDevicesPrintersIndexRouteModel } from './route';

export default class VisitorsDevicesPrintersIndexController extends Controller {
  declare model: VisitorsDevicesPrintersIndexRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  @tracked editPrinterId = null;

  get canAddAnotherPrinter(): boolean {
    const addingFirstPrinter = this.model.printers.length === 0;
    const canAccessMultiplePrinters = this.model.vrSubscription?.canAccessMultiplePrinters;
    const canAddPrinters = this.abilities.can('create printers');

    return Boolean(canAddPrinters && (addingFirstPrinter || canAccessMultiplePrinters));
  }

  savePrinterTask = restartableTask(async (printerChangeset: DetailedChangeset<PrinterModel>) => {
    try {
      await printerChangeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.editPrinterId = null;
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  deletePrinterTask = dropTask(async (printer: PrinterModel) => {
    try {
      await printer.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Printer removed successfully');
      if (this.model.printers.length === 0) {
        // if last printer was just removed...
        if (this.canAddAnotherPrinter && this.model.vrSubscription?.canEnableBadgePrinting) {
          // ...and it's possible for the current user to add new printers,
          // redirect to the "new printer" page.
          // (This is the same logic used to determine whether to show the "new printer" button on the page.)
          void this.router.transitionTo('visitors.devices.printers.new');
        }
      }
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  cancelChanges(printerChangeset: DetailedChangeset<PrinterModel>): void {
    printerChangeset.rollback();
    this.editPrinterId = null;
  }
}
