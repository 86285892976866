import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type MailerTemplateModel from 'garaje/models/mailer-template';

export default class MailerTemplateSectionModel extends Model {
  // Relationships
  @belongsTo('mailer-template') declare mailerTemplate: AsyncBelongsTo<MailerTemplateModel>;
  @belongsTo('mailer-section') declare mailerSection: AsyncBelongsTo<MailerSectionModel>;
  // Attributes
  @attr('immutable', { defaultValue: () => ({}) }) declare settings: Record<string, unknown>;
  @attr('number') declare position: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-template-section': MailerTemplateSectionModel;
  }
}
