import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';

export default class MailerTemplateVersionAdapter extends ApplicationAdapter {
  @service state;

  urlForFindRecord() {
    const { currentLocation, currentCompany } = this.state;
    const url = super.urlForFindRecord(...arguments);
    const includes = [
      'mailer-template',
      'flows',
      'locations',
      'companies',
      'mailer-section-block-overrides.mailer-section-block',
    ];
    // company and location ids are needed because mailer-block settings values are dynamic
    // and because some templates may be returned for certain locations / companies only
    return `${url}?meta[location_id]=${currentLocation.id}&meta[company_id]=${
      currentCompany.id
    }&include=${includes.join(',')}`;
  }
}
