import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {String}              inputId
 * @param {String}              days
 * @param {Boolean}             disabled
 * @param {String}              errorMessage
 * @param {Function}            validate
 * @param {Function}            updateDays
 */
export default class ReSignDaysInput extends Component {
  @action
  onKeyPress(event) {
    const days = event.target.value;
    return event.key.match(/^[0-9]$/) !== null && days.length < 4;
  }

  @action
  onInput(event) {
    const days = event.target.value;
    this.args.validate(days);
    // for paste, truncate
    this.args.updateDays(days.length > 4 ? days.slice(0, 4) : days);
  }

  @action
  onChange(event) {
    const days = event.target.value;
    this.args.validate(days);
    this.args.updateDays(days);
  }
}
