import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type CompanyModel from 'garaje/models/company';
import type ConfigModel from 'garaje/models/config';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type DeviceModel from 'garaje/models/device';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type GroupModel from 'garaje/models/group';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type TenantModel from 'garaje/models/tenant';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type LocationSettingsIndexController from './controller';

export interface LocationSettingsIndexRouteModel {
  currentLocation: LocationModel;
  company: CompanyModel;
  ipads: RecordArray<DeviceModel>;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel;
  groups: ArrayProxy<GroupModel> | GroupModel[];
  config: ConfigModel;
  tenants: RecordArray<TenantModel> | TenantModel[];
  nonDisconnectedTenants: TenantModel[];
  connectLocationConfiguration?: ConnectLocationConfigurationModel;
  emergencyNotificationConfiguration?: EmergencyNotificationConfigurationModel;
}

export default class LocationSettingsIndexRoute extends Route {
  declare controller: LocationSettingsIndexController;

  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare router: RouterService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare store: StoreService;
  @service declare featureFlags: FeatureFlagsService;

  title = 'Location Settings · Envoy';

  async model(): Promise<LocationSettingsIndexRouteModel> {
    const currentLocation = this.state.currentLocation;
    const vrSubscription = this.state.vrSubscription!;
    const company = currentLocation.company;
    const currentLocationId = currentLocation.id;
    const ipads = this.store.query('device', { filter: { location: currentLocationId } });
    const config = currentLocation.config;
    let emergencyNotificationConfiguration;

    if (this.abilities.can('edit-features emergency-notification')) {
      emergencyNotificationConfiguration = await currentLocation.getEmergencyNotificationConfiguration();
    }
    let groups: ArrayProxy<GroupModel> | GroupModel[] = [];
    let tenants: RecordArray<TenantModel> | TenantModel[] = [];

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    tenants = await this.store.query('tenant', {
      filter: {
        location: currentLocationId,
      },
    });

    let connectLocationConfiguration;
    const nonDisconnectedTenants = tenants?.filter((tenant) => tenant.status !== 'disconnected');

    // only load config if location is connected to a property to prevent unnecessary configs from being created
    if (nonDisconnectedTenants.length && this.featureFlags.isEnabled('property-management-auto-sign-in')) {
      // will create the value in BE if it doesn't exist
      connectLocationConfiguration = (
        await this.store.query('connect-location-configuration', {
          filter: {
            location: currentLocationId,
          },
        })
      ).firstObject;
    }

    if (this.abilities.can('view locations-grouping')) {
      groups = await this.store.findAll('group');
    }

    return hash({
      currentLocation,
      company,
      ipads,
      vrSubscription,
      groups,
      config,
      tenants,
      nonDisconnectedTenants,
      connectLocationConfiguration,
      emergencyNotificationConfiguration,
    });
  }

  setupController(
    controller: LocationSettingsIndexController,
    model: LocationSettingsIndexRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    const { currentLocation, tenants } = model;

    controller.tenants = tenants;
    controller._buildChangeset(currentLocation);

    const scrollTo = controller.scrollTo;
    if (scrollTo) {
      controller.scrollTo = null; // clear out the query param so it only scrolls once
      // eslint-disable-next-line @typescript-eslint/unbound-method
      scheduleOnce('afterRender', this, this._scrollIntoView, scrollTo);
    }
  }

  _scrollIntoView(scrollTo: string): void {
    const el = document.getElementById(scrollTo);
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controller;
    const { model } = controller;
    const { currentLocation } = model;

    const connectRoutePattern = /location-settings\.index\.connect$/;
    const traversingConnect =
      connectRoutePattern.test(transition.from!.name) || connectRoutePattern.test(transition.to.name);

    // no need to display confirmation modal when nothing is changed
    // or no need to display modal when traversing back to and from internal routes
    if ((!currentLocation.hasDirtyAttributes && !controller.isDirty) || traversingConnect) {
      return;
    }
    // display confirmation modal if settings are dirty
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (<boolean>(<unknown>currentLocation.hasDirtyAttributes)) {
          currentLocation.rollbackAttributes();
        }
        if (controller.isDirty) {
          controller.rollback();
        }
      },
    });
  }
}
