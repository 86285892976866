import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type AsyncBelongsTo } from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask } from 'ember-concurrency';
import type CompanyRoleModel from 'garaje/models/company-role';
import type FlowModel from 'garaje/models/flow';
import type LocationRoleModel from 'garaje/models/location-role';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type UserModel from 'garaje/models/user';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';

type Role = CompanyRoleModel | LocationRoleModel;

const INVITE_APPROVAL_PLAN = 'enterprise';

// list of roles that can be searched for to add as notification contacts
const SEARCH_ROLE_LIST = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

interface FlowsSectionsSecurityInviteApprovalArgs {
  contacts: UserModel[];
  flow: FlowModel;
  readOnly: boolean;
}

export default class FlowsSectionsSecurityInviteApproval extends Component<FlowsSectionsSecurityInviteApprovalArgs> {
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @tracked isDirty = false;
  @tracked isEditing = false;
  @tracked showUnsavedChangesModal = false;
  @tracked contacts = [...this.args.contacts];
  requiredPlan = INVITE_APPROVAL_PLAN;

  get vrSubscription(): LocationSubscriptionModel | SubscriptionModel | null {
    return this.state.vrSubscription;
  }

  @action
  onUpgradeClicked(): void {
    this.metrics.trackEvent('Visitor Type - Invite Approval Upgrade Clicked', { plan: this.requiredPlan });
  }

  @action
  async disableFeature(): Promise<void> {
    this.args.flow.inviteApprovalsEnabled = false;
    await this.saveTask.perform(false);
  }

  @action
  async enableFeature(): Promise<void> {
    this.args.flow.inviteApprovalsEnabled = true;
    await this.saveTask.perform(true);
  }

  @action
  updateInviteApprovalContacts(contacts: UserModel[]): void {
    this.contacts = [...contacts];
    this.isDirty = true;
  }

  @action
  cancel(confirmed = false): void {
    if (!this.isDirty || confirmed) {
      // if there are no unsaved changes, or user has confirmed to discard their unsaved changes,
      // just reset the state & close the panel
      this.contacts = this.args.flow.inviteApprovalContacts.toArray();
      this.isDirty = false;
      this.isEditing = false;
      this.showUnsavedChangesModal = false;
    } else if (this.isDirty) {
      // user has unsaved changes; show a modal asking them to confirm
      this.showUnsavedChangesModal = true;
    }
  }

  saveTask = dropTask(async (openAfterSave: boolean | undefined): Promise<void> => {
    try {
      await this.args.flow.inviteApprovalContacts.clear();
      await this.args.flow.inviteApprovalContacts.pushObjects(A(this.contacts));
      await this.args.flow.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.isEditing = !!openAfterSave;
      this.isDirty = false;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  searchUsersTask = restartableTask(async (term: string): Promise<UserModel[]> => {
    const userRoles = await this.currentLocation.searchUsers.perform(term, SEARCH_ROLE_LIST, true);
    const users: UserModel[] = await Promise.all(
      userRoles.map((role: Role): UserModel | AsyncBelongsTo<UserModel> => role.user),
    );

    const re = new RegExp(`.*${term}.*`, 'i');
    const currentContactsIds = this.contacts.map((contact: UserModel) => contact.id);

    return A(users)
      .uniqBy('id') // dedup since roles could point to same user
      .reject(({ id }) => currentContactsIds.includes(id)) // remove existing contacts
      .filter(({ fullName }) => fullName.match(re));
  });
}
