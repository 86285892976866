import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';
import { enqueueTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { buildWaiter } from '@ember/test-waiters';
import config from 'garaje/config/environment';

const testWaiter = buildWaiter('user-document-uploader-component-waiter');
const isTesting = config.environment === 'test';

/**
 * @param {Boolean}             alwaysRenderInput
 * @param {Boolean}             isDisabled
 * @param {Boolean}             isFile
 * @param {String}              imgSrc
 * @param {String}              accept
 * @param {Boolean}             autoUpload
 * @param {Boolean}             hideResetButton
 * @param {String}              label
 * @param {String}              labelRetry
 * @param {Number}              validSize
 * @param {String}              validType
 * @param {String}              invalidTitle
 * @param {String}              invalidBody
 * @param {Function}            onFileSelected
 * @param {Function}            onFilePreview
 * @param {Function}            onPendingUpload
 * @param {Function}            onPreviewClick
 * @param {Number}              uploadProgress
 */
export default class UserDocumentUploaderComponent extends Component {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorageExtension;

  /**
   * @type {HTMLInputElement}
   */
  fileInputElement = null;

  @tracked isValid = true;

  @localCopy('args.directUploadURL', '/a/visitors/api/direct-uploads') directUploadURL;
  @localCopy('args.prefix', '') prefix;
  @localCopy('args.isFile', false) isFile;
  @localCopy('args.imgSrc', '') imgSrc;
  @localCopy('args.validType') validType;
  @localCopy('args.validSize') validSize;
  @localCopy('args.onError') onError;
  @localCopy('args.hideResetButton', false) hideResetButton;

  // Normalize initial progress value to "0"
  get progress() {
    return this.args.uploadProgress ?? this.uploadTask?.last?.progress ?? 0;
  }

  // "Reverse" progress bar gets narrower as upload proceeds
  get progressStyle() {
    const remaining = 100 - this.progress;

    return htmlSafe(`width: ${remaining}%; transition: width 200ms;`);
  }

  get shouldShowResetButton() {
    return !this.hideResetButton && this.isFile && this.isValid;
  }

  upload(file) {
    const { args, directUploadURL, prefix, activeStorageExtension } = this;
    const { onUpload, onError, onUploadComplete } = args;

    // ember-active-storage addon does not support extra params in the POST payload.
    // But API currently supports sending the prefix as a query param.
    const endpoint = prefix ? `${directUploadURL}?prefix=${prefix}` : directUploadURL;
    const task = this.uploadTask.perform(file, endpoint, activeStorageExtension, { onUploadComplete, onError });

    onUpload?.(task);

    return task;
  }

  @action
  filesUpdated(files) {
    this.fileSelected(files);
  }

  /**
   * @param {FileList} files
   */
  @action
  fileSelected(files) {
    if (!files?.length) return;

    this.isValid = false;

    const { autoUpload, onFileSelected, onFilePreview } = this.args;

    const file = files.item(0);
    const reader = new FileReader();

    this.isFile = true;

    if (!this.validate(file)) return;

    const token = isTesting ? testWaiter.beginAsync() : false;

    reader.onload = () => {
      const { result } = reader;

      this.imgSrc = result;

      onFilePreview?.(result);

      if (token) testWaiter.endAsync(token);
    };
    reader.readAsDataURL(file);

    onFileSelected?.(file);

    if (autoUpload) {
      this.upload(file);
    } else {
      this.args.onPendingUpload?.({
        file,
        upload: () => this.upload(file),
        reset: () => this.reset(),
      });
    }
  }

  @action
  reset() {
    const { onReset, onFileSelected, onFilePreview } = this.args;

    this.imgSrc = '';
    this.isFile = false;
    this.isValid = true;

    // reset input value to permit selecting the same file again if input is persistent
    if (this.fileInputElement) this.fileInputElement.value = '';

    onFileSelected?.(null);
    onFilePreview?.(null);
    onReset?.();
  }

  // TODO if autoUpload true: Fix TaskInstance 'uploadTask' was canceled because the object
  // it lives on was destroyed or unrendered. For more information, see: http://ember-concurrency.com/docs/task-cancelation-help
  // Possible issue: this component is destroyed after an image is selected
  @enqueueTask uploadTask = {
    progress: 0,

    *perform(file, endpoint, activeStorageExtension, callbacks = {}) {
      const { onUploadComplete, onError } = callbacks;

      if (!file) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      try {
        const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
          onProgress: (progress) => {
            this.progress = progress;
          },
          onXHROpened: (xhr) => {
            xhr.withCredentials = this.context.args.includeCredentials ?? false;
          },
        });

        onUploadComplete?.(signedId);

        return signedId;
      } catch (error) {
        onError?.(error);
      }
    },
  };

  get validTypeReg() {
    if (this.validType instanceof RegExp) {
      return this.validType;
    }
    return new RegExp(this.validType);
  }

  validate(file) {
    const typeValid = this.validType ? this.validTypeReg.test(file.type) : true;
    const sizeValid = this.validSize ? file.size <= this.validSize : true;

    this.isValid = typeValid && sizeValid;

    return this.isValid;
  }
}
