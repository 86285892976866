import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import urlBuilder from 'garaje/utils/url-builder';
import { getUnixTime, addSeconds, addMinutes, startOfDay } from 'date-fns';
import { SECONDS_IN_A_DAY } from 'garaje/utils/enums';

export default class MapsRoute extends Route {
  title = 'Maps · Envoy';

  @service state;
  @service store;
  @service abilities;
  @service router;
  @service ajax;

  beforeModel() {
    if (this.abilities.cannot('visit-maps-nav maps')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const { currentLocation } = this.state;
    const currentLocationId = currentLocation.id;
    const areaMaps = await this.store.query('area-map', {
      filter: { location: currentLocationId },
      include: 'map-floors',
    });
    let areaMap = areaMaps.firstObject;

    // Create a new area map if one doesn't exist
    // This should be removed once the backend initializes db with area-map for locations with a desk subscription
    if (!areaMap) {
      areaMap = await this.store.createRecord('area-map', { entityId: currentLocationId }).save();
    }

    const startOfLocationDay = addMinutes(startOfDay(new Date()), this.state.minutesBetweenTimezones);
    const filter = {
      'location-id': currentLocationId,
      'user-id': this.state.currentUser.id,
      'start-date': getUnixTime(startOfLocationDay),
      'end-date': getUnixTime(addSeconds(startOfLocationDay, SECONDS_IN_A_DAY - 1)),
    };
    const currentUserReservations = await this.store.query('reservation', {
      filter,
      include: 'desk',
    });
    let currentUserReservationMapFloor = null;
    if (currentUserReservations.firstObject) {
      const currentUserRes = currentUserReservations.firstObject;
      const currentUserReservationFloor = await get(currentUserRes, 'desk.floor');
      currentUserReservationMapFloor = this.store.peekRecord('map-floor', currentUserReservationFloor.id);
    }

    // Load rooms in this location
    const roomsInLocation = await this.ajax.request(urlBuilder.roomba.getAvailableRooms(currentLocationId), {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
    });

    // Push rooms to store
    roomsInLocation.data.map((room) => {
      return this.store.push(this.store.normalize('room', room));
    });

    return { areaMap, currentUserReservationMapFloor };
  }

  @action
  refreshMapsRoute() {
    this.refresh();
  }
}
