import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import ObjectProxy from '@ember/object/proxy';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { task, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import LocationValidations from 'garaje/validations/location';
import validateAndUpdateAddressTask from 'garaje/utils/decorators/validate-and-update-address-task';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import fetch from 'fetch';
import { lt, map } from 'macro-decorators';
import { isProductDisabled } from 'garaje/helpers/product-disabled';

@validateAndUpdateAddressTask
class NewLocationController extends Controller {
  @service featureFlags;
  @service flashMessages;
  @service locations;
  @service cookies;
  @service state;
  @service store;
  @service flow;

  @tracked changeset;
  @controller('protected') protectedController;
  @lt('unusedQuantity', 1) hasInsufficientQuantity;

  queryParams = ['fromPropertyConnection'];
  @tracked fromPropertyConnection = false;

  get unusedQuantity() {
    return (get(this.model, 'vrSubscription.quantity') ?? 0) - (get(this.locations, 'active.length') ?? 0);
  }

  setupChangeset() {
    const model = get(this.model, 'newLocation');
    const validator = lookupValidator(LocationValidations);

    this.changeset = new Changeset(model, validator, LocationValidations);
  }

  get modelMasterLocation() {
    return this.locations.persisted.findBy('id', get(this.changeset, 'masterLocationId'));
  }

  set modelMasterLocation(value) {
    let id = null;
    if (value) {
      id = get(value, 'id');
    }
    set(this.changeset, 'masterLocationId', id);
    set(this.model, 'newLocation.masterLocationId', id);
    // eslint-disable-next-line no-setter-return
    return value;
  }

  // override the location model's disabled property so that disabled locations are not disabled in the power select
  @map('locations.persisted', (location) => ObjectProxy.create({ content: location, disabled: false })) locationOptions;

  @task
  *afterAddLocationTask(location) {
    if (this.model.workplaceSubscription?.isUsable) {
      const skinnyLocation = yield this.store.findRecord('skinny-location', get(location, 'id'));
      const employeeScreeningFlow = yield this.getOrCreateEmployeeFlowWithoutQuestionaire.perform(skinnyLocation);
      skinnyLocation.employeeScreeningFlow = employeeScreeningFlow;
      yield skinnyLocation.save();
    }

    if (!!this.state.features?.canAccessDeliveriesApplication && !isProductDisabled('deliveries')) {
      const deliveryArea = this.store.createRecord('delivery-area', {
        address: location.address,
        location,
      });

      yield deliveryArea.save();
    }

    yield this.state.initSubscriptionStateTask.perform(location);

    // if new location flow was initiated from a property connection prompt, return to that flow
    if (this.fromPropertyConnection && this.state.tenantConnectionRequests?.firstObject) {
      this.state.tenantConnectionRequests.firstObject.pendingLocation = location;
    }
  }

  @task
  *getOrCreateEmployeeFlowWithoutQuestionaire(skinnyLocation) {
    const existingEmployeeScreeningFlowId = this.flow.globalEmployeeScreeningFlows?.find(
      (flow) => !flow.employeeScreeningRequired,
    )?.id;

    if (!existingEmployeeScreeningFlowId) {
      const employeeRegistrationFlow = this.store.createRecord('employee-screening-flow', {
        employeeScreeningRequired: false,
        company: skinnyLocation.company,
        name: 'Employee registration [automatically generated]',
        description: 'Automatically created employee registration flow',
        locations: [skinnyLocation],
      });

      return yield employeeRegistrationFlow.save();
    } else {
      return yield this.store.findRecord('employee-screening-flow', existingEmployeeScreeningFlowId, {
        include: 'global-flow,locations',
      });
    }
  }

  @dropTask
  *saveTask() {
    try {
      yield this.changeset.validate();

      if (this.changeset.isInvalid) {
        return;
      }

      yield this.validateAndUpdateAddress.perform();

      const subscription = this.model.vrSubscription || this.model.deliveriesSubscription;

      if (
        !this.featureFlags.isEnabled('locationBilling') &&
        this.hasInsufficientQuantity &&
        this.model.vrSubscription &&
        this.model.vrSubscription.onTrial &&
        !this.model.vrSubscription.isBasicPlan
      ) {
        yield this.increaseSubscriptionQuantity.perform(subscription);
      }

      const location = yield this.changeset.save();

      yield this.afterAddLocationTask.perform(location);

      let redirect;

      if (this.state.deliveriesOnly) {
        redirect = 'deliveries.settings.delivery-areas.index';
      } else if (this.state.roomsOnly) {
        redirect = 'roomba.settings';
      } else if (this.state.visitorsOnly) {
        redirect = 'visitors.settings.location';
      } else {
        redirect = 'dashboard';
      }

      this.protectedController.send('switchLocation', get(location, 'id'), redirect);
      this.flashMessages.showAndHideFlash('success', 'New location added successfully!');
    } catch (error) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(error));
    }
  }

  @task
  *increaseSubscriptionQuantity(subscription) {
    const adapter = this.store.adapterFor('subscription');
    const updateSubscriptionURL = adapter.urlForUpdateRecord(get(subscription, 'id'), 'subscription');
    let json;

    try {
      const csrfToken = this.cookies.read('csrf_token');
      const headers = {
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-Token': csrfToken,
      };

      const request = yield fetch(updateSubscriptionURL, {
        credentials: 'include',
        headers,
        method: 'PATCH',
        body: JSON.stringify({
          data: {
            type: 'subscriptions',
            id: get(subscription, 'id'),
            attributes: {
              quantity: get(this.locations, 'active.length') + 1,
            },
          },
        }),
      });

      json = yield request.json();

      if (request.status != 200) {
        throw json;
      }
    } catch (e) {
      subscription.rollbackAttributes();
      throw e;
    }

    const serializer = this.store.serializerFor('subscription');
    const normalized = serializer.normalizeUpdateRecordResponse(
      this.store,
      'subscription',
      json,
      get(subscription, 'id'),
    );

    this.store.push(normalized);
  }

  @action
  updateName(value) {
    set(this.changeset, 'name', value);

    // The "Order Confirmation" section updates line items as the name of the
    // location changes. We need to udpate the model in addition to the
    // changeset in order to keep this working.
    set(this.model, 'newLocation.name', value);
  }

  @action
  updateMasterLocation(value) {
    this.modelMasterLocation = value;
  }
}

export default NewLocationController;
