import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { resolve } from 'rsvp';
import { action, get } from '@ember/object';
import { task, timeout, dropTask, enqueueTask, restartableTask } from 'ember-concurrency';
import { filter } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import genericSearcherTask from 'garaje/utils/generic-searcher';
import { inject as service } from '@ember/service';
import { all, defer } from 'rsvp';
import { getUnixTime, addDays, addMinutes, subDays, endOfDay, startOfDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

const TABLE_COLUMNS = [
  { name: 'Name', sort: 'full_name', key: 'user.fullName', context: 'reservation', label: 'full_name' },
  {
    name: 'Reservation date',
    sort: 'reservation-start-date',
    key: 'reservationDate',
    context: 'this',
    label: 'reservation-start-date',
  },
  { name: 'Check in time', sort: 'check-in-time', key: 'checkInTime', context: 'this', label: 'check-in-time' },
  { name: 'Check out time', sort: 'check-out-time', key: 'checkOutTime', context: 'this', label: 'check-out-time' },
  { name: 'Floor', key: 'desk.floor.name', context: 'reservation', label: 'floor-name' },
  { name: 'Desk', sort: 'desk-name', key: 'desk.name', context: 'reservation', label: 'desk-name' },
];

export default class DesksReservationsController extends Controller {
  @service metrics;
  @service flashMessages;
  @service state;
  @service store;
  @service abilities;
  @service featureFlags;

  queryParams = [
    'startDate',
    'endDate',
    'selectedDateRange',
    'selectedEmployeesIds',
    'selectedFloorsIds',
    'selectedDesksIds',
    'sort',
  ];

  @tracked sort = 'reservation-start-date';
  @tracked limit = 25;
  @tracked selectedEmployees = [];
  @tracked employees;
  @tracked employeesPage;
  @tracked reservations = [];
  @tracked reservationsPage;
  @tracked reservationsCount;
  @tracked selectedFloors = [];
  @tracked floors;
  @tracked floorsPage;
  @tracked selectedDesks = [];
  @tracked desks;
  @tracked desksPage;

  @tracked startDate;
  @tracked endDate;
  @tracked showCalendar = false;

  @tracked selectedDateRange = 'Today';
  dateRangeFilterOptions = [
    'Today',
    'Yesterday',
    'Tomorrow',
    'Past 7 days',
    'Past 14 days',
    'Past 30 days',
    'Next 7 days',
    'Next 14 days',
    'Next 30 days',
    'Custom range',
  ];

  @tracked selectedEmployeesIds = [];
  @tracked selectedFloorsIds = [];
  @tracked selectedDesksIds = [];
  @tracked showEmployeeFlowModal;

  @filter('floors', (floor) => floor.floorPlanUrl) floorsWithMap;

  getResourceIds(resourceList) {
    return resourceList.map((resource) => {
      return resource.id;
    });
  }

  /*
   *  Table functions
   */

  get sortField() {
    return this.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection() {
    return this.sort.startsWith('-') ? 'desc' : 'asc';
  }

  get canUsePartialDay() {
    return this.abilities.can('use partial day booking desk');
  }

  get fieldOptions() {
    const columns = [...TABLE_COLUMNS];
    if (this.canUsePartialDay) {
      columns.splice(2, 0, {
        name: 'Reservation time',
        sort: '',
        label: 'reservation-time',
        key: 'reservationTime',
        context: 'this',
      });
    }
    return columns.map(({ name, sort, key, context, label }) => ({
      name,
      sort,
      key,
      context,
      label,
    }));
  }

  /*
   *  Reservation functions
   */

  get hasMoreReservationPages() {
    return this.reservationsPage * this.limit < this.reservationsCount;
  }

  get startDateFormat() {
    const timeZone = this.state.currentLocation.timezone || 'America/Los_Angeles';
    const zonedTime = utcToZonedTime(new Date(this.startDate), timeZone);
    return format(new Date(zonedTime), 'MM/dd/yyyy', { timeZone: timeZone });
  }

  get endDateFormat() {
    const timeZone = this.state.currentLocation.timezone || 'America/Los_Angeles';
    const zonedTime = utcToZonedTime(new Date(this.endDate), timeZone);
    return format(zonedTime, 'MM/dd/yyyy', { timeZone: timeZone });
  }

  @action
  setStartAndEndTime() {
    this.startDate = addMinutes(startOfDay(new Date()), this.state.minutesBetweenTimezones);
    this.endDate = addMinutes(endOfDay(new Date()), this.state.minutesBetweenTimezones);
  }

  @task
  *loadReservationsTask() {
    const selectedUserIds = this.selectedEmployees.reduce((acc, emp) => {
      const userId = get(emp, 'user.id');
      if (userId) {
        acc.push(userId);
      }
      return acc;
    }, []);
    if (this.selectedEmployees.length && !selectedUserIds.length) {
      this.reservations = [];
    } else {
      try {
        const offset = this.reservationsPage * this.limit;
        const filter = {
          'location-id': get(this.model.currentLocation, 'id'),
          'start-date': getUnixTime(new Date(this.startDate)),
          'end-date': getUnixTime(new Date(this.endDate)),
          'checked-out': true,
          canceled: true,
          'active-or-checked-in': true,
        };
        if (this.selectedFloorsIds.length) {
          filter['floor-id'] = this.selectedFloorsIds.join(',');
        }
        if (this.selectedDesksIds.length) {
          filter['desk-id'] = this.selectedDesksIds.join(',');
        }
        if (selectedUserIds.length) {
          filter['user-id'] = selectedUserIds.join(',');
        }
        const reservationParams = {
          filter,
          page: { limit: this.limit, offset },
          sort: this.sort,
          include: 'desk',
          meta: 'reservation-count',
        };
        const reservations = yield this.store.query('reservation', reservationParams);
        this.metrics.trackEvent('RezLog_Filtered', {
          filter: {
            startDate: this.startDate,
            endDate: this.endDate,
            employees: this.selectedEmployeesIds,
            floors: this.selectedFloorsIds,
            desks: this.selectedDesksIds,
          },
        });
        const nonCanceledReservations = reservations.filter((res) => !res.canceledAt || res.checkInTime);
        this.reservations.pushObjects(nonCanceledReservations.toArray());
        this.reservationsCount = reservations.meta?.['reservation-count'];
        this.reservationsPage++;
      } catch (e) {
        this.flashMessages.showFlash('Error fetching reservations', parseErrorForDisplay(e));
        // eslint-disable-next-line no-console
        console.log({ e });
      }
    }
  }
  @dropTask
  *loadReservationsDropTask() {
    yield this.loadReservationsTask.perform();
  }

  @restartableTask
  *loadReservationsRestartableTask() {
    yield timeout(zft(250));
    this.reservationsPage = 0;
    this.reservations = [];
    yield this.loadReservationsTask.perform();
  }

  @dropTask
  reservationModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.loadReservationsRestartableTask.perform();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @action
  loadMoreReservations() {
    if (this.hasMoreReservationPages) {
      return this.loadReservationsDropTask.perform();
    } else {
      return resolve();
    }
  }

  loadNewReservations() {
    this.loadReservationsRestartableTask.perform();
  }

  @action
  sortReservations(field, direction) {
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;
    this.metrics.trackEvent('RezLog_Sorted', { sort: this.sort });
    // We are sorting on the client side for user names because the spaces service is not easily able to access
    // user information since it is store in the envoy-web db. A server side solution is being looked into but
    // for now this will serve as a temporary solution.
    if (field === 'full_name') {
      this.reservations = this.reservations.sort((a, b) => {
        if (direction === 'asc') {
          return get(a, 'user.fullName').localeCompare(get(b, 'user.fullName'));
        }
        return get(b, 'user.fullName').localeCompare(get(a, 'user.fullName'));
      });
    } else {
      this.loadNewReservations();
    }
  }

  /*
   *  Date Range Picker functions
   */

  @action
  onDateRangeSelect(option) {
    this.selectedDateRange = option;
    let start = startOfDay(new Date());
    let end = endOfDay(new Date());
    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Tomorrow':
        start = addDays(start, 1);
        end = addDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 14 days':
        start = subDays(start, 14);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Next 7 days':
        end = addDays(end, 7);
        break;
      case 'Next 14 days':
        end = addDays(end, 14);
        break;
      case 'Next 30 days':
        end = addDays(end, 30);
        break;
      case 'Custom range':
        return;
    }
    this.startDate = addMinutes(start, this.state.minutesBetweenTimezones);
    this.endDate = addMinutes(end, this.state.minutesBetweenTimezones);
    this.loadNewReservations();
  }

  @action
  didSelectDateRange(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedDateRange = 'Custom range';
    this.loadNewReservations();
  }

  @action
  setStartDate(date) {
    this.startDate = date;
  }

  @action
  setEndDate(date) {
    this.endDate = date;
  }

  /*
   *  Employee functions
   */

  @action
  setSelectedEmployees(selected) {
    this.selectedEmployees = selected;
    this.selectedEmployeesIds = this.getResourceIds(selected);
    this.loadNewReservations();
  }

  @enqueueTask
  *loadEmployeesTask() {
    const employees = yield this.store.query('employee', this.buildEmployeeQuery());
    this.employeesCount = employees.meta.total;
    if (this.employees.length) {
      // The last option is for loading more
      this.employees.pop();
    }
    this.employees.pushObjects(employees.toArray());
    const employeePromises = this.selectedEmployeesIds.map((id) => {
      return this.store.findRecord('employee', id);
    });

    const selectedEmployees = yield all(employeePromises);
    this.employees.pushObjects(selectedEmployees);
    this.employees = this.employees.uniqBy('email');
    this.employeesPage++;
    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  }

  @restartableTask
  *searchEmployeesTask(term) {
    const extraFilters = {
      'locations.id': this.model.currentLocation.id,
    };
    return yield this._searchEmployeesTask.perform(term, extraFilters);
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
    },
    include: 'user,employee-locations',
    sort: 'name',
  }).restartable())
  _searchEmployeesTask;

  get hasMoreEmployeePages() {
    return this.employeesPage * this.limit < this.employeesCount;
  }

  @dropTask
  *loadMoreEmployeesTask() {
    if (this.hasMoreEmployeePages) {
      try {
        yield this.loadEmployeesTask.perform();
      } catch (e) {
        this.employeesPage--;
      }
    }
  }

  buildEmployeeQuery() {
    const limit = this.limit;
    const offset = this.employeesPage * limit;
    const params = {};

    params.page = { limit, offset };

    params.filter = {
      'locations.id': this.model.currentLocation.id,
      deleted: false,
    };

    params.include = 'user,employee-locations';
    params.sort = 'name';
    return params;
  }

  /*
   *  Floor functions
   */

  @action
  setSelectedFloors(selected) {
    this.selectedFloors = selected;
    this.selectedFloorsIds = this.getResourceIds(selected);
    this.loadNewReservations();
    this.desks = [];
    this.desksPage = 0;
    this.loadDesksTask.perform();
  }

  @enqueueTask
  *loadFloorsTask() {
    const floors = yield this.store.query('floor', this.buildFloorQuery());
    this.floorsCount = floors.meta?.total;
    if (this.floors.length) {
      this.floors.pop();
    }
    this.floors.pushObjects(floors.slice()).uniqBy('id');

    const floorPromises = this.selectedFloorsIds.map((id) => {
      return this.store.findRecord('floor', id);
    });

    const selectedFloors = yield all(floorPromises);
    this.floors.pushObjects(selectedFloors);
    this.floors = this.floors.uniqBy('id');

    this.floorsPage++;
    if (this.hasMoreFloorsPages) {
      this.floors.pushObject('loadMore');
    }
  }

  @(genericSearcherTask('floor').restartable())
  searchFloorsTask;

  get hasMoreFloorsPages() {
    return this.floorsPage * this.limit < this.floorsCount;
  }

  @dropTask
  *loadMoreFloorsTask() {
    if (this.hasMoreFloorsPages) {
      try {
        yield this.loadFloorsTask.perform();
      } catch (e) {
        this.floorsPage--;
      }
    }
  }

  buildFloorQuery() {
    const limit = this.limit;
    const offset = this.floorsPage * limit;
    const params = {};

    params.page = { limit, offset };

    params.filter = {
      'location-id': this.model.currentLocation.id,
    };
    return params;
  }

  /*
   *  Desk functions
   */

  @action
  setSelectedDesks(selected) {
    this.selectedDesks = selected;
    this.selectedDesksIds = this.getResourceIds(selected);
    this.loadNewReservations();
  }

  @dropTask
  *loadDesksTask() {
    const desks = yield this.store.query('desk', this.buildDeskQuery());
    this.desksCount = desks.meta.total;
    if (this.desks.length) {
      this.desks.pop();
    }
    this.desks.pushObjects(desks.toArray()).uniqBy('id');
    const deskPromises = this.selectedDesksIds.map((id) => {
      return this.store.findRecord('desk', id);
    });

    const selectedDesks = yield all(deskPromises);
    this.desks.pushObjects(selectedDesks);
    this.desks = this.desks.uniqBy('id');

    this.desksPage++;
    if (this.hasMoreDesksPages) {
      this.desks.pushObject('loadMore');
    }
  }

  @(genericSearcherTask('desk').restartable())
  searchDesksTask;

  get hasMoreDesksPages() {
    return this.desksPage * this.limit < this.desksCount;
  }

  @dropTask
  *loadMoreDesksTask() {
    if (this.hasMoreDesksPages) {
      try {
        yield this.loadDesksTask.perform();
      } catch (e) {
        this.desksPage--;
      }
    }
  }

  buildDeskQuery() {
    const limit = this.limit;
    const offset = this.desksPage * limit;
    const params = {};

    params.page = { limit, offset };
    params.filter = {
      'location-id': this.model.currentLocation.id,
      'floor-id': this.selectedFloorsIds.join(','),
    };
    return params;
  }
}
