import { service } from '@ember/service';
import { isPresent, isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN];

export default class LocationsGroupingAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canManage(): boolean {
    const { vrSubscription } = this.state;

    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }

    return (
      !!vrSubscription?.canAccessLocationsGrouping &&
      isPresent(_intersection(CAN_VISIT_ROLES, this.currentAdmin.roleNames))
    );
  }

  get canView(): boolean {
    const { vrSubscription } = this.state;

    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }

    return !!vrSubscription?.canAccessLocationsGrouping;
  }
}
