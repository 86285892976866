import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';
import { dropTask } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SessionsService from 'garaje/services/session';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import urlBuilder from 'garaje/utils/url-builder';

const Validations = {
  email: [
    validatePresence({
      presence: true,
      message: 'is a required field',
    }),
    validateFormat({
      type: 'email',
      allowBlank: false,
    }),
  ],
};

interface EmailSearchComponentArgs {
  companyId: number;
  email: string;
  emailFound: boolean;
  password: string;
  prefilledEmail: string;
  rd: string;
  samlUrl: string;
  changeEmail: (email: string) => void;
  handleSearchResult: (email: string, found: boolean, payload?: unknown) => void;
  redirectToEnvoy: (path: string) => void;
  logInViaSAML: () => void;
}

type EmailChangeset = DetailedChangeset<{ email?: string }>;

export default class EmailSearchComponent extends Component<EmailSearchComponentArgs> {
  @service declare ajax: AjaxService;
  @service declare session: SessionsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked password?: string;
  @tracked email?: string;
  @tracked changeset?: EmailChangeset;

  get invalid(): boolean {
    return Boolean(this.changeset?.error['email']);
  }

  _buildChangeset(): EmailChangeset {
    const validator = lookupValidator(Validations);
    return Changeset({ email: this.email }, validator, Validations);
  }

  @action
  onInsert(element: HTMLElement): void {
    this.password = this.args.password;
    this.email = this.args.prefilledEmail ?? this.args.email;
    this.changeset = this._buildChangeset();

    if (this.args.prefilledEmail) {
      void this.handleSubmitTask.perform(this.args.prefilledEmail, '');
    }

    element.focus();
  }

  @action
  onUpdate(): void {
    if (this.args.emailFound && this.args.prefilledEmail) {
      void this.authenticateTask.perform(this.args.prefilledEmail, this.password!);
    }
  }

  @action
  validate(): void {
    if (this.email) {
      this.changeset!.email = this.email;
    }
  }

  handleSubmitTask = dropTask(async (email: string, password: string) => {
    if (this.args.emailFound) {
      await this.authenticateTask.perform(email, password);
    } else {
      this.changeset!.email = email;

      await this.changeset!.validate();

      if (this.changeset?.isValid) {
        await this.searchEmailTask.perform(this.email!);
      }
    }
  });

  searchEmailTask = dropTask(async (email: string) => {
    const url = urlBuilder.v2.users.search();

    try {
      const data: Record<string, unknown> = {
        email,
        company_id: this.args.companyId,
      };
      if (this.args.rd === 'legacy-mobile') {
        data['legacy_mobile'] = true;
      }
      const payload: unknown = await this.ajax.request(url, { data });

      this.args.handleSearchResult(email, true, payload);
    } catch (_e) {
      this.args.handleSearchResult(email, false);
    }
  });

  authenticateTask = dropTask(async (email: string, password: string) => {
    const params = {
      companyId: this.args.companyId,
      email,
      password,
      redirectFrom: this.args.rd,
    };

    try {
      await this.session.authenticate('authenticator:application', params);
      this.workplaceMetrics.trackEvent('DASHBOARD_LOG_IN_SUCCESS', {
        companyId: this.args.companyId,
        email: email,
      });
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Email and password do not match');
      this.password = '';
      (<HTMLElement>document.getElementsByClassName('password-field')[0]).focus();
    }
  });
}
