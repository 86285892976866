import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class MailerSectionBlock extends Model {
  // Relationships
  @belongsTo('mailer-block') mailerBlock;
  @belongsTo('mailer-section') mailerSection;
  @hasMany('mailer-section-block-override') mailerSectionBlockOverrides;

  // Attributes
  @attr('immutable', { defaultValue: () => ({}) }) settings;
  @attr('number') position;

  active = true;
}
