import { FlowsVaccineTrackingBase } from '../vaccine-tracking-base';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { add, format } from 'date-fns';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { utcToZonedTime } from 'date-fns-tz';

export default class FlowsVaccineTrackingEmployee extends FlowsVaccineTrackingBase {
  @service abilities;
  @service featureFlags;
  @service state;

  @tracked resetEndDate;
  @tracked maxDate;

  @tracked showReset = false;

  get cannotToggle() {
    return this.abilities.cannot('toggle proof of vaccination for workplace');
  }

  get isInputDisabled() {
    return super.isInputDisabled || this.cannotToggle;
  }

  @dropTask
  *resetTask() {
    try {
      yield this.templateConfiguration.denyAll({
        'end-date': format(this.resetEndDate, 'yyyy-MM-dd'),
      });

      this.flashMessages.showAndHideFlash('success', 'Reset successful');
      this.showReset = false;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  constructor() {
    super(...arguments);
    this.resetEndDate = utcToZonedTime(
      new Date(),
      this.state.currentLocation?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    this.maxDate = add(this.resetEndDate, { days: 1 });
  }
}
