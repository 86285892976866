import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class MapsEditShowRoute extends Route {
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  beforeModel(transition: Transition): void {
    const { floor_id } = transition.to.params;
    void this.router.transitionTo('spaces.maps.live');
    if (this.abilities.can('edit maps') && floor_id) {
      void this.router.transitionTo('spaces.maps.edit', floor_id);
    } else {
      void this.router.transitionTo('spaces.maps.live');
    }
  }
}
