/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { all, dropTask } from 'ember-concurrency';
import type Employee from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';
import { NEIGHBORHOOD_SCIM_CATEGORIES } from 'garaje/utils/enums';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';

import type ScimGroup from './scim-group';

const MAX_EMPLOYEE_LOAD_SIZE = 50;

export type ScimCategory = {
  name: string;
  categoryName: string;
  id?: string;
};

type DeskCountByFloor = {
  'desk-count': number;
  'floor-id': string;
};

@adapter('neighborhood')
class NeighborhoodModel extends Model {
  @service declare store: Store;

  // Relationships
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;

  @tracked declare employees: Employee[];
  @tracked declare numberOfDesks: number;

  // Attributes
  @attr('string') declare name: string;
  @attr('string') declare displayColor: string;
  @attr('string') declare notes: string;
  @attr('number') declare deskCount: number;
  @attr('array') declare deskCountByFloor: DeskCountByFloor[];
  // Arrays of Strings
  @attr('array', { defaultValue: () => [] }) declare scimDivisions?: string[];
  @attr('array', { defaultValue: () => [] }) declare scimOrganizations?: string[];
  @attr('array', { defaultValue: () => [] }) declare scimDepartments?: string[];
  @attr('array', { defaultValue: () => [] }) declare scimGroupIds: ScimGroup['id'][];
  // Because neighbohood lives in RMS back-end we can't have a "real" relationship with employees
  @attr('array', { defaultValue: () => [] }) declare employeeIds: Employee['id'][];

  @computed('scimGroupIds.[]')
  get scimGroups(): (ScimGroup | undefined)[] {
    const groups = this.store.peekAll('scim-group');
    return this.scimGroupIds.map((id) => groups.findBy('id', id.toString()));
  }

  @computed('scimDivisions.[]', 'scimOrganizations.[]', 'scimDepartments.[]', 'scimGroups.[]')
  get scimCategories(): ScimCategory[] {
    const { scimDivisions, scimOrganizations, scimDepartments, scimGroups } = this;
    const categories: ScimCategory[] = [];
    if (isPresent(scimDivisions)) {
      categories.push(...scimDivisions.map((name) => ({ name, categoryName: NEIGHBORHOOD_SCIM_CATEGORIES.DIVISIONS })));
    }
    if (isPresent(scimOrganizations)) {
      categories.push(
        ...scimOrganizations.map((name) => ({ name, categoryName: NEIGHBORHOOD_SCIM_CATEGORIES.ORGANIZATIONS })),
      );
    }
    if (isPresent(scimDepartments)) {
      categories.push(
        ...scimDepartments.map((name) => ({ name, categoryName: NEIGHBORHOOD_SCIM_CATEGORIES.DEPARTMENTS })),
      );
    }
    if (isPresent(scimGroups)) {
      categories.push(
        ...scimGroups.map((scimGroup) => {
          // TODO: this is dangerously casted b/c in theory each item here could be undefined
          const { id, displayName: name } = scimGroup!;
          return {
            id,
            name,
            categoryName: NEIGHBORHOOD_SCIM_CATEGORIES.GROUPS,
          };
        }),
      );
    }

    return categories;
  }

  /**
   * Only query for employees that aren't in the store
   *
   * Note: this is buggy right now. If `employeeIds.[]` quickly changes twice, this will actually only load the first change, not the second
   */
  @dropTask({
    observes: 'employeeIds.[]',
  })
  *loadEmployees(): Generator<Promise<Employee[][]>, void, Employee[][]> {
    const localEmployees = this.store.peekAll('employee').filter(({ id }) => this.employeeIds.includes(id));
    const localEmployeesIds = localEmployees.map(({ id }) => id);
    const notInLocalIds = this.employeeIds.filter((id) => !localEmployeesIds.includes(id));
    let loadedEmployees: Employee[][] = [];

    if (isPresent(notInLocalIds)) {
      loadedEmployees = yield all(
        _chunk(notInLocalIds, MAX_EMPLOYEE_LOAD_SIZE).map(async (ids) => {
          const result = await this.store.query('employee', {
            filter: {
              id: ids.join(','),
              deleted: false,
            },
          });

          return result.toArray();
        }),
      );
    }
    this.employees = [...localEmployees, ..._flatten(loadedEmployees)];
  }
}

export default NeighborhoodModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    neighborhood: NeighborhoodModel;
  }
}
