import { action } from '@ember/object';
import Component from '@glimmer/component';
import type MailerBlockModel from 'garaje/models/mailer-block';
import _cloneDeep from 'lodash/cloneDeep';
import { cached } from 'tracked-toolbox';

import MailerBlockAttributeArrayProxy from '../lib/mailer-section-block-attribute-array-proxy';

interface MailerSectionBlockComponentSignature {
  Args: {
    mailerBlock: MailerBlockModel;
    onUpdate?: () => void;
  };
}

export default class MailerSectionBlockComponent extends Component<MailerSectionBlockComponentSignature> {
  @cached
  get attributes(): MailerBlockAttributeArrayProxy {
    const mailerBlock: Partial<MailerBlockModel> = this.args.mailerBlock ?? {};
    const envoyDefaultBlock: Partial<MailerBlockModel> = mailerBlock.envoyDefaultBlock ?? {};

    return MailerBlockAttributeArrayProxy.create({
      defaultAttributes: envoyDefaultBlock.settings?.attributes || [],

      // Clone mailerBlock.settings to avoid modification of original attribute objects by reference
      content: _cloneDeep(mailerBlock.settings?.attributes || []),
    });
  }

  get showResetLink(): boolean {
    const {
      attributes,
      args: { mailerBlock },
    } = this;

    return attributes.any((attr) => !attr.isDefault) && mailerBlock.active;
  }

  @action
  updateSettings(): void {
    const {
      attributes,
      args: { mailerBlock },
    } = this;

    // Update model with copied object to enable proper comparison/rollback
    mailerBlock.settings = { ...mailerBlock.settings, attributes: _cloneDeep(attributes.content) };

    this.args.onUpdate?.();
  }

  @action
  toggleActive(): void {
    const { mailerBlock } = this.args;

    mailerBlock.active = !mailerBlock.active;
    this.args.onUpdate?.();
  }

  @action
  resetAttributes(): void {
    this.attributes.forEach((attr) => attr.reset());
    this.args.onUpdate?.();
  }

  @action
  deleteBlock(): void {
    const { mailerBlock } = this.args;

    if (mailerBlock.identifier !== 'custom_content') return;
    // clear mailerBlock settings so they they get included in the preview request as empty when the whole template tree gets serialized.
    mailerBlock.settings = { attributes: [] };

    mailerBlock.deleteRecord();
    this.args.onUpdate?.();
  }
}
