import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Object}                        changeset
 * @param {Boolean}                       disabled
 * @param {Boolean}                       editingEmail
 * @param {Class<Agreement>}              model
 * @param {Boolean}                       propagable
 * @param {Function}                      trackLegalDocument
 * @param {Class<Subscription>}           vrSubscription
 * @param {Function}                      updateAndSaveTask
 * @param {Function}                      updateEditingEmail
 */
export default class SendSigned extends Component {
  @action
  applyChangeset(changeset) {
    changeset.execute();
    changeset.rollback();
  }

  @action
  rollbackChangeset(changeset) {
    changeset.rollback();
  }
}
