import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class WelcomeRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;

  titleToken = 'Welcome';

  redirect(): void {
    if (this.state.hasSubscriptions) {
      void this.router.transitionTo('dashboard');
    }
  }
}
