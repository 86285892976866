import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { AttributeValueOption } from 'garaje/models/mailer-block';

import type MailerSectionBlockAttributeObjectProxy from '../../lib/mailer-section-block-attribute-object-proxy';

interface DropdownComponentSignature {
  Args: {
    attribute: MailerSectionBlockAttributeObjectProxy;
    active: boolean;
    onChangeValue: (value: string) => void;
  };
}

export default class DropdownComponent extends Component<DropdownComponentSignature> {
  @action
  onChangeValue(option: AttributeValueOption): void {
    this.args.onChangeValue?.(option.identifier);
  }

  get selected(): AttributeValueOption | undefined {
    const { attribute } = this.args;
    // eslint-disable-next-line ember/use-ember-get-and-set
    const value = attribute.get('value');
    // eslint-disable-next-line ember/use-ember-get-and-set
    const options = attribute.get('options');
    return options?.find((option) => option.identifier === value);
  }
}
