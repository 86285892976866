import Component from '@glimmer/component';

export interface EmailSidebarSettingsAttributeHeadingComponentSignature {
  Args: {
    isDefault?: boolean;
    active?: boolean;
    resetAttribute?: () => void;
  };

  Element: HTMLDivElement;
}

export default class EmailSidebarSettingsAttributeHeadingComponent extends Component<EmailSidebarSettingsAttributeHeadingComponentSignature> {
  get showResetLink(): boolean {
    const { isDefault, active, resetAttribute } = this.args;

    if (isDefault) return false;
    if (!active) return false;

    return typeof resetAttribute === 'function';
  }
}
