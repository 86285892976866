import Component from '@glimmer/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
/**
 * @param {Class<Agreement>}            model
 * @param {Function}                    trackLegalDocument
 */
export default class OnsiteRequired extends Component {
  @service flashMessages;

  @dropTask
  *toggleRequiredOnsiteTask(value) {
    const { model, trackLegalDocument } = this.args;
    set(model, 'requiredOnsite', value);
    try {
      yield model.save();
      yield trackLegalDocument();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
