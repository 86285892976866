import Component from '@glimmer/component';
import { get } from '@ember/object';

export default class EmailSidebarSettingsAttributeDynamicStringComponent extends Component {
  get value() {
    return get(this.args.attribute, 'value') || '';
  }

  get defaultValue() {
    return get(this.args.attribute, 'defaultValue') || '';
  }

  get variables() {
    return get(this.args.attribute, 'locale_interpolation_variables').map((variable) => `%{${variable}}`);
  }
}
