import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';

import ApplicationSerializer from './application';

export default class MailerSectionSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  constructor(properties: object) {
    super(properties);

    const isV2 = this.featureFlags.isEnabled('visitors-mailer-templates-v2-m1');

    this.attrs = {
      identifier: {
        serialize: isV2,
      },
      mailerTemplate: {
        serialize: isV2,
      },
      mailerBlocks: {
        serialize: isV2,
      },
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'mailer-section': MailerSectionSerializer;
  }
}
