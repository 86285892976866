import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, hash } from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';
import urlBuilder from 'garaje/utils/url-builder';

export default class DesksNeighborhoodsRoute extends Route {
  @service abilities;
  @service state;
  @service transitionConfirm;
  @service integrationsManager;
  @service ajax;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('visit-neighborhoods maps')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const { currentLocation, currentCompany } = this.state;
    await this.integrationsManager.loadIntegration(currentCompany.id);
    const adapterOptions = { locationId: currentLocation.id };
    const neighborhoods = await this.store.findAll('neighborhood', { adapterOptions, reload: true });

    const groupsToLoad = [];
    const desksByNeighborhoodId = {};

    const desksInLocation = this.store.query('desk', {
      filter: {
        'location-id': currentLocation.id,
      },
    });

    const neighborhoodDesksPromises = neighborhoods.map((hood) => {
      hood.scimGroupIds.forEach((id) => {
        groupsToLoad.push(this.store.findRecord('scimGroup', id));
      });
      const getDesksUrl = urlBuilder.rms.getDesksInNeighborhood(hood.id, currentLocation.id);
      return this.ajax.request(getDesksUrl, {
        type: 'GET',
        contentType: 'application/vnd.api+json',
      });
    });
    const neighborhoodDesks = await all(neighborhoodDesksPromises);
    neighborhoodDesks.forEach((desks) => {
      if (desks.data?.firstObject?.relationships.neighborhood) {
        desksByNeighborhoodId[desks.data.firstObject.relationships.neighborhood.data.id] = desks.data;
      }
    });

    return hash({
      neighborhoods,
      desks: desksByNeighborhoodId,
      locationDesks: desksInLocation,
      directorySyncProvider: currentCompany.directorySyncProvider,
      groups: all(groupsToLoad),
    });
  }

  @routeEvent
  routeWillChange(transition) {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const neighborHoodsController = this.controller;
    if (neighborHoodsController.dirtyNeighborhoods.length === 0) {
      return;
    }
    // display confirmation modal if any desk is dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        neighborHoodsController.dirtyNeighborhoods.forEach((neighborhood) => neighborhood.rollbackAttributes());
      },
    });
  }
}
