import { service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { restartableTask, dropTask } from 'ember-concurrency';
import type PropertyGdprConfigurationModel from 'garaje/models/property-gdpr-configuration';
import type ZoneModel from 'garaje/models/zone';
import { type PendingUpload } from 'garaje/pods/components/properties/property-form/cover-photo-config/component';
import type AddressLookupService from 'garaje/services/address-lookup';
import type FlashMessagesService from 'garaje/services/flash-messages';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { TrackedObject } from 'tracked-built-ins';

export type PropertySettingsChangesets = {
  coverPhoto?: DetailedChangeset<ZoneModel>;
  kioskLanguageSettings?: DetailedChangeset<ZoneModel>;
  accentColor?: DetailedChangeset<ZoneModel>;
  logo?: DetailedChangeset<ZoneModel>;
  property: DetailedChangeset<ZoneModel>;
  gdpr?: DetailedChangeset<PropertyGdprConfigurationModel>;
};

export type PropertyFormChangesets = NonNullable<PropertySettingsChangesets[keyof PropertySettingsChangesets]>;

interface PropertyFormArgs {
  /**
   * object literal of changesets keyed by part of the form the changeset pertains to
   */
  changesets: PropertySettingsChangesets;
  /**
   * zone model representing a property
   */
  property: ZoneModel;
  /**
   * function to call whenever any changeset is saved
   */
  onSave: (changeset: PropertyFormChangesets) => unknown;
}

export default class PropertyForm extends Component<PropertyFormArgs> {
  @service declare addressLookup: AddressLookupService;
  @service declare flashMessages: FlashMessagesService;

  @tracked photoUploading = false;
  @tracked isPropertyNew = <boolean>(<unknown>this.args.property.isNew);
  // manage address stuff separately because ember-changeset does NOT handle tracked objects.
  @tracked address = new TrackedObject(this.args.property.address);

  pendingUpload: PendingUpload | null = null;

  get coverPhotoChangeset(): DetailedChangeset<ZoneModel> | undefined {
    return this.args.changesets.coverPhoto;
  }

  get logoChangeset(): DetailedChangeset<ZoneModel> | undefined {
    return this.args.changesets.logo;
  }

  get accentColorChangeset(): DetailedChangeset<ZoneModel> | undefined {
    return this.args.changesets.accentColor;
  }

  get propertyChangeset(): DetailedChangeset<ZoneModel> {
    return this.args.changesets.property;
  }

  get gdprChangeset(): DetailedChangeset<PropertyGdprConfigurationModel> | undefined {
    return this.args.changesets.gdpr;
  }

  get saveButtonLabel(): string {
    if (this.saveChangesetTask.isRunning) {
      return this.isPropertyNew ? 'Creating...' : 'Saving...';
    } else {
      return this.isPropertyNew ? 'Create' : 'Save';
    }
  }

  get isSaveButtonDisabled(): boolean {
    return (
      (this.photoUploading && this.isPropertyNew) ||
      isEmpty(this.address.address) ||
      isBlank(this.propertyChangeset?.name) ||
      this.saveChangesetTask.isRunning ||
      !this.propertyChangeset.isDirty
    );
  }

  validateAndUpdateAddressTask = restartableTask(async (address: string) => {
    await this.addressLookup.updateAddress(address, this.address);
    this.propertyChangeset.address = this.address;
  });

  /**
   * Saves a changeset
   *
   * @param changeset - the changeset to save
   */
  saveChangesetTask = dropTask(async (changeset: PropertyFormChangesets) => {
    const isNew = <boolean>(<unknown>changeset.isNew);

    if (isNew && this.pendingUpload) {
      changeset.coverPhoto = <string>await this.pendingUpload.upload().catch(throwUnlessTaskDidCancel);
      this.pendingUpload = null;
    }

    try {
      await changeset.save();
      this.flashMessages.showAndHideFlash('success', isNew ? 'Property successfully created!' : 'Saved!');

      this.args.onSave?.(changeset);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });
}
