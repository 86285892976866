import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { action, set, get } from '@ember/object';
import { restartableTask, all, timeout } from 'ember-concurrency';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { and, or, reads } from 'macro-decorators';
import { TEST_IDENTIFIER, VACCINE_IDENTIFIER } from 'garaje/models/user-document-template';
import { task, lastValue } from 'ember-concurrency';
import { localCopy } from 'tracked-toolbox';
import zft from 'garaje/utils/zero-for-tests';

const MINIMUM_IPAD_VERSION_NEEDED = '3.3.0';
const MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS = '3.11.0';

@updateAndSaveTask
class VisitorsSettingsVisitorTypesFlowLegalDocumentsIndexController extends Controller {
  @service trial;
  @service metrics;
  @service currentAdmin;
  @service state;
  @service currentLocation;
  @service('user-document') userDocumentService;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED) isAppUpdateRequired;
  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS) isAppUpdateRequiredForDocuments;
  @and('model.agreementPage.enabled', 'model.vrSubscription.includesNda') legalDocumentsEnabled;

  @lastValue('setupTrackingConfigurations') trackingConfigurations;
  @localCopy('trackingConfigurations.vaccineConfiguration') vaccineConfiguration;
  @localCopy('trackingConfigurations.testConfiguration') testConfiguration;
  @or('testConfiguration.active', 'vaccineConfiguration.active') trackingEnabled;
  @reads('state.currentLocation.nearVisitScreeningEnabled') nearVisitScreeningEnabled;

  get returningVisitorEnabled() {
    return get(this.state.currentLocation?.config, 'beenHereBefore');
  }

  @restartableTask
  *updateAgreementPositions(agreements) {
    yield all(
      agreements.map((agreement, index) => {
        set(agreement, 'position', index + 1);
        return agreement.save({ propagable: false });
      }),
    );
  }

  @task
  *setupTrackingConfigurations() {
    if (!get(this.userDocumentService.loadTemplates, 'lastSuccessful.value')) {
      yield this.userDocumentService.loadTemplates.perform();
    }

    let relevantFlow = this.model.flow;

    if (relevantFlow.isGlobalChild) {
      relevantFlow = yield relevantFlow.globalFlow;
    }

    const [vaccineConfiguration, testConfiguration] = yield all([
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(relevantFlow, VACCINE_IDENTIFIER),
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(relevantFlow, TEST_IDENTIFIER),
    ]);

    return { vaccineConfiguration, testConfiguration };
  }

  @restartableTask
  *searchUsersTask(term) {
    yield timeout(zft(300));
    const re = new RegExp(`.*${term}.*`, 'i');
    const currentContactsIds = get(this.model.flow, 'visitorDocumentContacts').mapBy('id');
    const userRoles = yield this.currentLocation.searchUsers.perform(term);
    const users = yield all(userRoles.map((role) => get(role, 'user')));

    return users
      .uniqBy('id')
      .reject(({ id }) => currentContactsIds.includes(id))
      .filter(({ fullName }) => fullName.match(re));
  }

  @action
  trackFeatureToggled(isEnabled) {
    let eventName = 'Legal Docs - Legal Documents ';
    eventName += isEnabled ? 'Enabled' : 'Disabled';

    this.metrics.trackEvent(eventName, {
      location_id: this.model.tracking.currentLocationId,
    });
  }

  @action
  toggleAgreementEnabled(agreement, value) {
    this.updateAndSaveTask.perform(agreement, 'enabled', value, false);
  }

  @action
  toggleAgreementPageEnabled(agreementPage, value) {
    this.updateAndSaveTask.perform(agreementPage, 'enabled', value, false);
    this.trackFeatureToggled(value);
  }

  @action
  _trackAddLegalDocStarted() {
    this.send('trackAddLegalDocStarted');
  }

  @action
  _trackEditLegalDocStarted() {
    this.send('trackEditLegalDocStarted');
  }
}

export default VisitorsSettingsVisitorTypesFlowLegalDocumentsIndexController;
