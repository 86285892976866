import ArrayProxy from '@ember/array/proxy';
import MailerSectionBlockAttributeObjectProxy from './mailer-section-block-attribute-object-proxy';

/**
 * Gets the key from an attribute object
 *
 * @param {object} attribute { [key]: { ...content }}
 * @returns {string} key
 */
function getKey(attribute) {
  if (!attribute) return undefined;
  return Object.keys(attribute)[0];
}

/**
 * Gets the content from an attribute object
 *
 * @param {object} attribute { [key]: { ...content }}
 * @returns {object} content
 */
function getContent(attribute) {
  if (!attribute) return undefined;
  return attribute[getKey(attribute)];
}

export default class MailerSectionBlockAttributeArrayProxy extends ArrayProxy {
  defaultAttributes;

  getDefaultFor(attribute) {
    const key = getKey(attribute);
    return this.defaultAttributes.find((defaultAttribute) => getKey(defaultAttribute) === key);
  }

  objectAtContent(index) {
    const attribute = this.content.objectAt(index);
    const defaultAttribute = this.getDefaultFor(attribute);
    return MailerSectionBlockAttributeObjectProxy.create({
      defaultContent: getContent(defaultAttribute),
      content: getContent(attribute),
    });
  }
}
