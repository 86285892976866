import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type AjaxService from 'garaje/services/ajax';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type { Role, RoleScopeType } from 'garaje/utils/custom-roles';
import { type Permission, type Permissions, type PermissionSets } from 'garaje/utils/ui-permissions';
import { PermissionType } from 'garaje/utils/ui-permissions';
import urlBuilder from 'garaje/utils/url-builder';

export interface CreateRoleBody {
  name: string;
  description: string;
  'role-scope': RoleScopeType;
  'legacy-role': string;
  'copied-from': string | null;
  'permission-sets': Array<{ 'permission-set-id': string; effect: string }>;
}

export interface UpdateRoleBody {
  name: string;
  description: string;
  'permission-sets': Array<{ 'permission-set-id': string; effect: string }>;
}

export default class AuthzService extends Service {
  @service declare featureFlags: FeatureFlagsService;
  @service declare ajax: AjaxService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  @tracked permissions: Permissions = {
    [PermissionType.COMPANY]: {},
    [PermissionType.LOCATION]: {},
    [PermissionType.ZONE]: {},
  };
  @tracked roles: Array<Role> = [];

  async fetchPermissions(): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId) {
      const url = urlBuilder.authz.getPermissionsUrl(companyId);
      this.permissions = await this.ajax.request(url, { type: 'GET' });
    }
  }

  async fetchMatrix(roleId: string): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId && roleId) {
      const { visitorsSubscription, workplaceSubscription } = this.state;
      const options = {
        hasVisitorsAccess: visitorsSubscription?.canManageCustomAdminRoles || false,
        hasWorkplaceAccess: workplaceSubscription?.canManageCustomAdminRoles || false,
      };

      // override workplaceAccess if bypass split enabled
      if (this.featureFlags.isEnabled('spaces-custom-admin-roles')) {
        options.hasWorkplaceAccess = true;
      }

      const url = urlBuilder.authz.getMatrixUrl(companyId, roleId, options);
      return await this.ajax.request(url, { type: 'GET' });
    }
  }

  async fetchRoles(): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId) {
      const url = urlBuilder.authz.getRolesUrl(companyId);
      this.roles = await this.ajax.request(url, { type: 'GET' });
    }
  }

  async fetchRole(roleId: string): Promise<Role | void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId && roleId) {
      const url = urlBuilder.authz.getRoleUrl(companyId, roleId);
      const role: Role = await this.ajax.request(url, { type: 'GET' });
      return role;
    }
  }

  async createRole(body: CreateRoleBody): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId) {
      const url = urlBuilder.authz.getRolesUrl(companyId);
      await this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
        headers: { Accept: 'application/vnd.api+json' },
        data: JSON.stringify({
          data: {
            type: 'roles',
            attributes: {
              'parent-role-id': null,
              deactivated: false,
              ...body,
            },
          },
        }),
      });
    }
  }

  async updateRole(body: UpdateRoleBody, roleId: string): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId && roleId) {
      const url = urlBuilder.authz.getRoleUrl(companyId, roleId);
      await this.ajax.request(url, {
        type: 'PUT',
        contentType: 'application/vnd.api+json',
        headers: { Accept: 'application/vnd.api+json' },
        data: JSON.stringify({
          data: {
            type: 'roles',
            attributes: {
              deactivated: false,
              ...body,
            },
          },
        }),
      });
    }
  }

  async deactivateRole(roleId: string): Promise<void> {
    const companyId = this.state.currentCompany?.id;

    if (companyId) {
      const url = urlBuilder.authz.getRoleUrl(companyId, roleId);
      await this.ajax.request(url, { type: 'DELETE' });
    }
  }

  /**
   * Checks if the logged in user has a given permission
   *
   * @param   id   is the id of the given PermissionType, either a company id, location id, or zone id
   */
  hasPermission(type: PermissionType, id: string, permission: Permission): boolean {
    if (type && id && permission && this.permissions) {
      const permissionSets: PermissionSets = this.permissions[type];
      if (permissionSets && Object.keys(permissionSets).length > 0) {
        const permissionSet = permissionSets[id];
        if (permissionSet && permissionSet.length > 0) {
          return permissionSet.includes(permission);
        }
      }
    }

    return false;
  }

  hasPermissionAtCurrentLocation(permission: Permission): boolean {
    const locationId = this.state.currentLocation?.id;

    return locationId !== undefined && this.hasPermission(PermissionType.LOCATION, locationId, permission);
  }

  hasPermissionAtCurrentCompany(permission: Permission): boolean {
    const companyId = this.state.currentCompany?.id;

    return companyId !== undefined && this.hasPermission(PermissionType.COMPANY, companyId, permission);
  }

  hasAnyPermissionAtCurrentLocation(permissions: Array<Permission>): boolean {
    const locationId = this.state.currentLocation?.id;

    return (
      locationId !== undefined &&
      permissions.some((permission) => this.hasPermission(PermissionType.LOCATION, locationId, permission))
    );
  }

  hasAnyPermissionAtCurrentCompany(permissions: Array<Permission>): boolean {
    const companyId = this.state.currentCompany?.id;

    return (
      companyId !== undefined &&
      permissions.some((permission) => this.hasPermission(PermissionType.COMPANY, companyId, permission))
    );
  }

  hasAllPermissionsAtCurrentLocation(permissions: Array<Permission>): boolean {
    const locationId = this.state.currentLocation?.id;

    return (
      locationId !== undefined &&
      permissions.every((permission) => this.hasPermission(PermissionType.LOCATION, locationId, permission))
    );
  }

  hasAllPermissionsAtCurrentCompany(permissions: Array<Permission>): boolean {
    const companyId = this.state.currentCompany?.id;

    return (
      companyId !== undefined &&
      permissions.every((permission) => this.hasPermission(PermissionType.COMPANY, companyId, permission))
    );
  }
}
