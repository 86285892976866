import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface SettingsEmailTemplatesRouteModel {
  currentLocation: LocationModel;
  currentCompany: CompanyModel;
  envoyDefaultTemplates: MailerTemplateModel[];
  mailerTemplates: ArrayProxy<MailerTemplateModel>;
  isWhiteLabelFrom: boolean;
}

export default class SettingsEmailsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  titleToken = 'Emails';

  beforeModel(): void {
    if (this.abilities.cannot('visit settings')) {
      void this.router.transitionTo('dashboard');
    } else if (this.abilities.cannot('customize email visitor-emails')) {
      void this.router.transitionTo('visitors.settings');
    } else if (!this.featureFlags.isEnabled('visitors-mailer-templates-v2-m1')) {
      void this.router.transitionTo('visitors.settings.emails');
    }
  }

  model(): Promise<SettingsEmailTemplatesRouteModel> {
    const { currentLocation, currentCompany } = this.state;
    const mailerTemplates = this.store.query('mailer-template', {
      meta: {
        location_id: currentLocation.id,
        company_id: currentCompany.id,
      },
      filter: { 'location-id': currentLocation.id },
      page: { limit: 100 },
      'include-defaults': true, // Fetch Envoy Defaults too
    });
    const isWhiteLabelFrom = !!this.state.features?.hasWhiteglovedFromEmailName;

    return hash({
      currentLocation,
      currentCompany,
      envoyDefaultTemplates: [],
      mailerTemplates,
      isWhiteLabelFrom,
    }).then((model: SettingsEmailTemplatesRouteModel): SettingsEmailTemplatesRouteModel => {
      model.envoyDefaultTemplates = model.mailerTemplates.toArray().filter(({ envoyDefault }) => envoyDefault);

      return model;
    });
  }

  afterModel({ envoyDefaultTemplates }: SettingsEmailTemplatesRouteModel, { to: { name } }: Transition): void {
    const isToThisRoute = name === `${this.routeName}.index`;

    if (isToThisRoute && envoyDefaultTemplates[0]?.id)
      void this.router.replaceWith('visitors.settings.email-templates.show', envoyDefaultTemplates[0].id);
  }
}
