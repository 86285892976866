import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';

import ApplicationSerializer from './application';

export default class MailerBlockSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  constructor(properties: object) {
    super(properties);

    const isV2 = this.featureFlags.isEnabled('visitors-mailer-templates-v2-m1');

    this.attrs = {
      settings: {
        serialize: isV2,
      },
      position: {
        serialize: isV2,
      },
      active: {
        serialize: isV2,
      },
      identifier: {
        serialize: isV2,
      },
      mailerSection: {
        serialize: isV2,
      },
      requiredAttributes: {
        serialize: false,
      },
      deactivatable: {
        serialize: false,
      },
      editable: {
        serialize: false,
      },
      sortable: {
        serialize: false,
      },
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'mailer-block': MailerBlockSerializer;
  }
}
