import Component from '@glimmer/component';
import { action } from '@ember/object';
import { format, subMinutes, fromUnixTime } from 'date-fns';
import { inject as service } from '@ember/service';

/**
 * @param {Function}                    checkID
 * @param {Class<Entry>}                entry
 * @param {Array}                       eventReports
 * @param {Array}                       fieldOptions
 * @param {Boolean}                     fullDate
 * @param {object}                      location
 * @param {Function}                    signOutTask
 * @param {String}                      userType
 */

export default class DashboardRow extends Component {
  @service state;
  @service featureFlags;

  @action
  displayReservationTime(reservation) {
    if (reservation.isPartialDay) {
      const start = subMinutes(fromUnixTime(reservation.startTime), this.state.minutesBetweenTimezones);
      const end = subMinutes(fromUnixTime(reservation.endTime), this.state.minutesBetweenTimezones);
      return `${format(start, 'h:mmaa')} - ${format(end, 'h:mmaa')}`;
    }
    return 'All day';
  }

  get fieldCount() {
    return (this.args.fieldOptions ?? []).filterBy('show').length;
  }
}
