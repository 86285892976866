import Component from '@glimmer/component';
import mailerTemplateChangeset from 'garaje/changesets/mailer-template-version';
import { dropTaskGroup, task } from 'ember-concurrency';
import { cached } from 'tracked-toolbox';
import config from 'garaje/config/environment';

/**
 * @param {object}              mailerTemplateVersion
 * @param {string}              title
 * @param {boolean}             isOpen
 * @param {Function}            onUpdate
 * @param {Function}            onClickSave
 * @param {Function}            onClickDelete
 * @param {Function}            onClickClose
 */
export default class SideBar extends Component {
  isSidebarFixed = !config.isCypress;

  @cached
  get mailerTemplateVersionChangeset() {
    return mailerTemplateChangeset(this.args.mailerTemplateVersion);
  }

  get hasChanges() {
    const { mailerSectionBlockOverrides } = this.args.mailerTemplateVersion;
    const { mailerTemplateVersionChangeset } = this;
    return mailerTemplateVersionChangeset.isDirty || mailerSectionBlockOverrides.isAny('isDirty');
  }

  get isSaveDisabled() {
    const { mailerTemplateVersionChangeset } = this;
    return !this.hasChanges || !mailerTemplateVersionChangeset.isValid || this.updateTaskGroup.isRunning;
  }

  @dropTaskGroup updateTaskGroup;

  @task({ group: 'updateTaskGroup' })
  *saveTask() {
    const { mailerTemplateVersionChangeset } = this;
    const { mailerSectionBlockOverrides } = this.args.mailerTemplateVersion;
    yield this.args.onClickSave({ mailerTemplateVersionChangeset, mailerSectionBlockOverrides });
  }

  @task({ group: 'updateTaskGroup' })
  *deleteTask() {
    yield this.args.onClickDelete(...arguments);
  }
}
