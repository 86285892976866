import { service } from '@ember/service';
import type { Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import type AgreementModel from 'garaje/models/agreement';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { SinglePayload } from 'jsonapi/response';

import ApplicationSerializer from './application';

export default class AgreementSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  attrs = {
    documentUrl: {
      serialize: false,
    },
  };

  serialize(snapshot: Snapshot<keyof ModelRegistry>, options: object): SinglePayload<AgreementModel> {
    const json = <SinglePayload<AgreementModel>>super.serialize(snapshot, options);

    if (!this.featureFlags.isEnabled('visitors-legal-doc-pdf-upload')) {
      delete json.data.attributes.footer;
      delete json.data.attributes['document'];
    }
    return json;
  }
}
