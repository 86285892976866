import ArrayProxy from '@ember/array/proxy';
import type { MailerBlockSettingAttribute, MailerBlockSettingAttributeValues } from 'garaje/models/mailer-block';

import MailerSectionBlockAttributeObjectProxy from './mailer-section-block-attribute-object-proxy';

/**
 * Gets the key from an attribute object
 *
 * @param attribute { [key]: { ...content }}
 * @returns key
 */
function getKey(attribute: MailerBlockSettingAttribute): string | undefined {
  if (!attribute) return undefined;
  return Object.keys(attribute)[0];
}

/**
 * Gets the content from an attribute object
 *
 * @param  attribute { [key]: { ...content }}
 * @returns  content
 */
function getContent(attribute: MailerBlockSettingAttribute): MailerBlockSettingAttributeValues | undefined {
  if (!attribute) return undefined;
  const key = getKey(attribute);
  return key ? attribute[key] : undefined;
}

export default class MailerSectionBlockAttributeArrayProxy extends ArrayProxy<MailerSectionBlockAttributeObjectProxy> {
  // @ts-ignore
  declare content: MailerBlockSettingAttribute[];
  defaultAttributes!: MailerBlockSettingAttribute[];

  getDefaultFor(attribute: MailerBlockSettingAttribute): MailerBlockSettingAttribute | undefined {
    const key = getKey(attribute);
    return this.defaultAttributes.find((defaultAttribute) => getKey(defaultAttribute) === key);
  }

  objectAtContent(index: number): MailerSectionBlockAttributeObjectProxy | undefined {
    const attribute = this.content[index];
    if (!attribute) return undefined;
    const defaultAttribute = this.getDefaultFor(attribute);

    return MailerSectionBlockAttributeObjectProxy.create({
      defaultContent: getContent(defaultAttribute!),
      content: getContent(attribute),
    });
  }
}
