import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import groupBy from 'garaje/utils/decorators/group-by';
import { dependentKeyCompat } from '@ember/object/compat';
import { filter } from 'macro-decorators';

export default class ChangelogsIndexController extends Controller {
  @service infinity;

  @tracked hasClickedLoadMore;

  @dependentKeyCompat
  get sortedLogs() {
    return this.notEmptylogs.sortBy('createdAt').reverse();
  }

  @groupBy('sortedLogs', 'day') logsByDay;
  @filter('model', (changelog) => isPresent(get(changelog, 'items'))) notEmptylogs;

  /*
    @param {InfinityModel} changelogs
  */
  @task
  *loadMoreLogs(changelogs) {
    yield this.infinity.infinityLoad(changelogs);
    this.hasClickedLoadMore = false;
  }
}
