import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

import type MailerSectionBlockAttributeObjectProxy from '../../lib/mailer-section-block-attribute-object-proxy';

const UI_HOOKS_SUPPORTED = ['custom_content', 'prereg_notes'];

interface MailerTemplateEditorAttributeHtmlComponentSignature {
  Args: {
    attribute: MailerSectionBlockAttributeObjectProxy;
    identifier: string;
    uiHooks: {
      clientName: string;
      label: string;
      token: string;
      icon: string;
    }[];
  };
}

export default class MailerTemplateEditorAttributeHtmlComponent extends Component<MailerTemplateEditorAttributeHtmlComponentSignature> {
  get extensions(): { label: string; token: string; icon: string }[] | undefined {
    if (!UI_HOOKS_SUPPORTED.includes(this.args.identifier)) return [];
    const { uiHooks } = this.args;
    if (isPresent(uiHooks)) {
      return uiHooks.map(({ clientName, label, token, icon }) => ({
        label: `${clientName} - ${label}`,
        token,
        icon,
      }));
    }
    return undefined;
  }

  get variables(): string[] {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return (this.args.attribute.get('locale_interpolation_variables') || []).map((variable) => `%{${variable}}`);
  }
}
